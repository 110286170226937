import { AxiosResponse } from "axios";

import { dispatch } from "./http";
import { IParticipant, Participant } from "../models/participant";
import {
  IPresenceTrackingCreate,
  IPresenceTrackingMonthReport,
  IPresenceTrackingSummary,
  IPresenceTrackingUpdate
} from "../models/presenceTracking";
import { IPerformanceTracking, IPerformanceTrackingCreate } from "../models/performanceTracking";
import { ListResponse } from "../helper/http";
import { IPerformanceTrackingTemplate } from "../models/performanceTrackingTemplate";
import { IHomeworkTrackingCreate, IHomeworkTrackingUpdate } from "../models/homeworkTracking";
import { IComment, ICommentCreate } from "../models/comment";
import { IParticipantSummary } from "../models/participantsSummary";
import { IPerformanceInterimReport } from "../models/performanceInterimReport";

export class ApiParticipantService {
  // *************************************************************************** //
  // ********************* P A R T I C I P A N T ******************************* //
  // *************************************************************************** //
  public static fetchAllParticipants(pageParam: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v1/participants/",
      params: pageParam
    });
  }

  public static createParticipant(data: Participant): Promise<AxiosResponse<Participant>> {
    return dispatch({
      method: "POST",
      url: "/api/v1/participants/",
      data
    });
  }

  public static fetchParticipant(participantId: number): Promise<AxiosResponse<IParticipant>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/participants/${participantId}/`
    });
  }

  public static updateParticipant(participantId: number, data: Participant): Promise<AxiosResponse<IParticipant>> {
    return dispatch({
      method: "PATCH",
      url: `/api/v1/participants/${participantId}/`,
      data
    });
  }

  public static fetchPresenceReportForParticipant(participantId: number, month: string, year: string): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/participants/${participantId}/presence-tracking-report/`,
      params: new URLSearchParams({ month, year }),
      responseType: "blob"
    });
  }

  public static createPresenceReportForParticipant(participantId: number, month: string, year: string): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: `/api/v1/participants/${participantId}/presence-tracking-report/`,
      params: new URLSearchParams({ month, year })
    });
  }

  public static fetchPerformanceQuarterReportForParticipant(participantId: number, quarter: number): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/participants/${participantId}/performance-tracking-quarter-report/`,
      params: new URLSearchParams({ quarter: String(quarter) }),
      responseType: "blob"
    });
  }

  public static fetchActivities(participantId: number, pageParam: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/participants/${participantId}/activities/`,
      params: pageParam
    });
  }

  public static fetchParticipantsSummary(): Promise<AxiosResponse<IParticipantSummary>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/participants/summary/"
    });
  }

  // *************************************************************************** //
  // ******************* H O M E W O R K - T R A C K I N G  ******************** //
  // *************************************************************************** //
  public static fetchHomeworkTracking(params: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v1/homework-tracking/",
      params
    });
  }

  public static createHomeworkTracking(data: IHomeworkTrackingCreate): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: "/api/v1/homework-tracking/",
      data
    });
  }

  public static updateHomeworkTracking(id: number, data: IHomeworkTrackingUpdate): Promise<AxiosResponse> {
    return dispatch({
      method: "PATCH",
      url: `/api/v1/homework-tracking/${id}/`,
      data
    });
  }

  public static deleteHomeworkTracking(id: number): Promise<AxiosResponse> {
    return dispatch({
      method: "DELETE",
      url: `/api/v1/homework-tracking/${id}/`
    });
  }

  // *************************************************************************** //
  // ******************* P R E S E N C E - T R A C K I N G  ******************** //
  // *************************************************************************** //
  public static fetchPresenceTracking(params: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v1/presence-tracking/",
      params
    });
  }

  public static createPresenceTracking(data: IPresenceTrackingCreate): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: "/api/v1/presence-tracking/",
      data
    });
  }

  public static updatePresenceTracking(id: number, data: IPresenceTrackingUpdate): Promise<AxiosResponse> {
    return dispatch({
      method: "PATCH",
      url: `/api/v1/presence-tracking/${id}/`,
      data
    });
  }

  public static fetchPresenceTrackingSummary(
    params: URLSearchParams = new URLSearchParams()
  ): Promise<AxiosResponse<IPresenceTrackingSummary>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/presence-tracking/summary/",
      params
    });
  }

  public static fetchPresenceTrackingMonthReport(): Promise<AxiosResponse<IPresenceTrackingMonthReport[]>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/presence-tracking/report-month/"
    });
  }

  // *************************************************************************** //
  // **************** P E R F O R M A N C E - T R A C K I N G  ***************** //
  // *************************************************************************** //
  public static fetchPerformanceTracking(params: URLSearchParams): Promise<AxiosResponse<ListResponse<IPerformanceTracking>>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/performance-tracking/",
      params
    });
  }

  public static createPerformanceTracking(data: IPerformanceTrackingCreate): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: "/api/v1/performance-tracking/",
      data
    });
  }

  public static listPerformanceInterimReports(
    participantId: number
  ): Promise<AxiosResponse<ListResponse<IPerformanceInterimReport>>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/performance-interim-reports/",
      params: new URLSearchParams({ participant: String(participantId) })
    });
  }

  public static createPerformanceInterimReport(data: IPerformanceInterimReport): Promise<AxiosResponse> {
    return dispatch({
      method: "POST",
      url: "/api/v1/performance-interim-reports/",
      data: data
    });
  }

  public static deletePerformanceInterimReport(reportId: number): Promise<AxiosResponse> {
    return dispatch({
      method: "DELETE",
      url: `/api/v1/performance-interim-reports/${reportId}/`
    });
  }

  public static fetchPerformanceInterimReportAsPDF(reportId: number): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/performance-interim-reports/${reportId}/pdf/`,
      responseType: "blob"
    });
  }

  // *************************************************************************** //
  // ****** P E R F O R M A N C E - T R A C K I N G - T E M P L A T E  ********* //
  // *************************************************************************** //
  public static fetchPerformanceTrackingTemplate(id: number): Promise<AxiosResponse<IPerformanceTrackingTemplate>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/performance-tracking-template/${id}/`
    });
  }

  // *************************************************************************** //
  // *************************** C O M M E N T S  ****************************** //
  // *************************************************************************** //
  public static fetchAllCommentsForParticipant(pageParam: URLSearchParams): Promise<AxiosResponse<IComment>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/comments/`,
      params: pageParam
    });
  }

  public static createCommentForParticipant(data: ICommentCreate): Promise<AxiosResponse<IComment>> {
    return dispatch({
      method: "POST",
      url: `/api/v1/comments/`,
      data
    });
  }
}
