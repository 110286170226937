import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

// MUI
import { Button } from "@mui/material";
import Stack from "@mui/material/Stack";

// TS-Hub
import { Course } from "../../../../models/course";
import { ApiClassesService } from "../../../../services/apiClassesService";
import { CourseBook } from "../../../../models/courseBook";
import { MainCard } from "../../../../components/cards";
import { LoadingScreen } from "../../../../components/screens/loadingScreen";
import { CourseBookRecordCreateModal } from "../../modals/courseBookRecordCreateModal";
import { CourseBookRecordsTable } from "./courseBookRecordsTable";
import { CourseBookAccordionList } from "./courseBookAccordionList";

type CourseBookTableProps = {
  course: Course;
};

export const CourseBookDetail: React.FC<CourseBookTableProps> = ({ course }) => {
  const [courseBook, setCourseBook] = useState<CourseBook>();
  const [isCreateEntryModalOpen, setIsCreateEntryModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetch data necessary for the component.
   *
   */
  function fetchData() {
    ApiClassesService.fetchCourseBookFromCourse(course.title).then(res => setCourseBook(new CourseBook(res.data)));
  }

  /**
   * Render action buttons for the card.
   *
   */
  function renderCardActions(): React.ReactNode {
    return (
      <Stack direction={"row"} justifyContent={"flex-end"} alignContent={"flex-end"} width={"100%"}>
        <Button
          type={"submit"}
          size={"small"}
          variant={"contained"}
          onClick={() => setIsCreateEntryModalOpen(true)}
          style={{ marginLeft: "5px" }}
        >
          <Trans i18nKey={"COMMON.WORDS.Add"} />
        </Button>
      </Stack>
    );
  }

  if (!courseBook) {
    return <LoadingScreen />;
  }

  return (
    <React.Fragment>
      <MainCard
        title={"Kursbuch - Aktuelle Einträge"}
        cardActions={renderCardActions()}
        sx={{ marginBottom: 2, height: "auto" }}
      >
        <CourseBookRecordsTable entries={courseBook.entries} refreshCourseBook={fetchData} pageSize={5} />
      </MainCard>

      <CourseBookAccordionList courseBook={courseBook} refreshCourseBook={fetchData} />

      <CourseBookRecordCreateModal
        isOpen={isCreateEntryModalOpen}
        onClose={() => setIsCreateEntryModalOpen(false)}
        course={course}
        courseBook={courseBook}
        refreshCourseBook={fetchData}
      />
    </React.Fragment>
  );
};
