import React, { useEffect, useState } from "react";

// MUI
import Alert from "@mui/material/Alert";

// TS-HUB
import { AppLayout } from "../../layouts/appLayout";
import { MainCard } from "../../components/cards";
import { UserForm } from "./forms/userForm";
import { useAuth } from "../../provider/authentication";
import { Heading } from "../../components/text/heading";

export const Profile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user, cacheUser } = useAuth();

  useEffect(() => {
    cacheUser();
  }, []);

  return (
    <AppLayout>
      <Heading text={"Mein Profil"} />

      <MainCard sx={{ height: "auto", marginBottom: 2 }}>
        <Alert severity={"info"}>
          Das hier ist dein Nutzerprofil. Du kannst hier deine persönlichen Daten anpassen und dein Passwort ändern. Später
          kommen auch noch mehr Sachen hinzu.
        </Alert>
      </MainCard>

      <MainCard sx={{ height: "auto", marginBottom: 2 }}>
        <UserForm isLoading={isLoading} setIsLoading={setIsLoading} user={user!} />
      </MainCard>
    </AppLayout>
  );
};
