import React from "react";

// MUI
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/utils";
import { MenuItem, Select } from "@mui/material";

// TS-Hub
import { useSnackbar } from "../../../provider/snackbar";
import { PresenceStatus } from "../../../models/presenceTracking";
import { PresenceTrackingIcon } from "./presenceTrackingIcon";
import { ColumnDataRecord } from "./interfaces";

type EditComponentProps = {
  params: GridRenderEditCellParams;
};

export const PresenceTrackingCellEdit: React.FC<EditComponentProps> = props => {
  const { id, field, hasFocus } = props.params;
  const value: ColumnDataRecord = props.params.value;
  const apiRef = useGridApiContext();
  const ref = React.useRef<HTMLElement>();
  const { toast } = useSnackbar();

  useEnhancedEffect(() => {
    if (hasFocus && ref.current && value) {
      const input = ref.current.querySelector<HTMLInputElement>(`input[value="${value.presenceTrackingStatus}"]`);
      input?.focus();
    }
  }, [hasFocus, value]);

  /**
   * Handles the change event.
   *
   * @param newValue
   */
  function handleChange(newValue: PresenceStatus) {
    if (value) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: { ...value, presenceTrackingStatus: newValue } as ColumnDataRecord,
        debounceMs: 200
      });
    } else {
      toast({ level: "warning", message: "Für diesen Zeitraum gibt es keinen Eintrag. Bitte erstelle zunächst einen." });
    }
  }

  return (
    <Select
      fullWidth={true}
      ref={ref}
      value={value ? value.presenceTrackingStatus : ""}
      onChange={event => handleChange(event.target.value as PresenceStatus)}
      size={"small"}
    >
      <MenuItem value={PresenceStatus.PRESENT}>
        <PresenceTrackingIcon status={PresenceStatus.PRESENT} comment={""} />
      </MenuItem>
      <MenuItem value={PresenceStatus.DELAYED}>
        <PresenceTrackingIcon status={PresenceStatus.DELAYED} comment={""} />
      </MenuItem>
      <MenuItem value={PresenceStatus.ABSENT}>
        <PresenceTrackingIcon status={PresenceStatus.ABSENT} comment={""} />
      </MenuItem>
      <MenuItem value={PresenceStatus.EXCUSED}>
        <PresenceTrackingIcon status={PresenceStatus.EXCUSED} comment={""} />
      </MenuItem>
      <MenuItem value={PresenceStatus.VACATION}>
        <PresenceTrackingIcon status={PresenceStatus.VACATION} comment={""} />
      </MenuItem>
      <MenuItem value={PresenceStatus.HOLIDAY}>
        <PresenceTrackingIcon status={PresenceStatus.HOLIDAY} comment={""} />
      </MenuItem>
    </Select>
  );
};
