import React from "react";
import { Controller, useForm } from "react-hook-form";

// MUI
import { GridRowModelUpdate } from "@mui/x-data-grid-pro";
import { Button, ButtonGroup, Grid, TextField } from "@mui/material";
import Divider from "@mui/material/Divider";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { DateTimeField } from "@mui/x-date-pickers";

// TS-Hub
import { CourseBookRecord, ICourseBookRecord, ICourseBookRecordUpdate } from "../../../../models/courseBookRecord";
import { MainCard } from "../../../../components/cards";
import { useAuth } from "../../../../provider/authentication";
import { useSnackbar } from "../../../../provider/snackbar";
import { ApiClassesService } from "../../../../services/apiClassesService";

type CourseBookRecordsTablePanelContentProps = {
  record: CourseBookRecord;
  refreshCourseBook: Function;
};

export const CourseBookRecordsTablePanelContent: React.FC<CourseBookRecordsTablePanelContentProps> = props => {
  const { record, refreshCourseBook } = props;
  const { user } = useAuth();
  const {
    control,
    handleSubmit,
    formState: { isValid, isDirty }
  } = useForm({ defaultValues: record });
  const { toast } = useSnackbar();

  /**
   * Handles the onSubmit event of the form.
   *
   * @param data
   */
  async function onSubmit(data: GridRowModelUpdate) {
    const record = data as ICourseBookRecord;
    const update: ICourseBookRecordUpdate = { entry: record.entry, topic: record.topic };
    const res = await ApiClassesService.updateCourseBookRecord(record.id!, update);

    if (res.status === 200) {
      toast({ level: "success", message: "Eintrag wurde aktualisiert." });
      setTimeout(() => refreshCourseBook(), 1_000);
    } else {
      toast({ level: "warning", message: "Eintrag konnte ncht aktualisiert werden." });
    }
  }

  /**
   * Handles the onDelete button event.
   *
   */
  async function onDelete() {
    if (!user?.isSuperuser) {
      toast({ level: "warning", message: "Keine Berechtigung." });
    } else {
      const res = await ApiClassesService.deleteCourseBookRecord(record.id!);
      if (res.status === 204) {
        toast({ level: "success", message: "Eintrag wurde gelöscht." });
        setTimeout(() => refreshCourseBook(), 1_000);
      } else {
        toast({ level: "warning", message: "Eintrag konnte nicht gelöscht werden." });
      }
    }
  }

  /**
   * Handles the onApprove button event.
   *
   */
  async function onApprove() {
    if (user && user.isTrainer()) {
      const res = await ApiClassesService.approveCourseBookRecord(record.id!);
      if (res.status === 200) {
        toast({ level: "success", message: "Erfolgreich genehmigt!" });
        setTimeout(() => refreshCourseBook(), 1_000);
      } else {
        toast({ level: "warning", message: "Dieser Eintrage wurde bereits genehmigt." });
      }
    } else {
      toast({ level: "error", message: "Diese Funktion können nur Trainer ausführen." });
    }
  }

  return (
    <MainCard sx={{ border: "none", margin: "20px" }}>
      <Box sx={{ marginTop: 1, marginBottom: 3 }}>
        {!record.isApproved && (
          <Alert severity={"warning"}>
            Dieser Eintrag wurde von einem Junior Trainer erstelt. Der Eintrag muss aus diesem Grund noch vom Haupt-Trainer
            genehmigt werden.
          </Alert>
        )}
      </Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <Controller
              control={control}
              name={"teachingUnits"}
              render={({ field, fieldState: { invalid } }) => (
                <TextField label={"Unterrichtseinheiten"} size={"small"} error={invalid} fullWidth disabled {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={10}>
            <Controller
              control={control}
              name={"module"}
              render={({ field, fieldState: { invalid } }) => (
                <TextField label={"Modul"} size={"small"} error={invalid} fullWidth disabled {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              control={control}
              name={"entry"}
              render={({ field, fieldState: { invalid } }) => (
                <TextField
                  label={"Eintrag"}
                  size={"small"}
                  error={invalid}
                  required
                  fullWidth
                  multiline
                  disabled={record.teacher?.id !== user?.id}
                  {...field}
                />
              )}
            />
          </Grid>
        </Grid>

        {record.approverFirstName && (
          <>
            <Divider sx={{ margin: "20px 0" }} />
            <h4>Freigegeben durch</h4>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name={"approver.firstName"}
                  render={({ field, fieldState: { invalid } }) => (
                    <TextField label={"Genehmigt durch"} size={"small"} error={invalid} fullWidth disabled {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name={"approver.lastName"}
                  render={({ field, fieldState: { invalid } }) => (
                    <TextField label={"Genehmigt durch"} size={"small"} error={invalid} fullWidth disabled {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  control={control}
                  name={"approvedAt"}
                  render={({ field }) => (
                    <DateTimeField label={"Genehmigt durch"} size={"small"} fullWidth disabled {...field} />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}

        <Divider sx={{ margin: "20px 0" }} />

        <Grid container spacing={3}>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
            <ButtonGroup>
              {user?.isSuperuser && (
                <Button type={"button"} variant={"outlined"} size={"small"} color={"error"} onClick={onDelete}>
                  Löschen
                </Button>
              )}
              {record.isApproved ? (
                <Button type={"submit"} variant={"contained"} size={"small"} disabled={!isDirty || !isValid}>
                  Speichern
                </Button>
              ) : (
                <Button type={"button"} variant={"contained"} size={"small"} disabled={!isValid} onClick={onApprove}>
                  Genehmigen
                </Button>
              )}
            </ButtonGroup>
          </Grid>
        </Grid>
      </form>
    </MainCard>
  );
};
