import React, { useState } from "react";
import { useTranslation, Trans } from "react-i18next";

// MUI
import { Box, Button, Stack } from "@mui/material";
import Divider from "@mui/material/Divider";

// TS-Hub
import { MainCard } from "../../../components/cards";
import { Participant } from "../../../models/participant";
import { PerformanceTrackingModal } from "../../../components/performanceTracking/performanceTrackingModal";
import { PresenceTrackingDownloadModal } from "../../../components/presenceTracking/presenceTrackingDownloadModal";
import { PresenceTrackingModal } from "../../../components/presenceTracking/presenceTrackingModal";
import { CommentModal } from "../modals/commentModal";

type ParticipantActionsCardProps = {
  participant: Participant;
};

export const ParticipantActionsCard: React.FC<ParticipantActionsCardProps> = props => {
  const { participant } = props;
  const [isPresenceTrackingModalOpen, setIsPresenceTrackingModalOpen] = useState(false);
  const [isPerformanceTrackingModalOpen, setIsPerformanceTrackingModalOpen] = useState(false);
  const [isPresenceTrackingDownloadModalOpen, setIsPresenceTrackingDownloadModalOpen] = useState(false);
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <MainCard title={t("COMMON.WORDS.Course Actions")}>
      <Box sx={{ width: "100%" }}>
        <Stack spacing={2}>
          <Button variant={"contained"} onClick={() => setIsPresenceTrackingModalOpen(true)}>
            <Trans i18nKey={"COMMON.BUTTONS.Track presence"} />
          </Button>

          <Button variant={"outlined"} onClick={() => setIsPresenceTrackingDownloadModalOpen(true)}>
            <Trans i18nKey={"COMMON.WORDS.Presence Report"} />
          </Button>

          <Divider />

          <Button variant={"contained"} onClick={() => setIsPerformanceTrackingModalOpen(true)}>
            <Trans i18nKey={"COMMON.BUTTONS.Track performance"}>Track performance</Trans>
          </Button>
          <Button variant={"contained"} onClick={() => setIsCommentModalOpen(true)}>
            <Trans i18nKey={"COMMON.BUTTONS.Create comment"}>Create comment</Trans>
          </Button>

          <Divider />
          <Button
            href={`https://app-eu1.hubspot.com/contacts/25040982/contact/${participant.hubspotId}`}
            target={"_blank"}
            variant={"outlined"}
          >
            Zu HubSpot
          </Button>
        </Stack>
      </Box>

      <PresenceTrackingModal
        isOpen={isPresenceTrackingModalOpen}
        onClose={() => setIsPresenceTrackingModalOpen(false)}
        participant={participant}
      />

      <PresenceTrackingDownloadModal
        isOpen={isPresenceTrackingDownloadModalOpen}
        onClose={() => setIsPresenceTrackingDownloadModalOpen(false)}
        participant={participant}
      />

      <PerformanceTrackingModal
        isOpen={isPerformanceTrackingModalOpen}
        onClose={() => setIsPerformanceTrackingModalOpen(false)}
        participantId={participant.id}
      />

      <CommentModal isOpen={isCommentModalOpen} onClose={() => setIsCommentModalOpen(false)} participant={participant} />
    </MainCard>
  );
};
