import React, { createContext } from "react";

import { ThemeProvider } from "@mui/material";
import { createTheme, ThemeOptions, useMediaQuery } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

interface IThemingContextStates {
  preferDarkMode: boolean;
}

/**
 * Create the context.
 *
 */
const ThemingContext = createContext<IThemingContextStates>({
  preferDarkMode: false
});

/**
 * Returns the theming options for the UI.
 *
 * @param preferDarkMode
 */

function getThemeOptions(preferDarkMode: boolean): ThemeOptions {
  if (preferDarkMode) {
    return {
      palette: {
        mode: "dark",
        primary: {
          main: "#fc6549"
        }
      }
    };
  } else {
    return {
      palette: {
        mode: "light",
        primary: {
          main: "#fc6549"
        }
      }
    };
  }
}

export const ThemingProvider: React.FC<React.PropsWithChildren> = props => {
  const preferDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = React.useMemo(() => createTheme(getThemeOptions(preferDarkMode)), [preferDarkMode]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ThemingContext.Provider value={{ preferDarkMode }}>{props.children}</ThemingContext.Provider>
    </ThemeProvider>
  );
};

export function useTheming(): IThemingContextStates {
  const context = React.useContext(ThemingContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthContext.");
  }

  return context;
}
