import React from "react";
import { Controller, useForm } from "react-hook-form";

// MUI
import { Grid, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

// TS-Hub
import { MainCard } from "../../../../components/cards";
import { Course, ICourse } from "../../../../models/course";
import { ApiClassesService } from "../../../../services/apiClassesService";
import { useSnackbar } from "../../../../provider/snackbar";

type CourseDataProps = {
  course: Course;
  setCourse: Function;
};

export const CourseData: React.FC<CourseDataProps> = ({ course, setCourse }) => {
  const { register, handleSubmit, control } = useForm<Course>({ values: course });
  const { toast } = useSnackbar();

  /**
   * Saves the updated course into the cloud.
   *
   * @param data
   */
  async function handleOnSubmit(data: ICourse) {
    if (course) {
      const res = await ApiClassesService.patchCourse(course.title, data);
      if (res.status === 200) {
        toast({ message: "Update success!", level: "success" });
        setCourse(res.data);
      } else {
        toast({ message: "Update not possible! Status: " + res.status + ".", level: "warning" });
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)} style={{ height: "100%" }}>
      <MainCard title={"Kursdaten"}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <TextField
              id={"course_title"}
              label={"Title"}
              fullWidth={true}
              defaultValue={course.title}
              disabled={true}
              variant={"outlined"}
              {...register("title")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={"startsAt"}
              render={({ field: { ref, ...field } }) => (
                <DesktopDatePicker {...field} inputRef={ref} label={"Beginnt am"} sx={{ width: "100%" }} disabled={true} />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              control={control}
              name={"endsAt"}
              render={({ field: { ref, ...field } }) => (
                <DesktopDatePicker {...field} inputRef={ref} label={"Endet am"} sx={{ width: "100%" }} disabled={true} />
              )}
            />
          </Grid>
        </Grid>
      </MainCard>
    </form>
  );
};
