import React from "react";

// MUI
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

export const FeatureNotPublished: React.FC = () => {
  return (
    <Box width={"full"} my={2}>
      <Alert severity={"warning"}>
        <AlertTitle>Feature noch nicht offiziell veröffentlicht</AlertTitle>
        Dieses Features steht zur Verwendung bereit, ist allerdings noch <strong>nicht offiziell veröffentlicht</strong> worden
        und somit noch nicht Teil der offiziellen Techstarter Toolchain.
        <br />
        Ausprobieren und Feedback sind jederzeit willkommen.
      </Alert>
    </Box>
  );
};
