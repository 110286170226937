export const GoogleClientId = "1001374586340-04o2529t4tpn4tsqr3jk955dsheunkrr.apps.googleusercontent.com";

export enum Routes {
  // Users
  USERS_LOGIN = "/login",
  USERS_PROFILE = "/profile",

  // Classes
  COURSES_LIST = "/kurse",
  COURSES_DETAIL = "/kurse/:id",

  // Participants
  PARTICIPANTS_LIST = "/teilnehmer",

  // Programs
  PROGRAMS_LIST = "/maßnahmen",

  // Todos
  TODOS_LIST = "/todos"
}

export enum Month {
  JANUARY = 1,
  FEBRUARY,
  MARCH,
  APRIL,
  MAY,
  JUNE,
  JULY,
  AUGUST,
  SEPTEMBER,
  OCTOBER,
  NOVEMBER,
  DECEMBER
}

export enum Grade {
  VERY_GOOD = 1,
  GOOD,
  SATISFYING,
  SUFFICIENT,
  DEFICIENT
}
