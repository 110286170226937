import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import download from "downloadjs";

import "./styles-ckeditor.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// MUI
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Switch,
  FormControlLabel,
  FormLabel
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";

// TS-Hub
import { useSnackbar } from "../../provider/snackbar";
import { Participant } from "../../models/participant";
import { IPerformanceInterimReport, PerformanceInterimReport } from "../../models/performanceInterimReport";
import { ApiParticipantService } from "../../services/apiParticipantService";
import { useAuth } from "../../provider/authentication";

type PerformanceTrackingDownloadInterimReportModalProps = {
  isOpen: boolean;
  onClose: Function;
  participant: Participant;
};

export const PerformanceTrackingInterimReportModal: React.FC<PerformanceTrackingDownloadInterimReportModalProps> = props => {
  const { isOpen, onClose, participant } = props;
  const { handleSubmit, control } = useForm<PerformanceInterimReport>({ defaultValues: { canAchieveObjectives: false } });
  const { user } = useAuth();
  const { toast } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState<Array<PerformanceInterimReport>>([]);

  useEffect(() => {
    if (isOpen) {
      getListOfInterimReports();
    }
  }, [isOpen]);

  /**
   * Handler for the onSubmit event.
   *
   */
  async function handleCreateOnSubmit(data: IPerformanceInterimReport) {
    setIsLoading(true);
    const reportData: IPerformanceInterimReport = { ...data, user: user!.id, participant: participant.id };
    const res = await ApiParticipantService.createPerformanceInterimReport(reportData);
    if (res.status === 201) {
      toast({ message: "Zwischenbericht erstellt.", level: "success" });
      getListOfInterimReports();
    } else {
      toast({
        message:
          "Der Zwischenbericht konnte nicht erstellt werden. Bitte prüfe die Angaben einmal oder konktaktiere den Support.",
        level: "error"
      });
    }
    setIsLoading(false);
  }

  /**
   * Get a list of all interim reports for this participant.
   *
   */
  async function getListOfInterimReports() {
    ApiParticipantService.listPerformanceInterimReports(participant.id).then(res => {
      setReports(res.data.results.map(r => new PerformanceInterimReport(r)));
    });
  }

  /**
   * Deletes the given report.
   *
   * @param report
   */
  async function deleteReport(report: PerformanceInterimReport) {
    const res = await ApiParticipantService.deletePerformanceInterimReport(report.id!);
    if (res.status === 204) {
      toast({ message: "Zwischenbericht erfolgreich gelöscht.", level: "success" });
      getListOfInterimReports();
    } else {
      toast({ message: "Zwischenbericht konnte nicht gelöscht werden.", level: "error" });
    }
  }

  /**
   * Downloads the performance tracking interim report for the participant.
   *
   */
  async function downloadReport(report: PerformanceInterimReport) {
    const res = await ApiParticipantService.fetchPerformanceInterimReportAsPDF(report.id!);
    if (res.status === 200) {
      const fileName = `${participant.firstName}_${participant.lastName}_Interim_Report.pdf`;
      return download(res.data, fileName, "application/pdf");
    } else if (res.status === 403) {
      toast({
        level: "warning",
        message: "Der Zwischenbericht kann nur von Classmanagern heruntergeladen werden."
      });
    } else {
      toast({
        level: "warning",
        message: "Fehler beim Herunterladen des Berichts. Bitte überprüfe in deinem Profil, ob deine Unterschrift vorliegt."
      });
    }
  }

  /**
   * Handles the onClose event of the modal.
   *
   */
  function handleOnClose() {
    setIsLoading(false);
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose} maxWidth={"xl"} fullWidth={true}>
      <DialogTitle textAlign={"center"}>
        <Trans i18nKey={"COMMON.WORDS.Performance Tracking Interim Report"} />: {participant.getFullName()}
      </DialogTitle>

      <DialogContent>
        <Divider sx={{ marginBottom: "15px" }} />

        <Stack direction={"row"} width={"100%"} spacing={2}>
          <Box width={"60%"}>
            <form onSubmit={handleSubmit(handleCreateOnSubmit)}>
              <h3 style={{ textAlign: "center" }}>Zwischenbericht erstellen</h3>
              <Divider sx={{ marginBottom: 2 }} />
              <Alert severity={"info"}>
                Hier können Trainer*innen Zwischenberichte von den Teilnehmern erstellen. <br />
                <br />
                Fülle bitte hierfür alle Daten aus und prüfe vor dem absenden, ob bereits ein Bericht für den gewünschten
                Zeitraum erstellt wurde.
              </Alert>

              <Grid container spacing={3} sx={{ marginTop: "0" }}>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={"startDate"}
                    render={({ field: { ref, ...field } }) => (
                      <DesktopDatePicker {...field} inputRef={ref} label={"Start"} sx={{ width: "100%" }} />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={"endDate"}
                    render={({ field: { ref, ...field } }) => (
                      <DesktopDatePicker {...field} inputRef={ref} label={"Ende"} sx={{ width: "100%" }} />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Controller
                    control={control}
                    name={"canAchieveObjectives"}
                    render={({ field: { ref, ...field } }) => (
                      <FormControlLabel
                        control={<Switch {...field} inputRef={ref} />}
                        label={"Maßnahmenziel wird voraussichtlich erfüllt"}
                        labelPlacement={"start"}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <div>
                    <FormLabel>Kenntnisse und Fähigkeiten</FormLabel>
                    <Controller
                      control={control}
                      name={"commentSkills"}
                      render={({ field: { onChange, ...field } }) => (
                        <CKEditor
                          editor={ClassicEditor}
                          {...field}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onChange(data);
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div>
                    <FormLabel>Persönliche Eigenschaften</FormLabel>
                    <Controller
                      control={control}
                      name={"commentPersonalTraits"}
                      render={({ field: { onChange, ...field } }) => (
                        <CKEditor
                          editor={ClassicEditor}
                          {...field}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            onChange(data);
                          }}
                        />
                      )}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
                    <Trans i18nKey={"COMMON.WORDS.Create"} />
                  </LoadingButton>
                </Grid>
              </Grid>
            </form>
          </Box>

          <Divider orientation={"vertical"} sx={{ height: "auto" }} />

          <Box width={"40%"}>
            <h3 style={{ textAlign: "center" }}>Zwischenberichte</h3>
            <Divider />
            <List dense={true}>
              {reports.map(r => (
                <ListItem
                  key={r.id}
                  secondaryAction={
                    <IconButton edge={"end"} aria-label={"delete"} onClick={() => deleteReport(r)}>
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemAvatar>
                    <Avatar>
                      <IconButton onClick={() => downloadReport(r)}>
                        <DownloadIcon />
                      </IconButton>
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    primary={`${r.startDate.toDate().toLocaleDateString()} - ${r.endDate.toDate().toLocaleDateString()}`}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
