import { AxiosResponse } from "axios";

import { dispatch } from "./http";
import { IProgram } from "../models/program";

export class ApiProgramsService {
  // *************************************************************************** //
  // ************************* M O D U L E  ************************************ //
  // *************************************************************************** //
  public static fetchAllModules(params: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/modules/`,
      params: params
    });
  }

  // *************************************************************************** //
  // ************************** T O P I C ************************************** //
  // *************************************************************************** //
  public static fetchTopics(params: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/topics/`,
      params: params
    });
  }

  // *************************************************************************** //
  // ************************* P R O G R A M *********************************** //
  // *************************************************************************** //
  public static fetchPrograms(params: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: `/api/v1/programs/`,
      params: params
    });
  }

  public static fetchProgram(id: number): Promise<AxiosResponse<IProgram>> {
    return dispatch({
      method: "GET",
      url: `/api/v1/programs/${id}`
    });
  }
}
