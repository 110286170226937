import React from "react";

// MUI
import Stack from "@mui/material/Stack";

// TS-Hub
import { CenteredLayout } from "../../layouts/centeredLayout";
import { useTheming } from "../../provider/theming";

export const ErrorPage: React.FC = () => {
  const { preferDarkMode } = useTheming();
  const imgPath = preferDarkMode
    ? process.env.PUBLIC_URL + "/img/logos/ts_mint.png"
    : process.env.PUBLIC_URL + "/img/logos/ts_petrol.png";

  return (
    <CenteredLayout>
      <Stack direction={"column"} spacing={2} width={"300px"}>
        <img src={imgPath} alt={"Logo"} style={{ marginBottom: "15px" }} />
        <Stack direction={"row"} spacing={2}>
          <h3>Ups, hier ist ein Fehler passiert! 🏴‍☠️</h3>
          <p>Aber, keine Sorge: Unser Team wurde bereits automatisch darüber informiert.</p>
          <p>Gehe einfach oben auf den Zurückbutton und tue, was du tun musst! 🫡</p>
        </Stack>
      </Stack>
      <br />
      <br />
    </CenteredLayout>
  );
};
