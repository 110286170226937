import { useCallback, useEffect, useLayoutEffect, useRef } from "react";

type KeyPressProps = {
  keys: Array<string>;
  callback: Function;
};

export const useKeyPress = ({ keys, callback }: KeyPressProps) => {
  const callbackRef = useRef(callback);

  useLayoutEffect(() => {
    callbackRef.current = callback;
  });

  // Handle what happens on key press
  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      // Check if one of the key is part of the ones we want
      if (keys.some(key => event.key === key) && event.metaKey) {
        callbackRef.current(event);
      }
    },
    [keys]
  );

  useEffect(() => {
    // Target is either the provided node or the document
    const targetNode = document;
    // Attach the event listener
    targetNode && targetNode.addEventListener("keydown", handleKeyPress);

    // Remove the event listener
    return () => targetNode && targetNode.removeEventListener("keydown", handleKeyPress);
  }, [handleKeyPress]);
};
