import React, { useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";

// MUI
import { red, green } from "@mui/material/colors";
import { Grid } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import Divider from "@mui/material/Divider";

// TS-Hub
import { MainCard } from "../../../../components/cards";
import { Course } from "../../../../models/course";
import { getSum } from "../../../../helper/array";

type CourseFreeDaysProps = {
  course: Course;
};

export const CourseTimePlan: React.FC<CourseFreeDaysProps> = ({ course }) => {
  const today = dayjs();
  const sumTeachingDays = getSum(course.courseDates.map(c => c.teachingDays));
  const sumVacationDays = getSum(course.courseDates.map(c => c.countVacationDays));
  const sumHolidays = getSum(course.courseDates.map(c => c.countHolidays));
  const countVacationFix = sumVacationDays;
  const countVacationFlexible = course.maxVacationDays - countVacationFix;
  const countVacationLimit = course.maxVacationDays - 3;

  useEffect(() => {}, []);

  /**
   * Returns the name of the module for the given date. This tells us, what module is active on that day.
   *
   * @param time
   *
   */
  function getActiveModuleName(time: Dayjs) {
    const m = course.courseDates.find(cmd => cmd.startDate <= time && time <= cmd.endDate);
    return m?.moduleName;
  }

  return (
    <MainCard title={"Zeitplan"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <h3>Module</h3>
          <TableContainer component={Paper}>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Modul</TableCell>
                  <TableCell>Beginn</TableCell>
                  <TableCell>Ende</TableCell>
                  <TableCell align={"right"}>Unterrichtstage</TableCell>
                  <TableCell align={"right"}>Urlaubstage</TableCell>
                  <TableCell align={"right"}>Feiertage</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {course.courseDates.map((courseDates, index) => (
                  <TableRow key={index} hover={true}>
                    <TableCell>{courseDates.moduleName}</TableCell>
                    <TableCell>
                      {courseDates.startDate
                        .toDate()
                        .toLocaleDateString("de-DE", { weekday: "long", year: "numeric", month: "long", day: "numeric" })}
                    </TableCell>
                    <TableCell>
                      {courseDates.endDate
                        .toDate()
                        .toLocaleDateString("de-DE", { weekday: "long", year: "numeric", month: "long", day: "numeric" })}
                    </TableCell>
                    <TableCell align={"right"}>{courseDates.teachingDays}</TableCell>
                    <TableCell align={"right"}>{courseDates.countVacationDays}</TableCell>
                    <TableCell align={"right"}>{courseDates.countHolidays}</TableCell>
                  </TableRow>
                ))}

                <TableRow sx={{ borderTop: "1px double" }}>
                  <TableCell rowSpan={1} />
                  <TableCell rowSpan={1} />
                  <TableCell colSpan={2}>
                    <strong>Anzahl fixer Urlaubstage</strong>
                  </TableCell>
                  <TableCell
                    align={"right"}
                    sx={{ backgroundColor: 14 > countVacationFix || countVacationFix > countVacationLimit ? red["500"] : green["500"] }}
                  >
                    {countVacationFix}
                  </TableCell>
                  <TableCell align={"right"}></TableCell>
                </TableRow>

                <TableRow>
                  <TableCell rowSpan={1} />
                  <TableCell rowSpan={1} />
                  <TableCell colSpan={2}>
                    <strong>Anzahl flexibler Urlaubstage</strong>
                  </TableCell>
                  <TableCell
                    align={"right"}
                    sx={{ backgroundColor: countVacationFlexible <= 0 || countVacationFlexible > countVacationFix ? red["500"] : green["500"] }}
                  >
                    {countVacationFlexible}
                  </TableCell>
                </TableRow>

                <TableRow sx={{ borderTop: "3px double" }}>
                  <TableCell rowSpan={1} />
                  <TableCell rowSpan={1} />
                  <TableCell colSpan={1}>
                    <strong>Gesamt</strong>
                  </TableCell>
                  <TableCell align={"right"}>{sumTeachingDays}</TableCell>
                  <TableCell align={"right"} sx={{ color: sumVacationDays > course.maxVacationDays ? red["500"] : green["500"] }}>
                    {countVacationFix + countVacationFlexible}
                  </TableCell>
                  <TableCell align={"right"} sx={{ color: sumHolidays > course.maxVacationDays ? red["500"] : green["500"] }}>
                    { course.maxVacationDays}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Divider sx={{ margin: "15px 0" }} />

        <Grid item xs={12} sm={12} md={6}>
          <h3>Urlaub</h3>
          <TableContainer component={Paper}>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Urlaub</TableCell>
                  <TableCell align={"right"}>Zeitraum</TableCell>
                  <TableCell align={"right"}>Modul</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {course.vacations?.map((vacation, index) => (
                  <TableRow key={index} sx={{ opacity: today > vacation.dateUntil ? 0.5 : 1 }} hover={true}>
                    <TableCell component={"th"} scope={"row"}>
                      {vacation.name}
                    </TableCell>
                    <TableCell align={"right"}>{vacation.getDateRangeAsString()}</TableCell>
                    <TableCell align={"right"}>{getActiveModuleName(vacation.dateFrom)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <h3>Feiertage</h3>
          <TableContainer component={Paper}>
            <Table size={"small"}>
              <TableHead>
                <TableRow>
                  <TableCell>Name des Feiertags</TableCell>
                  <TableCell align={"right"}>Datum</TableCell>
                  <TableCell align={"right"}>Modul</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {course.holidays?.map((holiday, index) => (
                  <TableRow key={index} sx={{ opacity: today > holiday.day ? 0.5 : 1 }} hover={true}>
                    <TableCell component={"th"} scope={"row"}>
                      {holiday.name}
                    </TableCell>
                    <TableCell align={"right"}>{holiday.getDateRangeAsString()}</TableCell>
                    <TableCell align={"right"}>{getActiveModuleName(holiday.day)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </MainCard>
  );
};
