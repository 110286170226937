import React, { useEffect, useState } from "react";

// MUI
import { Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Pagination from "@mui/material/Pagination";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

// TS-Hub
import { Participant } from "../../../models/participant";
import { Activity, IActivity } from "../../../models/activity";
import { ApiParticipantService } from "../../../services/apiParticipantService";
import { CenteredBox } from "../../../components/box/centeredBox";

interface ActivitiesTimelineProps {
  participant: Participant;
}
export const ActivitiesTimeline: React.FC<ActivitiesTimelineProps> = props => {
  const { participant } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    ApiParticipantService.fetchActivities(participant.id, new URLSearchParams({ page: String(page) })).then(res => {
      const data = res.data.results.map((r: IActivity) => new Activity(r));
      setActivities(data);
      setPages(res.data.countPages);
      setIsLoading(false);
    });
  }, [participant, page]);

  function onPageChangesHandler(page: number) {
    setPage(page);
  }

  function createTableView(activity: Activity) {
    const rows = [];
    for (const key in activity.changes) {
      rows.push(
        <TableRow key={key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell component={"th"} scope={"row"}>
            {key}
          </TableCell>
          <TableCell align={"right"}>{activity.changes[key][0]}</TableCell>
          <TableCell align={"right"}>{activity.changes[key][1]}</TableCell>
        </TableRow>
      );
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Wert</TableCell>
              <TableCell align={"right"}>Vorher</TableCell>
              <TableCell align={"right"}>Nachher</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{rows.map(row => row)}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <Box>
      <Box width={"100%"}>
        <h3>Aktivitäten</h3>
        <CenteredBox>
          <Pagination count={pages} size={"large"} page={page} onChange={(event, page) => onPageChangesHandler(page)} />
        </CenteredBox>

        <Timeline>
          {activities.map((a, index) => {
            return (
              <TimelineItem key={index} sx={{ opacity: isLoading ? 0.4 : 1 }}>
                <TimelineOppositeContent color={"text.secondary"}>
                  <div>
                    {a.createdAt.toLocaleString("de")}
                    <div>User: {a.creator}</div>
                    <div>Datensatz: {a.getContentType()}</div>
                  </div>
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>{createTableView(a)}</TimelineContent>
              </TimelineItem>
            );
          })}
        </Timeline>
      </Box>
    </Box>
  );
};
