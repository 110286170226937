import dayjs, { Dayjs } from "dayjs";
import download from "downloadjs";

import { ApiParticipantService } from "../services/apiParticipantService";
import { getInfinitePaginatedData } from "../helper/http";
import { IPerformanceTracking, PerformanceTracking, IPerformanceGraphParams } from "./performanceTracking";

export enum Gender {
  MAN = "m",
  WOMAN = "w",
  DIVERSE = "d"
}

export interface ICompareStatistic {
  participant: number;
  course: number;
}

export interface ILastMonthStatistic {
  homework: number;
  absence: number;
  presence: number;
  grade: number;
}

export interface IParticipantStatistic {
  homeworkAvg: ICompareStatistic;
  absenceAvg: ICompareStatistic;
  presenceAvg: ICompareStatistic;
  gradeAvg: ICompareStatistic;
  lastMonth: ILastMonthStatistic;
}

export interface IParticipant {
  id?: number;

  tsId?: string;

  email: string;

  firstName: string;

  lastName: string;

  dateOfBirth: Dayjs;

  gender: Gender;

  postalCode: string;

  city: string;

  clientNumber: string;

  course: string;

  courseName: string;

  hubspotId?: string;

  hubspotLeadStatus?: string;

  hubspotDocumentNumber?: string;

  performanceTrackingLastMonthDone?: boolean;

  statistics?: IParticipantStatistic;

  groups?: Array<number>;

  numberOfTeachingUnitsDone?: number;

  numberOfVacationDaysTaken?: number;
}

export class Participant implements IParticipant {
  id: number;

  tsId: string;

  email: string;

  firstName: string;

  lastName: string;

  dateOfBirth: Dayjs;

  gender: Gender;

  postalCode: string;

  city: string;

  clientNumber: string;

  course: string;

  courseName: string;

  hubspotId?: string;

  hubspotLeadStatus?: string;

  hubspotDocumentNumber?: string;

  performanceTracking: Array<IPerformanceTracking> = [];

  performanceTrackingLastMonthDone?: boolean;

  statistics: IParticipantStatistic;

  groups: Array<number> = [];

  numberOfTeachingUnitsDone?: number;

  numberOfVacationDaysTaken?: number;

  constructor(data: IParticipant) {
    this.id = data.id || 0;
    this.tsId = `${data.courseName}-${data.hubspotDocumentNumber}`;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.dateOfBirth = dayjs(data.dateOfBirth);
    this.gender = data.gender;

    this.postalCode = data.postalCode;
    this.city = data.city;

    this.clientNumber = data.clientNumber;
    this.course = data.course;
    this.courseName = data.courseName;
    this.hubspotId = data.hubspotId;
    this.hubspotLeadStatus = data.hubspotLeadStatus;
    this.hubspotDocumentNumber = data.hubspotDocumentNumber;

    this.performanceTrackingLastMonthDone = data.performanceTrackingLastMonthDone;
    this.statistics = data.statistics as IParticipantStatistic;

    this.groups = data.groups || [];

    this.numberOfTeachingUnitsDone = data.numberOfTeachingUnitsDone;
    this.numberOfVacationDaysTaken = data.numberOfVacationDaysTaken;
  }

  public getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  public async downloadPresenceTrackingReport(period: Date) {
    const res = await ApiParticipantService.fetchPresenceReportForParticipant(
      this.id,
      (period.getMonth() + 1).toString(),
      period.getFullYear().toString()
    );
    if (res.status === 200) {
      const fileName = `${period.getFullYear()}_${period.getMonth() + 1}__${this.lastName}_${this.firstName}.pdf`;
      return download(res.data, fileName, "application/pdf");
    }
  }

  /**
   * Calls the backend to create or update the presence tracking reports for a single participant.
   *
   * @param period
   */
  public async createOrUpdatePresenceTrackingReport(period: Date) {
    return await ApiParticipantService.createPresenceReportForParticipant(
      this.id,
      (period.getMonth() + 1).toString(),
      period.getFullYear().toString()
    );
  }

  /**
   * Calls the backend to get the performance tracking for the participant.
   *
   */
  public async getPerformanceTracking() {
    return getInfinitePaginatedData<IPerformanceTracking>(
      ApiParticipantService.fetchPerformanceTracking,
      [],
      new URLSearchParams({ participant: String(this.id) })
    ).then(res => {
      this.performanceTracking = res.data.map(x => new PerformanceTracking(x));
      const tDetailData: Array<IPerformanceGraphParams> = [];
      const tDetailDataKeys: Array<string> = [];
      this.performanceTracking.map(x => {
        x.performanceTrackingParameters.map(y => {
          if (!tDetailDataKeys.includes(y.name)) {
            tDetailDataKeys.push(y.name);
          }

          const e = tDetailData.find(p => p.dateMonth === x.dateMonth && p.dateYear === x.dateYear);
          if (e && e.dateYear) {
            e[y.name] = y.grade;
          } else {
            const d: IPerformanceGraphParams = { dateMonth: x.dateMonth as number, dateYear: x.dateYear, result: x.result };
            d[y.name] = y.grade;
            tDetailData.push(d);
          }
        });
      });

      return { performanceTracking: this.performanceTracking, tDetailData, tDetailDataKeys };
    });
  }
}
