import React from "react";
import { useTranslation } from "react-i18next";
import { Bar, CartesianGrid, ComposedChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { Grade } from "../../../constants";
import { MainCard } from "../../../components/cards";

interface PerformanceParameters {
  dateMonth: number;
  dateYear: number;

  [key: string | number]: Grade;
}

interface PerformanceTrackingDetailsChartProps {
  detailDataKeys: Array<string>;
  detailData: Array<PerformanceParameters>;
}

const colors = ["#8cf5bf", "#62ab95", "#62ab95", "#8cbcf5", "#9bf58c", "#6cab62"];

export const PerformanceTrackingDetailsChart: React.FC<PerformanceTrackingDetailsChartProps> = props => {
  const { detailDataKeys, detailData } = props;
  const { t } = useTranslation();

  /**
   * Creates label for the chart label.
   *
   * @param label
   * @param payload
   */

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getLabel(label: string, payload: any) {
    let text = "";
    if (Array.isArray(payload)) {
      if (payload[0] !== undefined) {
        text = `Zeitraum: ${payload[0].payload.dateMonth}.${payload[0].payload.dateYear}`;
      }
    }
    return <p>{text}</p>;
  }

  return (
    <React.Fragment>
      <MainCard sx={{ height: "auto" }} title={"Details"}>
        <ResponsiveContainer width={"100%"} height={300}>
          <ComposedChart data={detailData} syncId={"anyId"}>
            <CartesianGrid horizontal={true} vertical={false} strokeDasharray={"1 1"} />
            <XAxis dataKey={"dateMonth"} />
            <YAxis domain={[0, 6]} />
            <Tooltip
              contentStyle={{ backgroundColor: "#121212", color: "#fff", border: "none" }}
              cursor={false}
              labelFormatter={getLabel}
            />
            <Legend />

            {detailDataKeys.map((key, index) => {
              return (
                <Bar
                  type={"monotone"}
                  key={key}
                  dataKey={key}
                  name={`${t("COMMON.WORDS.Grade")} ${key}`}
                  fill={colors.reverse()[index]}
                  width={2}
                />
              );
            })}
          </ComposedChart>
        </ResponsiveContainer>
      </MainCard>
    </React.Fragment>
  );
};
