import React, { useState } from "react";
import { Dayjs } from "dayjs";
import download from "downloadjs";

// MUI
import { Chip, Divider, IconButton, Menu, MenuItem, Tooltip } from "@mui/material";

// TS-Hub
import { Participant } from "../../../../models/participant";
import { PerformanceTrackingModal } from "../../../../components/performanceTracking/performanceTrackingModal";
import { SimpleTableCell, SimpleTableRow } from "../../../../components/tables/simpleTable";
import { ApiParticipantService } from "../../../../services/apiParticipantService";
import { Course } from "../../../../models/course";
import { useSnackbar } from "../../../../provider/snackbar";
import { PerformanceTrackingInterimReportModal } from "../../../../components/performanceTracking/performanceTrackingInterimReportModal";
import MoreVertIcon from "@mui/icons-material/MoreVert";

type CourseParticipantsPerformanceListRowProps = {
  course: Course;
  participant: Participant;
};

export const CourseParticipantsPerformanceListRow: React.FC<CourseParticipantsPerformanceListRowProps> = props => {
  const { course, participant } = props;
  const [isPerformanceTrackingModalOpen, setIsPerformanceTrackingModalOpen] = useState(false);
  const [isPerformanceTrackingInterimReportModalOpen, setIsPerformanceTrackingInterimReportModalOpen] = useState(false);
  const { toast } = useSnackbar();

  // Menu variables
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenuOnClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);
  const handleMenuOnClose = () => setAnchorEl(null);

  /**
   * Downloads the performance tracking quarter report for the participant.
   *
   * @param participant
   * @param quarter
   */
  async function downloadPerformanceTrackingQuarterReport(participant: Participant, quarter: number) {
    const res = await ApiParticipantService.fetchPerformanceQuarterReportForParticipant(participant.id, quarter);
    if (res.status === 200) {
      const fileName = `${participant.firstName}_${participant.lastName}_Quarter_Report_${quarter}.pdf`;
      return download(res.data, fileName, "application/pdf");
    } else if (res.status === 403) {
      toast({
        level: "warning",
        message: "Den Quartalsbericht können nur Classmanager erstellen."
      });
    } else {
      toast({
        level: "warning",
        message: "Fehler beim Herunterladen des Berichts. Bitte überprüfe in deinem Profil, ob deine Unterschrift vorliegt."
      });
    }
  }

  /**
   * Handles the closing of the modal.
   *
   */
  async function handleModalOnClose() {
    setIsPerformanceTrackingModalOpen(false);
    await participant.getPerformanceTracking();
  }

  /**
   * Renders the table cell for every participant.
   *
   * @param participant
   * @param d
   */
  function renderTableCell(participant: Participant, d: Dayjs) {
    const performanceTracking = participant.performanceTracking.find(
      pt => pt.dateYear === d.year() && pt.dateMonth === d.month() + 1
    );

    return performanceTracking ? (
      <Tooltip title={performanceTracking.getTrack()}>
        <Chip
          color={performanceTracking.result <= 2.4 ? "success" : performanceTracking.result >= 4.0 ? "error" : "warning"}
          variant={"outlined"}
          label={performanceTracking.result.toFixed(2)}
        />
      </Tooltip>
    ) : (
      <div></div>
    );
  }

  /**
   * Renders the table row for every participant.
   *
   * @param participant
   */
  return (
    <SimpleTableRow key={participant.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }} hover={true}>
      <SimpleTableCell component={"th"} scope={"row"}>
        {participant.firstName} {participant.lastName}
      </SimpleTableCell>

      {course?.getListOfMonths().map((d, index) => (
        <SimpleTableCell key={index} align={"center"}>
          {renderTableCell(participant, d)}
        </SimpleTableCell>
      ))}

      <SimpleTableCell align={"right"}>
        <IconButton
          id={`table-button-${participant.id}`}
          aria-controls={open ? `table-menu-${participant.id}` : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleMenuOnClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={`table-menu-${participant.id}`}
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuOnClose}
          MenuListProps={{
            "aria-labelledby": "basic-button"
          }}
        >
          <MenuItem
            onClick={() => {
              handleMenuOnClose();
              setIsPerformanceTrackingModalOpen(true);
            }}
          >
            Leistungsbewertung erstellen
          </MenuItem>

          <Divider />

          <MenuItem
            onClick={() => {
              handleMenuOnClose();
              setIsPerformanceTrackingInterimReportModalOpen(true);
            }}
          >
            Zwischenbericht erstellen
          </MenuItem>

          <Divider />

          <MenuItem onClick={() => downloadPerformanceTrackingQuarterReport(participant, 1)}>Bericht Q1</MenuItem>
          <MenuItem onClick={() => downloadPerformanceTrackingQuarterReport(participant, 2)}>Bericht Q2</MenuItem>
          <MenuItem onClick={() => downloadPerformanceTrackingQuarterReport(participant, 3)}>Bericht Q3</MenuItem>
          <MenuItem onClick={() => downloadPerformanceTrackingQuarterReport(participant, 4)}>Bericht Q4</MenuItem>
        </Menu>
      </SimpleTableCell>

      <PerformanceTrackingModal
        isOpen={isPerformanceTrackingModalOpen}
        onClose={() => handleModalOnClose()}
        participantId={participant.id}
      />

      <PerformanceTrackingInterimReportModal
        isOpen={isPerformanceTrackingInterimReportModalOpen}
        onClose={() => setIsPerformanceTrackingInterimReportModalOpen(false)}
        participant={participant}
      />
    </SimpleTableRow>
  );
};
