import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

// MUI
import { Grid } from "@mui/material";

// TS-Hub
import { ApiParticipantService } from "../../services/apiParticipantService";
import { IPresenceTrackingMonthReport } from "../../models/presenceTracking";
import { MainCard } from "../../components/cards";

export const CardPresenceMonthReport: React.FC = () => {
  const [data, setData] = useState<Array<IPresenceTrackingMonthReport>>([]);
  const { t } = useTranslation();

  useEffect(() => {
    ApiParticipantService.fetchPresenceTrackingMonthReport().then(res => {
      setData(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12} sm={12} md={6}>
        <MainCard title={`${t("COMMON.WORDS.Presence")} - ${dayjs().format("MMMM YYYY")}`}>
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart data={data}>
              <CartesianGrid horizontal={true} vertical={false} strokeDasharray={"1 1"} />
              <XAxis dataKey={"name"} />
              <YAxis />
              <Tooltip contentStyle={{ backgroundColor: "#121212", color: "#fff", border: "none" }} cursor={false} />
              <Legend />
              <Line
                type={"monotone"}
                dataKey={"countPresentMo"}
                name={`${t("COMMON.WORDS.Present")} (${t("COMMON.WORDS.Morning")})`}
                stroke={"#fc6549"}
                strokeWidth={"3"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
              <Line
                type={"monotone"}
                dataKey={"countPresentAn"}
                name={`${t("COMMON.WORDS.Present")} (${t("COMMON.WORDS.Afternoon")})`}
                stroke={"#43755B"}
                strokeWidth={"1"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <MainCard title={`${t("COMMON.WORDS.Absent")} - ${dayjs().format("MMMM YYYY")}`}>
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart data={data}>
              <CartesianGrid horizontal={true} vertical={false} strokeDasharray={"1 1"} />
              <XAxis dataKey={"name"} />
              <YAxis />
              <Tooltip contentStyle={{ backgroundColor: "#121212", color: "#fff", border: "none" }} cursor={false} />
              <Legend />
              <Line
                type={"monotone"}
                dataKey={"countAbsentMo"}
                name={`${t("COMMON.WORDS.Absent")} (${t("COMMON.WORDS.Morning")})`}
                stroke={"#fc6549"}
                strokeWidth={"3"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
              <Line
                type={"monotone"}
                dataKey={"countAbsentAn"}
                name={`${t("COMMON.WORDS.Absent")} (${t("COMMON.WORDS.Afternoon")})`}
                stroke={"#43755B"}
                strokeWidth={"1"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <MainCard title={`${t("COMMON.WORDS.Vacation")} - ${dayjs().format("MMMM YYYY")}`}>
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart data={data}>
              <CartesianGrid horizontal={true} vertical={false} strokeDasharray={"1 1"} />
              <XAxis dataKey={"name"} />
              <YAxis />
              <Tooltip contentStyle={{ backgroundColor: "#121212", color: "#fff", border: "none" }} cursor={false} />
              <Legend />
              <Line
                type={"monotone"}
                dataKey={"countVacationMo"}
                name={`${t("COMMON.WORDS.Vacation")} (${t("COMMON.WORDS.Morning")})`}
                stroke={"#fc6549"}
                strokeWidth={"3"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
              <Line
                type={"monotone"}
                dataKey={"countVacationAn"}
                name={`${t("COMMON.WORDS.Absent")} (${t("COMMON.WORDS.Afternoon")})`}
                stroke={"#43755B"}
                strokeWidth={"1"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MainCard>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <MainCard title={`${t("COMMON.WORDS.Excused")} - ${dayjs().format("MMMM YYYY")}`}>
          <ResponsiveContainer width={"100%"} height={300}>
            <LineChart data={data}>
              <CartesianGrid horizontal={true} vertical={false} strokeDasharray={"1 1"} />
              <XAxis dataKey={"name"} />
              <YAxis />
              <Tooltip contentStyle={{ backgroundColor: "#121212", color: "#fff", border: "none" }} cursor={false} />
              <Legend />
              <Line
                type={"monotone"}
                dataKey={"countExcusedMo"}
                name={`${t("COMMON.WORDS.Excused")} (${t("COMMON.WORDS.Morning")})`}
                stroke={"#fc6549"}
                strokeWidth={"3"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
              <Line
                type={"monotone"}
                dataKey={"countExcusedAn"}
                name={`${t("COMMON.WORDS.Excused")} (${t("COMMON.WORDS.Afternoon")})`}
                stroke={"#43755B"}
                strokeWidth={"1"}
                dot={{ strokeWidth: 2, r: 1 }}
                activeDot={{ strokeWidth: 2, r: 5 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </MainCard>
      </Grid>
    </React.Fragment>
  );
};
