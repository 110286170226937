import React, { useEffect, useState } from "react";

// MUI
import { Grid } from "@mui/material";

import { AnalyticCard } from "../../components/cards";
import { ApiParticipantService } from "../../services/apiParticipantService";
import { ParticipantsSummary } from "../../models/participantsSummary";
import Typography from "@mui/material/Typography";

export const CardParticipantsSummary: React.FC = () => {
  const [summary, setSummary] = useState<ParticipantsSummary>();

  useEffect(() => {
    ApiParticipantService.fetchParticipantsSummary().then(res => {
      setSummary(new ParticipantsSummary(res.data));
    });
  }, []);

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"Teilnehmende Gesamt"}
          count={summary?.countParticipantsTotal || 0}
          variant={"outlined"}
          extra={
            <>
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                Anzahl
              </Typography>{" "}
              aller Teilnehmende.
            </>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"davon Frauen"}
          count={summary?.countParticipantsWomen || 0}
          variant={"outlined"}
          extra={
            <>
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                Anzahl weiblicher
              </Typography>{" "}
              Teilnehmende.
            </>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"davon Männer"}
          count={summary?.countParticipantsMen || 0}
          variant={"outlined"}
          extra={
            <>
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                Anzahl männlicher
              </Typography>{" "}
              Teilnehmende.
            </>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"Frauenquote"}
          count={`${summary?.perParticipantsWomen || 0} %`}
          variant={"outlined"}
          extra={
            <>
              Anteil
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                weiblicher Teilnehmende
              </Typography>{" "}
              in Prozent.
            </>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"Ø Alter"}
          count={summary?.avgAges || 0}
          variant={"outlined"}
          extra={
            <>
              Der{" "}
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                Altersdurchschnitt
              </Typography>{" "}
              über alle Kurse hinweg.
            </>
          }
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"Ø Kursgröße"}
          count={summary?.avgCourseSize || 0}
          variant={"outlined"}
          extra={
            <>
              Die durchschnittliche{" "}
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                Anzahl Teilnehmende
              </Typography>{" "}
              pro Kurs.
            </>
          }
        />
      </Grid>
    </React.Fragment>
  );
};
