import React from "react";

import { Theme, useTheme } from "@mui/material/styles";
import { Card, CardContent, CardHeader, Divider, CardActions } from "@mui/material";
import { SxProps } from "@mui/system";

interface MainCardProps extends React.PropsWithChildren {
  title?: string | React.ReactNode;
  border?: boolean;
  boxShadow?: boolean;
  sx?: SxProps;
  contentSX?: SxProps;
  cardActions?: React.ReactNode;
}

const cardStyle = (theme: Theme) => {
  return {
    height: "100%",
    border: "1px solid",
    borderRadius: 0.5,
    borderColor: theme.palette.mode === "dark" ? theme.palette.divider : theme.palette.divider,
    display: "flex",
    flexDirection: "column",
    "& pre": {
      m: 0,
      p: "16px !important",
      fontFamily: theme.typography.fontFamily,
      fontSize: "0.75rem"
    }
  };
};

export const MainCard: React.FC<MainCardProps> = props => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Card
        sx={
          {
            ...cardStyle(theme),
            ...props.sx
          } as SxProps
        }
      >
        {/* Card header and action (subtitle1) */}
        {props.title && (
          <CardHeader
            sx={{
              p: 2.5,
              "& .MuiCardHeader-action": { m: "0px auto", alignSelf: "center" }
            }}
            titleTypographyProps={{ variant: "h6" }}
            title={props.title}
          />
        )}

        {/* Content & Header divider */}
        {props.title && <Divider />}

        {/* Card content */}
        {<CardContent sx={{ ...props.contentSX, ...{ flexGrow: 1 } }}>{props.children}</CardContent>}

        {/* Card actions */}
        {props.cardActions && <Divider />}
        {props.cardActions && <CardActions style={{ width: "100%" }}>{props.cardActions}</CardActions>}
      </Card>
    </React.Fragment>
  );
};
