import React from "react";

import Box from "@mui/material/Box";

export const CenteredLayout: React.FC<React.PropsWithChildren> = props => {
  return (
    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} minHeight={"100vh"}>
      {props.children}
    </Box>
  );
};
