import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";

// TS-Hub
import { Participant } from "../../../models/participant";
import { IPerformanceGraphParams, PerformanceTracking } from "../../../models/performanceTracking";
import { TrackingAlert } from "./trackingAlert";
import { PerformanceTrackingDetailsChart } from "./performanceTrackingDetailsChart";
import { PerformanceTrackingGradeChart } from "./performanceTrackingGradeChart";

type PerformanceTrackingProps = {
  participant: Participant;
};

export const PerformanceTrackingCard: React.FC<PerformanceTrackingProps> = props => {
  const [data, setData] = useState<Array<PerformanceTracking>>([]);
  const [detailDataKeys, setDetailDataKeys] = useState<Array<string>>([]);
  const [detailData, setDetailData] = useState<Array<IPerformanceGraphParams>>([]);
  const lastData = data[data.length - 1];
  const { participant } = props;

  /**
   * useEffect to fetch the performance tracking data.
   *
   */
  useEffect(() => {
    participant.getPerformanceTracking().then(data => {
      setData(data.performanceTracking);
      setDetailData(data.tDetailData);
      setDetailDataKeys(data.tDetailDataKeys);
    });
  }, []);

  return (
    <React.Fragment>
      <Box maxWidth={"400px"} margin={"auto"}>
        <TrackingAlert result={lastData ? lastData.result : null} />
      </Box>

      <PerformanceTrackingGradeChart data={data} />

      <br />

      <PerformanceTrackingDetailsChart detailDataKeys={detailDataKeys} detailData={detailData} />
    </React.Fragment>
  );
};
