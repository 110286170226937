import React from "react";
import { useNavigate } from "react-router-dom";

// MUI
import { Button, Paper } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// TS-Hub
import { Program } from "../../../models/program";

type GeneralTableProps = {
  program?: Program;
};

export const GeneralTable: React.FC<GeneralTableProps> = props => {
  const { program } = props;
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>UE</TableCell>
            <TableCell>UE/Tag</TableCell>
            <TableCell>Unterrichtstage</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {program?.modules?.map((module, index) => (
            <TableRow key={index} hover={true}>
              <TableCell>{module.title}</TableCell>
              <TableCell>{module.teachingUnitsTotal}</TableCell>
              <TableCell>{module.teachingUnitsPerDay}</TableCell>
              <TableCell>{module.teachingUnitsTotal / module.teachingUnitsPerDay}</TableCell>
              <TableCell>
                <Button onClick={() => navigate(`/programs/${2}`)} variant={"contained"}>
                  Details
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
