import { ITopic, Topic } from "./topic";

export interface IModule {
  id: number;

  title: string;

  teachingUnitsTotal: number;

  teachingUnitsPerDay: number;

  topics: Array<Topic>;
}

export class Module implements IModule {
  id: number;

  title: string;

  teachingUnitsTotal: number;

  teachingUnitsPerDay: number;

  topics: Array<Topic> = [];

  constructor(data: IModule) {
    this.id = data.id;
    this.title = data.title;
    this.teachingUnitsTotal = data.teachingUnitsTotal;
    this.teachingUnitsPerDay = data.teachingUnitsPerDay;

    data.topics.map(d => this.topics.push(new Topic(d as ITopic)));
  }
}
