import dayjs, { Dayjs } from "dayjs";

export interface IPerformanceInterimReport {
  id?: number;
  participant: number;
  user: number;
  startDate: Dayjs;
  endDate: Dayjs;
  commentSkills: string;
  commentPersonalTraits: string;
  canAchieveObjectives: boolean;
}

export class PerformanceInterimReport implements IPerformanceInterimReport {
  id?: number;
  participant: number;
  user: number;
  startDate: Dayjs;
  endDate: Dayjs;
  commentSkills: string;
  commentPersonalTraits: string;
  canAchieveObjectives: boolean;

  constructor(data: IPerformanceInterimReport) {
    this.id = data.id;
    this.participant = data.participant;
    this.user = data.user;
    this.startDate = dayjs(data.startDate);
    this.endDate = dayjs(data.endDate);
    this.commentSkills = data.commentSkills;
    this.commentPersonalTraits = data.commentPersonalTraits;
    this.canAchieveObjectives = data.canAchieveObjectives;
  }
}
