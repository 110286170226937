export interface IParticipantSummary {
  avgAges: number;
  avgCourseSize: number;
  countParticipantsTotal: number;
  countParticipantsWomen: number;
  countParticipantsMen: number;
  perParticipantsWomen: number;
}
export class ParticipantsSummary implements IParticipantSummary {
  avgAges: number;
  avgCourseSize: number;
  countParticipantsTotal: number;
  countParticipantsWomen: number;
  countParticipantsMen: number;
  perParticipantsWomen: number;

  constructor(data: IParticipantSummary) {
    this.avgAges = data.avgAges;
    this.avgCourseSize = data.avgCourseSize;
    this.countParticipantsTotal = data.countParticipantsTotal;
    this.countParticipantsWomen = data.countParticipantsWomen;
    this.countParticipantsMen = data.countParticipantsMen;
    this.perParticipantsWomen = data.perParticipantsWomen;
  }
}
