import React from "react";

import { Box, Chip, Grid, Stack, Typography, Tooltip } from "@mui/material";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CallReceivedIcon from "@mui/icons-material/CallReceived";

import { MainCard } from "./mainCard";

type AnalyticCardProps = {
  title: string;
  color?: "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
  count: number | string;
  percentage?: number | string;
  percentageDescription?: string;
  isLoss?: boolean;
  variant: "outlined" | "filled";
  extra?: React.ReactNode;
};

export const AnalyticCard: React.FC<AnalyticCardProps> = props => {
  return (
    <MainCard contentSX={{ p: 2.25 }}>
      <Stack spacing={0.5}>
        <Typography variant={"h6"} color={"textSecondary"}>
          {props.title}
        </Typography>

        <Grid container alignItems={"center"}>
          <Grid item>
            <Typography variant="h4" color="inherit">
              {props.count}
            </Typography>
          </Grid>

          {(props.percentage || props.percentage === 0) && (
            <Grid item>
              <Tooltip title={props.percentageDescription}>
                <Chip
                  variant={props.variant}
                  color={props.color}
                  icon={
                    <>
                      {!props.isLoss && <CallMadeIcon style={{ fontSize: "0.75rem", color: "green" }} />}
                      {props.isLoss && <CallReceivedIcon style={{ fontSize: "0.75rem", color: "red" }} />}
                    </>
                  }
                  label={`${props.percentage}%`}
                  sx={{ ml: 1.25, pl: 1 }}
                  size={"small"}
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Stack>

      {props.extra && (
        <Box sx={{ pt: 2.25 }}>
          <Typography variant="caption" color="textSecondary">
            {props.extra}
          </Typography>
        </Box>
      )}
    </MainCard>
  );
};
