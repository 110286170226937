import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

// MUI
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

// TS-Hub
import { useSnackbar } from "../../../provider/snackbar";
import { Participant } from "../../../models/participant";
import { ICommentCreate } from "../../../models/comment";
import { ApiParticipantService } from "../../../services/apiParticipantService";

type CommentModalProps = {
  isOpen: boolean;
  onClose: Function;
  participant: Participant;
};

export const CommentModal: React.FC<CommentModalProps> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { isOpen, onClose, participant } = props;

  const { toast } = useSnackbar();
  const { register, handleSubmit, reset } = useForm<ICommentCreate>({
    values: {
      comment: "",
      participant: participant.id
    }
  });

  /**
   * Handler for the onSubmit event.
   *
   * @param data
   */
  async function handleOnSubmit(data: ICommentCreate) {
    setIsLoading(true);
    const res = await ApiParticipantService.createCommentForParticipant(data);
    if (res.status === 201) {
      toast({ message: t("COMMENTS.TOASTS.CREATED_SUCCESSFULLY"), level: "success" });
      setTimeout(() => handleOnClose(), 2_000);
    } else {
      toast({ message: t("COMMENTS.TOASTS.CREATION_FAILED"), level: "error" });
      setIsLoading(false);
    }
  }

  /**
   * Handles the onClose event of the modal.
   *
   */
  function handleOnClose() {
    setIsLoading(false);
    reset();
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose} maxWidth={"md"} fullWidth={true}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle>Kommentar zum Teilnehmenden hinzufügen</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ marginTop: "0" }}>
            <Grid item xs={12} sm={12}>
              <TextField
                id={"entry"}
                label={t("COMMON.WORDS.Comment")}
                fullWidth={true}
                variant={"outlined"}
                multiline={true}
                minRows={4}
                required={true}
                {...register("comment")}
              />
            </Grid>
            <Grid item width={"100%"}>
              <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}></Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
            <Trans i18nKey={"COMMON.WORDS.Save"} />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
