import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";

// MUI
import { Link, Paper } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// TS-Hub
import { Program } from "../../models/program";
import { ApiProgramsService } from "../../services/apiProgramsService";

type GeneralTableProps = {
  programId?: number;
};

export const ModuleTopicsTable: React.FC<GeneralTableProps> = props => {
  const [program, setProgram] = useState<Program>();
  const { programId } = props;

  useEffect(() => {
    ApiProgramsService.fetchProgram(Number(programId)).then(res => {
      if (res.status === 200) {
        setProgram(new Program(res.data));
      }
    });
  }, [programId]);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Modul</TableCell>
            <TableCell>Thema</TableCell>
            <TableCell>Beschreibung</TableCell>
            <TableCell>UE</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {program?.modules?.map((module, index) => (
            <React.Fragment key={index}>
              <TableRow hover={true}>
                <TableCell>{module.title}</TableCell>
                <TableCell />
                <TableCell />
                <TableCell>{module.teachingUnitsTotal}</TableCell>
              </TableRow>
              {module.topics.map(topic => (
                <TableRow key={index} hover={true}>
                  <React.Fragment key={topic.id}>
                    <TableCell />

                    <TableCell>
                      <Link href={topic.notionLink} target={"_blank"}>
                        {topic.title}
                      </Link>
                    </TableCell>
                    <TableCell>
                      <Markdown>{topic.description}</Markdown>
                    </TableCell>
                    <TableCell>{topic.teachingUnitsTotal}</TableCell>
                  </React.Fragment>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
