import dayjs, { Dayjs } from "dayjs";

export enum TodoTitle {
  CLASS_BOOK_ENTRY__ADD = "TODO__AAA",
  NPS__CREATE_SURVEY = "TODO__ABA"
}

export interface ITodo {
  id: number;
  title: TodoTitle;
  doingDate: Dayjs;
}

export class Todo implements ITodo {
  id: number;

  title: TodoTitle;

  doingDate: Dayjs;

  constructor(data: ITodo) {
    this.id = data.id;
    this.title = data.title;
    this.doingDate = dayjs(data.doingDate);
  }

  public todoTitle() {
    switch (this.title) {
      case TodoTitle.CLASS_BOOK_ENTRY__ADD:
        return "Add new class book entry";
      case TodoTitle.NPS__CREATE_SURVEY:
        return "Create new NPS survey";
      default:
        return this.title;
    }
  }
}
