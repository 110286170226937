import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

// MUI
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Alert from "@mui/material/Alert";
import { Button, Chip } from "@mui/material";

// TS-Hub
import { CourseBookRecord } from "../../../../models/courseBookRecord";
import { CourseBookRecordsTable } from "./courseBookRecordsTable";
import { ExportOnlyToolbar } from "../../../../components/tables/toolbars";
import { ApiClassesService } from "../../../../services/apiClassesService";
import { CourseBook, ICompletionCheck } from "../../../../models/courseBook";

type CourseBookAccordionProps = {
  courseBook: CourseBook;
  year: number;
  month: number;
  monthName: string;
  records: Array<CourseBookRecord>;
  refreshCourseBook: Function;
};

export const CourseBookAccordion: React.FC<CourseBookAccordionProps> = props => {
  const { courseBook, year, month, monthName, records, refreshCourseBook } = props;
  const [completedState, setCompletedState] = useState<ICompletionCheck>();
  const [countNotApproved, setCountNotApproved] = useState(0);

  /**
   * useEffect to fetch the data from the backend.
   *
   */
  useEffect(() => {
    setTimeout(async () => {
      const res = await ApiClassesService.checkCompletion(courseBook.id, String(month + 1), String(year));
      setCompletedState(res.data as ICompletionCheck);
    }, 5);

    setCountNotApproved(records.filter(r => !r.isApproved).length);
  }, [courseBook]);

  /**
   * Renders and returns a single accordion.
   *
   */
  return (
    <Accordion key={`${year}-${month}`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`${year}-${month}`}>
        <Stack direction={"row"} justifyContent={"space-between"} width={"95%"} alignItems={"center"}>
          <Box>
            <Typography>
              {year} {monthName}
            </Typography>
          </Box>

          <Box>
            <Stack direction="row" spacing={2}>
              {countNotApproved > 0 && (
                <Chip label={`${countNotApproved} Genehmigungen offen`} color={"warning"} variant={"outlined"} />
              )}

              {completedState?.complete ? (
                <Chip label={"Vollständig"} color={"primary"} variant={"outlined"} />
              ) : (
                <Chip label={"Nicht vollständig"} color={"error"} variant={"outlined"} />
              )}
            </Stack>
          </Box>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        {!completedState?.complete && (
          <Alert severity={"warning"}>
            Für einige Tage fehlen noch Einträge in dem Klassenbuch. Erst dann ist das Klassenbuch zum Download freigegeben.
            <br />
            <br />
            {completedState?.missingDays.map(day => dayjs(day).toDate().toLocaleDateString()).join(", ")}
          </Alert>
        )}

        {completedState?.complete && (
          <Alert severity={"success"}>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Button
                variant={"outlined"}
                onClick={() => {
                  courseBook?.downloadCourseBook(new Date(year, month));
                }}
              >
                Download Kursbuch
              </Button>
            </Box>
          </Alert>
        )}
        <CourseBookRecordsTable
          entries={records}
          pageSize={10}
          refreshCourseBook={refreshCourseBook}
          toolbar={ExportOnlyToolbar}
        />
      </AccordionDetails>
    </Accordion>
  );
};
