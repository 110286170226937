import { IUser, User } from "../models/user";

const BASE_KEY = "tss";

export const browserStorage = {
  /**
   * Function to set a value into the browserStorage.
   *
   * @param name
   * @param value
   */
  set(name: string, value: string | boolean | User): void {
    try {
      localStorage.setItem(`${BASE_KEY}:${name}`, JSON.stringify(value));
    } catch (e) {
      throw e;
    }
  },

  /**
   * Function to get a value from the browserStorage with an appropriate key.
   *
   * @param name
   */
  get(name: string): string | null {
    try {
      const value = localStorage.getItem(`${BASE_KEY}:${name}`);
      if (!value) {
        return null;
      }

      return JSON.parse(value);
    } catch (e) {
      return null;
    }
  },

  /**
   * Remove an item from the browserStorage for an appropriate key.
   *
   * @param name
   */
  remove(name: string): void {
    try {
      localStorage.removeItem(`${BASE_KEY}:${name}`);
    } catch (e) {
      throw e;
    }
  },

  // Direct access to accessToken
  getAccessToken(): string {
    return this.get("accessToken") || "";
  },
  setAccessToken(accessToken: string): void {
    return this.set("accessToken", accessToken);
  },

  // Direct access to refreshToken
  getRefreshToken(): string {
    return this.get("refreshToken") || "";
  },
  setRefreshToken(refreshToken: string): void {
    return this.set("refreshToken", refreshToken);
  },

  // Direct access to user
  getUser(): User {
    return new User(Object(this.get("user")) as IUser);
  },
  setUser(user: User): void {
    return this.set("user", user);
  },

  // Direct access to user authentication state
  getUserIsAuthenticated(): string | null {
    return this.get("user:isa");
  },
  setUserIsAuthenticated(state: boolean): void {
    return this.set("user:isa", state);
  },

  // Direct access to user otp verification state
  getOTPIsVerifiedState(): string | null {
    return this.get("user:iov");
  },
  setOTPIsVerifiedState(state: boolean): void {
    return this.set("user:iov", state);
  }
};

/**
 * Deletes user data from local storage.
 */
export function deleteBrowserData(): void {
  browserStorage.remove("user");
  browserStorage.remove("user:isa");
  browserStorage.remove("accessToken");
  browserStorage.remove("refreshToken");
}
