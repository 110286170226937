import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

// MUI
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { TutoringBook } from "../../models/tutoringBook";
import { ApiClassesService } from "../../services/apiClassesService";
import { MainCard } from "../../components/cards";
import { TutoringBookRecord } from "../../models/tutoringBookRecord";
import { TutoringBookRecordsTable } from "./components/tutoringBookRecordsTable";
import { ExportOnlyToolbar } from "../../components/tables/toolbars";

type SortedEntries = {
  [key: number]: {
    [key: string]: Array<TutoringBookRecord>;
  };
};

export const TutoringBookDetail: React.FC = () => {
  const { tutoringBookId } = useParams<"tutoringBookId">();
  const [tutoringBook, setTutoringBook] = useState<TutoringBook>();
  const [entries, setEntries] = useState<SortedEntries>({});

  /**
   * useEffect to fetch the data from the backend.
   *
   */
  useEffect(() => {
    if (tutoringBookId) {
      ApiClassesService.fetchTutoringBook(tutoringBookId).then(res => {
        const tCourseBook = new TutoringBook(res.data);
        setTutoringBook(tCourseBook);
        sortEntries(tCourseBook);
      });
    }
  }, [tutoringBookId]);

  /**
   * Create a sorted object of all entries by year and month.
   *
   * This functions creates an object like this:
   * const o = { 2022: { "Dezember": [{}, {}, {}]}}
   *
   * @param tutoringBook
   */
  function sortEntries(tutoringBook: TutoringBook) {
    const tmp: SortedEntries = {};
    tutoringBook.entries.reverse().map(entry => {
      const year = entry.teachingDay.year();
      const month = entry.teachingDay.format("MMMM");

      if (_.isEmpty(tmp[year])) {
        tmp[year] = {};
      }

      if (!Array.isArray(tmp[year][month])) {
        tmp[year][month] = [];
      }

      tmp[year][month].push(entry);
    });
    setEntries(tmp);
  }

  /**
   * Renders and returns a single accordion.
   *
   */
  function renderAccordion(year: number, month: string, entries: Array<TutoringBookRecord>): React.ReactNode {
    return (
      <Accordion key={`${year}-${month}`}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`${year}-${month}`}>
          <Typography>
            {year} {month}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TutoringBookRecordsTable entries={entries} pageSize={10} toolbar={ExportOnlyToolbar} />
        </AccordionDetails>
      </Accordion>
    );
  }

  /**
   * Renders and returns the whole accordion element.
   *
   */
  function renderAccordions(): React.ReactNode {
    const accordions: Array<React.ReactNode> = [];

    Object.keys(entries).forEach(value => {
      const year = Number(value);
      const entriesOfYear = entries[year];
      Object.keys(entriesOfYear).forEach(value => {
        const month = value;
        accordions.push(renderAccordion(year, month, entries[year][month]));
      });
    });

    return accordions;
  }

  if (!tutoringBook) {
    return null;
  }

  return (
    <AppLayout>
      <MainCard title={`Nachhilfebuch: ${tutoringBook.course}`}>{renderAccordions()}</MainCard>
    </AppLayout>
  );
};
