import dayjs, { Dayjs } from "dayjs";

import { Participant } from "./participant";

export interface IHomeworkTracking {
  id?: number;
  participant: Participant;
  date: Dayjs;
  status: boolean;
  comment: string;
}

export interface IHomeworkTrackingCreate {
  participant: number;
  date: Dayjs | string;
  status: boolean;
  comment: string;
}

export interface IHomeworkTrackingUpdate {
  status: boolean;
  comment: string;
}

export class HomeworkTracking implements IHomeworkTracking {
  id?: number;
  participant: Participant;
  date: Dayjs;
  status: boolean;
  comment: string;

  constructor(data: IHomeworkTracking) {
    this.id = data.id;
    this.participant = data.participant;
    this.date = dayjs(data.date);
    this.status = data.status;
    this.comment = data.comment;
  }
}
