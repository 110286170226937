import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Grid, Typography } from "@mui/material";

import { AnalyticCard } from "../../components/cards";
import { IPresenceTrackingSummary } from "../../models/presenceTracking";
import { ApiParticipantService } from "../../services/apiParticipantService";

export const CardsPresenceSummary: React.FC = () => {
  const [summary, setSummary] = useState<IPresenceTrackingSummary>();
  const { t } = useTranslation();

  useEffect(() => {
    ApiParticipantService.fetchPresenceTrackingSummary().then(res => setSummary(res.data));
  }, []);

  if (!summary || !summary.today) {
    return null;
  }

  function calculateDiff(today: number, yesterday: number): number {
    if (yesterday > 0) {
      return Math.round(((today - yesterday) / yesterday) * 100);
    } else {
      return 0;
    }
  }

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={t("DASHBOARD.TITLES.Participants Present")}
          count={summary.today.present}
          percentage={calculateDiff(summary.today.present, summary.yesterday.present)}
          extra={
            <>
              Difference of {"  "}
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                {summary.today.present - summary.yesterday.present}
              </Typography>
            </>
          }
          variant={"filled"}
          isLoss={calculateDiff(summary.today.present, summary.yesterday.present) < 0}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={t("DASHBOARD.TITLES.Participants Absent")}
          count={summary.today.absent}
          percentage={calculateDiff(summary.today.absent, summary.yesterday.absent)}
          extra={
            <>
              Difference of {"  "}
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                {summary.today.absent - summary.yesterday.absent}
              </Typography>
            </>
          }
          variant={"filled"}
          isLoss={calculateDiff(summary.today.absent, summary.yesterday.absent) < 0}
        />
      </Grid>
    </React.Fragment>
  );
};
