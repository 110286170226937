import _ from "lodash";

interface IPaginationData {
  hasNext: boolean;
  hasPrevious: boolean;
  nextQueryParams: URLSearchParams;
  previousQueryParams: URLSearchParams;
}

export interface InfinitePaginatedResponse<T> {
  status: number;
  data: Array<T>;
}

/**
 * Generic response type for list views.
 *
 */
export type ListResponse<T> = {
  links: {
    next: string;
    previous: string;
  };
  countAllResults: number;
  countResults: number;
  countPages: number;
  results: Array<T>;
};

export type ListResponseWithInformation<T> = {
  countAllResults: number;
  countResults: number;
  countPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
  nextQueryParams: URLSearchParams;
  previousQueryParams: URLSearchParams;
  data: Array<T>;
  status: number;
};

/**
 * Checks whether the data object has a `next` or `previous` key in it.
 * If yes, extract the query params and return the state, that there is
 * more to load and return the query params.
 *
 */
export function getPaginationInformation<T>(data: ListResponse<T>): IPaginationData {
  let hasNext = !_.isEmpty(data.links.next);
  let hasPrevious = !_.isEmpty(data.links.previous);
  let nextQueryParams = new URLSearchParams({});
  let previousQueryParams = new URLSearchParams({});

  const nextUrl = String(_.get(data.links, "next", ""));
  const previousUrl = String(_.get(data.links, "previous", ""));

  if (hasNext) {
    const nextIndexOfQuestionMarker = nextUrl.indexOf("?");
    if (nextIndexOfQuestionMarker) {
      const nextParams = nextUrl.slice(nextIndexOfQuestionMarker);
      hasNext = true;
      nextQueryParams = new URLSearchParams(nextParams);
    }
  }

  if (hasPrevious) {
    const previousIndexOfQuestionMarker = previousUrl.indexOf("?");
    if (previousIndexOfQuestionMarker) {
      const previousParams = previousUrl.slice(previousIndexOfQuestionMarker);
      hasPrevious = true;
      previousQueryParams = new URLSearchParams(previousParams);
    }
  }

  return {
    hasNext: hasNext,
    hasPrevious: hasPrevious,
    nextQueryParams: nextQueryParams,
    previousQueryParams: previousQueryParams
  };
}

/**
 * Generic function to fetch paginated data from the server.
 * This function is used to fetch every single page manually.
 *
 * Usage:
 * const data = getPaginatedData<MyClass>(fetchContent)
 */
export async function getPaginatedData<T>(
  callback: Function,
  data: Array<T> = [],
  pageParams: URLSearchParams = new URLSearchParams({})
): Promise<ListResponseWithInformation<T>> {
  const res = await callback(pageParams);
  const paginationInformation = getPaginationInformation(res.data);
  data.push(...res.data.results);

  return {
    countAllResults: res.data.countAllResults,
    countResults: res.data.countResults,
    countPages: res.data.countPages,
    hasNext: paginationInformation.hasNext,
    hasPrevious: paginationInformation.hasPrevious,
    nextQueryParams: paginationInformation.nextQueryParams,
    previousQueryParams: paginationInformation.previousQueryParams,
    data: data,
    status: res.status
  };
}

/**
 * Generic function to make an infinite fetch of paginated data from the server.
 *
 * Usage:
 * const data = getInfinitePaginatedData<MyClass>(fetchContent)
 */
export async function getInfinitePaginatedData<T>(
  callback: Function,
  data: Array<T> = [],
  pageParams: URLSearchParams = new URLSearchParams({})
): Promise<InfinitePaginatedResponse<T>> {
  const res = await getPaginatedData(callback, data, pageParams);

  if (res.status >= 400) {
    return { status: res.status, data: [] };
  }

  if (res.hasNext) {
    await getInfinitePaginatedData(callback, res.data, res.nextQueryParams);
  }

  return { status: res.status, data: res.data };
}
