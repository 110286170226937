import React, { ChangeEvent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// MUI
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField
} from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

// TS-Hub
import { useSnackbar } from "../../provider/snackbar";
import { ApiParticipantService } from "../../services/apiParticipantService";
import { PerformanceTrackingTemplate } from "../../models/performanceTrackingTemplate";
import { IPerformanceTrackingCreate } from "../../models/performanceTracking";
import { Participant } from "../../models/participant";
import { Grade, Month } from "../../constants";
import { getPreviousMonth } from "../../helper/date";
import { PerformanceTrackingAnalytics } from "./performanceTrackingAnalytics";
import { Course } from "../../models/course";
import { ApiClassesService } from "../../services/apiClassesService";

type PerformanceTrackingModalProps = {
  isOpen: boolean;
  onClose: Function;
  participantId: number;
};

export const PerformanceTrackingModal: React.FC<PerformanceTrackingModalProps> = props => {
  const lastMonth = getPreviousMonth();

  const { isOpen, onClose, participantId } = props;
  const defaultValues: IPerformanceTrackingCreate = {
    dateMonth: (lastMonth.getMonth() + 1) as Month,
    dateYear: lastMonth.getFullYear(),
    performanceTrackingParameters: [],
    participant: participantId,
    comment: ""
  };

  const [template, setTemplate] = useState<PerformanceTrackingTemplate>();
  const [performanceTracking, setPerformanceTracking] = useState<IPerformanceTrackingCreate>(defaultValues);
  const [isLoading, setIsLoading] = useState(false);
  const [participant, setParticipant] = useState<Participant>();
  const [course, setCourse] = useState<Course>();
  const { t } = useTranslation();
  const { toast } = useSnackbar();

  /**
   * useEffect that fetch the participant.
   *
   */
  useEffect(() => {
    if (isOpen) {
      setTimeout(async () => {
        const resP = await ApiParticipantService.fetchParticipant(participantId);
        const p = new Participant(resP.data);
        p.getPerformanceTracking();

        const resC = await ApiClassesService.fetchCourse(p.course);
        const c = new Course(resC.data);

        setParticipant(p);
        setCourse(c);
      });
    }
  }, [participantId, isOpen]);

  /**
   * useEffect that fetch the performance tracking template.
   *
   */
  useEffect(() => {
    if (isOpen && course && participantId && participant) {
      ApiParticipantService.fetchPerformanceTrackingTemplate(course.performanceTrackingTemplate).then(res => {
        setTemplate(new PerformanceTrackingTemplate(res.data));
        setPerformanceTracking({
          ...performanceTracking,
          participant: participantId,
          performanceTrackingParameters: res.data.performanceTrackingParameters.map(p => {
            return { id: p.id, name: p.name, grade: Grade.VERY_GOOD };
          })
        });
      });
    }
  }, [course, participant, participantId, isOpen]);

  /**
   * useEffect to set the participant.
   *
   */
  useEffect(() => {
    setPerformanceTracking({ ...performanceTracking, participant: participant?.id || 0 });
  }, [participant?.id]);

  /**
   * Handler for the month change.
   *
   * @param event
   */
  function handleDateMonthOnChange(event: SelectChangeEvent<Month>) {
    setPerformanceTracking({ ...performanceTracking, dateMonth: event.target.value as Month });
  }

  /**
   * Handler for the year change.
   *
   * @param event
   */
  function handleDateYearOnChange(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    setPerformanceTracking({ ...performanceTracking, dateYear: Number(event.target.value) });
  }

  /**
   * Handler for the parameter change.
   *
   * @param event
   * @param index
   */
  function handleParameterOnChange(event: SelectChangeEvent<unknown>, index: number) {
    const t = [...performanceTracking.performanceTrackingParameters];
    const t1 = t[index];
    t1.grade = event.target.value as Grade;
    setPerformanceTracking({ ...performanceTracking, performanceTrackingParameters: t });
  }

  /**
   * Handler for the comment.
   *
   * @param event
   */
  function handleCommentOnChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    setPerformanceTracking({ ...performanceTracking, comment: event.target.value });
  }

  /**
   * Handler for the onSubmit event.
   *
   */
  async function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    const res = await ApiParticipantService.createPerformanceTracking(performanceTracking);
    if (res.status === 201) {
      toast({ message: t("PERFORMANCE_TRACKING.TOASTS.CREATED_SUCCESSFULLY"), level: "success" });
    } else {
      toast({ message: t("PERFORMANCE_TRACKING.TOASTS.CREATION_FAILED"), level: "error" });
    }
    setIsLoading(false);
    handleOnClose();
  }

  /**
   * Handles the onClose event of the modal.
   *
   */
  function handleOnClose() {
    setIsLoading(false);
    onClose();
  }

  if (!template || !participant) {
    return null;
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose} maxWidth={"md"} fullWidth={true}>
      <form onSubmit={handleOnSubmit}>
        <DialogTitle textAlign={"center"}>
          <Trans i18nKey={"COMMON.MODALS.Create performance tracking"}>Create Performance Tracking</Trans>:{" "}
          {participant.getFullName()}
        </DialogTitle>

        <DialogContent>
          <Divider sx={{ marginBottom: "15px" }} />

          <PerformanceTrackingAnalytics participant={participant} />

          <Divider sx={{ marginTop: "15px" }} />

          <Grid container spacing={3} sx={{ marginTop: "0" }}>
            {/* Date Month>  */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel id={"performance-date-month-select-label"}>Monat</InputLabel>
                <Select
                  labelId={"performance-date-month-select-label"}
                  id={"performance-date-month-select-label"}
                  value={performanceTracking?.dateMonth}
                  label={"Monat"}
                  onChange={event => handleDateMonthOnChange(event)}
                >
                  <MenuItem value={Month.JANUARY}>{t("COMMON.WORDS.January")}</MenuItem>
                  <MenuItem value={Month.FEBRUARY}>{t("COMMON.WORDS.February")}</MenuItem>
                  <MenuItem value={Month.MARCH}>{t("COMMON.WORDS.March")}</MenuItem>
                  <MenuItem value={Month.APRIL}>{t("COMMON.WORDS.April")}</MenuItem>
                  <MenuItem value={Month.MAY}>{t("COMMON.WORDS.May")}</MenuItem>
                  <MenuItem value={Month.JUNE}>{t("COMMON.WORDS.June")}</MenuItem>
                  <MenuItem value={Month.JULY}>{t("COMMON.WORDS.July")}</MenuItem>
                  <MenuItem value={Month.AUGUST}>{t("COMMON.WORDS.August")}</MenuItem>
                  <MenuItem value={Month.SEPTEMBER}>{t("COMMON.WORDS.September")}</MenuItem>
                  <MenuItem value={Month.OCTOBER}>{t("COMMON.WORDS.October")}</MenuItem>
                  <MenuItem value={Month.NOVEMBER}>{t("COMMON.WORDS.November")}</MenuItem>
                  <MenuItem value={Month.DECEMBER}>{t("COMMON.WORDS.December")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Date Year  */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <TextField
                  value={performanceTracking?.dateYear}
                  type={"text"}
                  label={t("COMMON.WORDS.Year")}
                  onChange={event => handleDateYearOnChange(event)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Parameter */}
            {Array.isArray(performanceTracking.performanceTrackingParameters) &&
              performanceTracking.performanceTrackingParameters.map((ptp, index) => (
                <Grid key={ptp.name} item xs={6} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id={`performance-${ptp.name}-select-label`}>{ptp.name}</InputLabel>
                    <Select
                      labelId={`performance-${ptp.name}-select-label`}
                      id={`presence-${ptp.name}-select`}
                      value={ptp.grade}
                      label={ptp.name}
                      onChange={event => handleParameterOnChange(event, index)}
                    >
                      <MenuItem value={Grade.VERY_GOOD}>{t("COMMON.WORDS.Very good")}</MenuItem>
                      <MenuItem value={Grade.GOOD}>{t("COMMON.WORDS.Good")}</MenuItem>
                      <MenuItem value={Grade.SATISFYING}>{t("COMMON.WORDS.Satisfying")}</MenuItem>
                      <MenuItem value={Grade.SUFFICIENT}>{t("COMMON.WORDS.Sufficient")}</MenuItem>
                      <MenuItem value={Grade.DEFICIENT}>{t("COMMON.WORDS.Deficient")}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              ))}

            <Grid item xs={12}>
              <Divider />
            </Grid>

            {/* Comment  */}
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <TextField
                  value={performanceTracking.comment}
                  id={"comment"}
                  label={"Kommentar"}
                  fullWidth={true}
                  variant={"outlined"}
                  multiline={true}
                  minRows={4}
                  required={true}
                  onChange={event => handleCommentOnChange(event)}
                />
              </FormControl>
            </Grid>

            <Grid item width={"100%"}>
              <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}></Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
            <Trans i18nKey={"COMMON.WORDS.Save"} />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
