import React from "react";

// MUI
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { Heading } from "../../components/text/heading";
import { CardParticipantsSummary } from "./cardParticipantsSummary";
import { CardsPresenceSummary } from "./cardsPresenceSummary";
import { CardPresenceMonthReport } from "./cardPresenceMonthReport";
import { CardsCoursesPerformanceReport } from "./cardsCoursesPerformanceReport";

export const Dashboard: React.FC = () => {
  return (
    <AppLayout>
      <Heading text={"Dashboard"} />

      <Grid container rowSpacing={2} columnSpacing={2} alignItems={"stretch"}>
        <CardParticipantsSummary />
        <CardsCoursesPerformanceReport />
        <CardsPresenceSummary />
      </Grid>
      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
      <Grid container rowSpacing={4.5} columnSpacing={2.75} alignItems={"stretch"}>
        <CardPresenceMonthReport />
      </Grid>
    </AppLayout>
  );
};
