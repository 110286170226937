import { TutoringBookRecord, ITutoringBookRecord } from "./tutoringBookRecord";

export interface ITutoringBook {
  id: number;

  course: string;

  entries: Array<TutoringBookRecord>;
}

export class TutoringBook implements ITutoringBook {
  id: number;

  course: string;

  entries: Array<TutoringBookRecord> = [];

  constructor(data: ITutoringBook) {
    this.id = data.id;
    this.course = data.course || "";

    if (Array.isArray(data.entries)) {
      data.entries.map(e => this.entries.push(new TutoringBookRecord(e as ITutoringBookRecord)));
    }
  }
}
