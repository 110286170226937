import dayjs, { Dayjs } from "dayjs";

export interface IHoliday {
  name: string;
  day: Dayjs;
}

export class Holiday implements IHoliday {
  name: string;
  day: Dayjs;

  constructor(data: IHoliday) {
    this.name = data.name;
    this.day = dayjs(data.day);
  }

  public getDateRangeAsString() {
    return `${this.day.format("DD.MM.YYYY")}`;
  }
}
