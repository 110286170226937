import React, { useState } from "react";
import { Trans } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";

// MUI
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

// TS-Hub
import { getPreviousMonth } from "../../helper/date";
import { useSnackbar } from "../../provider/snackbar";
import { Participant } from "../../models/participant";
import { Course } from "../../models/course";

type PresenceTrackingDownloadModalProps = {
  isOpen: boolean;
  onClose: Function;
  participant?: Participant;
  course?: Course;
};

export const PresenceTrackingDownloadModal: React.FC<PresenceTrackingDownloadModalProps> = props => {
  const [period, setPeriod] = useState<Dayjs>(dayjs(getPreviousMonth()));
  const [isLoading, setIsLoading] = useState(false);
  const { isOpen, onClose, participant, course } = props;
  const { toast } = useSnackbar();

  /**
   * Start download.
   *
   */
  async function startDownload() {
    toast({ message: "Starte download ...", level: "info" });

    if (participant) {
      return await participant.downloadPresenceTrackingReport(new Date(String(period)));
    } else if (course) {
      return await course.downloadPresenceTrackingReports(new Date(String(period)));
    }
  }

  /**
   * Handles the onDownload event of the modal.
   *
   */
  async function handleOnDownload() {
    setIsLoading(true);
    await startDownload();
    setIsLoading(false);
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={() => onClose()} maxWidth={"xs"} fullWidth={true}>
      <DialogTitle>Anwesenheitsbericht(e) erstellen</DialogTitle>
      <DialogContent>
        <Grid container spacing={3} sx={{ marginTop: "0" }}>
          <Grid item xs={12} width={"full"}>
            <DatePicker
              onChange={value => setPeriod(value!)}
              value={period}
              label={"Zeitraum"}
              sx={{ width: "100%" }}
              views={["month", "year"]}
            />
          </Grid>
          <Grid item width={"100%"}>
            <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading} onClick={handleOnDownload}>
          <Trans i18nKey={"COMMON.BUTTONS.Download report"} />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
