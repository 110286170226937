export enum ActivityContentType {
  PRESENCE_TRACKING = "presencetracking"
}

export interface IActivity {
  creator: string;
  contentType: ActivityContentType;
  createdAt: Date;
  changes: Record<string, Array<string>>;
}

export class Activity {
  creator: string;
  contentType: ActivityContentType;
  createdAt: Date;
  changes: Record<string, Array<string>>;

  constructor(data: IActivity) {
    this.creator = data.creator;
    this.contentType = data.contentType;
    this.createdAt = new Date(data.createdAt);
    this.changes = data.changes;
  }

  public displayChanges() {
    let response = "";

    for (const key in this.changes) {
      response += `${key}: ${this.changes[key][0]} --> ${this.changes[key][1]}\n`;
    }

    return response;
  }

  public getContentType() {
    switch (this.contentType) {
      case ActivityContentType.PRESENCE_TRACKING:
        return "Anwesenheitserfassung";
      default:
        return "--- UNBEKANNT ---";
    }
  }
}
