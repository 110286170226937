import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

// MUI
import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Stack from "@mui/material/Stack";

// TS-Hub
import { Course } from "../../../../models/course";
import { MainCard } from "../../../../components/cards";
import { Participant } from "../../../../models/participant";
import { LoadingScreen } from "../../../../components/screens/loadingScreen";
import { SimpleTableCell } from "../../../../components/tables/simpleTable";
import { CourseParticipantsPerformanceListRow } from "./courseParticipantsPerformanceListRow";
import { useSnackbar } from "../../../../provider/snackbar";

type CourseParticipantsPerformanceListProps = {
  course?: Course;
};

export const CourseParticipantsPerformanceList: React.FC<CourseParticipantsPerformanceListProps> = props => {
  const { course } = props;
  const { toast } = useSnackbar();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      course?.participants.forEach(async p => await p.getPerformanceTracking());
      setTimeout(() => setIsLoaded(true), 3_000);
    }
  }, [course?.participants]);

  async function downloadPerformanceQuarterReports(quarter: number) {
    if (course) {
      setIsLoading(true);
      toast({ message: "Bereite Download vor ...", level: "info" });
      await course.downloadPerformanceQuarterReports(quarter);
      setIsLoading(false);
      toast({ message: "Download abgeschlossen.", level: "success" });
    }
  }

  if (!isLoaded) {
    return <LoadingScreen />;
  }

  return (
    <React.Fragment>
      <Backdrop open={isLoading}>
        <CircularProgress />
      </Backdrop>

      <MainCard title={"Leistungsbewertung"}>
        <Stack sx={{ float: "right" }} direction={"row"} spacing={2} mb={1}>
          <Button onClick={() => downloadPerformanceQuarterReports(1)} disabled={isLoading} variant={"outlined"}>
            Berichte Q1
          </Button>
          <Button onClick={() => downloadPerformanceQuarterReports(2)} disabled={isLoading} variant={"outlined"}>
            Berichte Q2
          </Button>
          <Button onClick={() => downloadPerformanceQuarterReports(3)} disabled={isLoading} variant={"outlined"}>
            Berichte Q3
          </Button>
          <Button onClick={() => downloadPerformanceQuarterReports(4)} disabled={isLoading} variant={"outlined"}>
            Berichte Q4
          </Button>
        </Stack>

        <TableContainer component={Box}>
          <Table size={"small"} aria-label={"CourseBook table"} border={0}>
            <TableHead>
              <TableRow>
                <SimpleTableCell style={{ minWidth: "200px" }}>
                  <Trans i18nKey={"COMMON.WORDS.Name"} />
                </SimpleTableCell>

                {course?.getListOfMonths().map((d, index) => (
                  <SimpleTableCell key={index} align={"left"}>
                    {d.format("MM.YYYY")}
                  </SimpleTableCell>
                ))}

                <SimpleTableCell align={"right"}></SimpleTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {course?.participants.map((p: Participant) => (
                <CourseParticipantsPerformanceListRow key={p.id} participant={p} course={course} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>
    </React.Fragment>
  );
};
