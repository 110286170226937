import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

// MUI
import { Box, Button, Divider, Stack } from "@mui/material";

// TS-Hub
import { MainCard } from "../../../../components/cards";
import { Course } from "../../../../models/course";
import { useKeyPress } from "../../../../provider/keyPress";
import { HomeworkTrackingModal } from "../../../../components/homeworkTracking/homeworkTrackingModal";
import { PresenceTrackingModal } from "../../../../components/presenceTracking/presenceTrackingModal";
import { PresenceTrackingDownloadModal } from "../../../../components/presenceTracking/presenceTrackingDownloadModal";

type CourseActionsProps = {
  course: Course;
};

export const CourseActions: React.FC<CourseActionsProps> = props => {
  const { course } = props;
  const [isPresenceTrackingModalOpen, setIsPresenceTrackingModalOpen] = useState(false);
  const [isPresenceTrackingDownloadModalOpen, setIsPresenceTrackingDownloadModalOpen] = useState(false);
  const [isHomeworkTrackingModalOpen, setIsHomeworkTrackingModalOpen] = useState(false);
  const { t } = useTranslation();

  useKeyPress({ keys: ["k"], callback: togglePresenceTrackingModal });

  /**
   * Toggles the presence tracking modal.
   *
   * @param event
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function togglePresenceTrackingModal(event: KeyboardEvent) {
    setIsPresenceTrackingModalOpen(!isPresenceTrackingModalOpen);
  }

  return (
    <MainCard title={t("COMMON.WORDS.Course Actions")}>
      <Box sx={{ width: "100%" }}>
        <Stack spacing={2} flexWrap={"wrap"}>
          <Button variant={"contained"} onClick={() => setIsPresenceTrackingModalOpen(true)} sx={{ flexGrow: 1 }}>
            <Trans i18nKey={"COMMON.BUTTONS.Track presence"} />
          </Button>
          <Button variant={"contained"} onClick={() => setIsHomeworkTrackingModalOpen(true)} sx={{ flexGrow: 1 }}>
            <Trans i18nKey={"COMMON.BUTTONS.Track homework"} />
          </Button>

          <Divider />

          <Button variant={"outlined"} onClick={() => setIsPresenceTrackingDownloadModalOpen(true)}>
            <Trans i18nKey={"COMMON.BUTTONS.Request all participant reports"} />
          </Button>
        </Stack>
      </Box>

      <HomeworkTrackingModal
        isOpen={isHomeworkTrackingModalOpen}
        onClose={() => setIsHomeworkTrackingModalOpen(false)}
        course={course}
      />

      <PresenceTrackingModal
        isOpen={isPresenceTrackingModalOpen}
        onClose={() => setIsPresenceTrackingModalOpen(false)}
        course={course}
      />

      <PresenceTrackingDownloadModal
        isOpen={isPresenceTrackingDownloadModalOpen}
        onClose={() => setIsPresenceTrackingDownloadModalOpen(false)}
        course={course}
      />
    </MainCard>
  );
};
