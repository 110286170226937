import { Grade, Month } from "../constants";
import { IPerformanceTrackingParameter } from "./performanceTrackingParameter";

export interface IPerformanceGraphParams {
  dateMonth: number;
  dateYear: number;

  [key: string | number]: Grade;
}

export interface IPerformanceTracking {
  id?: number;
  participant: number;
  result: number;
  dateMonth: Month;
  dateYear: number;
  performanceTrackingParameters: Array<IPerformanceTrackingParameter>;
  comment: string;

  getTrack(): String;
}

export interface IPerformanceTrackingCreate {
  participant: number;
  dateMonth: Month;
  dateYear: number;
  performanceTrackingParameters: Array<IPerformanceTrackingParameter>;
  comment: string;
}

export class PerformanceTracking implements IPerformanceTracking {
  id?: number;
  participant: number;
  result: number;
  dateMonth: Month;
  dateYear: number;
  performanceTrackingParameters: Array<IPerformanceTrackingParameter>;
  comment: string;

  constructor(data: IPerformanceTracking) {
    this.id = data.id;
    this.participant = data.participant;
    this.result = Number(data.result);
    this.dateMonth = data.dateMonth;
    this.dateYear = data.dateYear;
    this.performanceTrackingParameters = data.performanceTrackingParameters;
    this.comment = data.comment;
  }

  public getTrack(): String {
    if (this.result <= 2.4) {
      return "A";
    } else if (this.result >= 2.5 && this.result <= 3.9) {
      return "B";
    } else {
      return "C";
    }
  }
}
