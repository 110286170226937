export interface ITopic {
  id: number;

  title: string;

  description: string;

  teachingUnitsTotal: number;

  notionLink: string;
}

export class Topic implements ITopic {
  id: number;

  title: string;

  description: string;

  teachingUnitsTotal: number;

  notionLink: string;

  constructor(data: ITopic) {
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.teachingUnitsTotal = data.teachingUnitsTotal;
    this.notionLink = data.notionLink;
  }
}
