import React from "react";
import { CenteredBox } from "../../components/box/centeredBox";
import { AppLayout } from "../../layouts/appLayout";
import Stack from "@mui/material/Stack";

export const NotAllowed: React.FC = () => {
  return (
    <AppLayout>
      <CenteredBox>
        <Stack>
          <CenteredBox>
            <h1>403 - You shall not pass!</h1>
          </CenteredBox>
          <iframe
            width="964px"
            height="400px"
            src="https://www.youtube-nocookie.com/embed/mJZZNHekEQw?si=X1zTDZlwp60I4Atl&autoplay=1&controls=0&fs=0&showinfo=0&start=45"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen={false}
          ></iframe>
        </Stack>
      </CenteredBox>
    </AppLayout>
  );
};
