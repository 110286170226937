import dayjs, { Dayjs } from "dayjs";
import { IUser, User } from "./user";

export interface ITutoringBookRecord {
  id?: number;
  tutoringBook: number;
  entry: string;
  teachingDay: Dayjs | null;
  teacher?: User;
}

export class TutoringBookRecord implements ITutoringBookRecord {
  id?: number;
  tutoringBook: number;
  entry: string;
  teachingDay: Dayjs;
  teacher: User;

  constructor(data: ITutoringBookRecord) {
    this.id = data.id;
    this.tutoringBook = data.tutoringBook;
    this.entry = data.entry || "";
    this.teachingDay = dayjs(data.teachingDay || "");
    this.teacher = new User(data.teacher as IUser);
  }
}
