import React, { createContext, useState } from "react";

import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert";

type ToastType = {
  message: string;
  level: AlertColor;
  autoHideDuration?: number | null;
};

interface ISnackbarContextStates {
  toast: ({ message, level, autoHideDuration }: ToastType) => void;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

/**
 * Create the context.
 *
 */
const SnackbarContext = createContext<ISnackbarContextStates>({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  toast: ({ message, level, autoHideDuration }: ToastType) => {}
});

export const SnackbarProvider: React.FC<React.PropsWithChildren> = props => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>("DEFAULT");
  const [level, setLevel] = useState<AlertColor>("success");
  const [autoHideDuration, setAutoHideDuration] = useState<number>(3_000);

  /**
   * Handles the onClose event on the Snackbar.
   *
   * @param event
   * @param reason
   */
  function handleOnClose(event?: React.SyntheticEvent | Event, reason?: string) {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  }

  /**
   * This does the actual trick! Just call toast({message: "Jo", level: ""}) and enjoy!
   *
   * @param message
   * @param level
   * @param autoHideDuration
   */
  function toast({ message, level, autoHideDuration }: ToastType): void {
    setMessage(message);
    setLevel(level);
    setAutoHideDuration(autoHideDuration || 3_000);
    setOpen(true);
  }

  return (
    <SnackbarContext.Provider value={{ toast }}>
      <Snackbar
        open={open}
        autoHideDuration={autoHideDuration}
        onClose={handleOnClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleOnClose} variant={"standard"} severity={level} sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>

      {props.children}
    </SnackbarContext.Provider>
  );
};

export function useSnackbar(): ISnackbarContextStates {
  const context = React.useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error("useSnackbar must be used within a SnackbarContext.");
  }

  return context;
}
