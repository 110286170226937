import * as React from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";

import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

interface AppBarProps extends MuiAppBarProps {
  isOpen?: boolean;
  handleDrawerOpen?: Function;
}

const drawerWidth = 240;

const StyledAppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== "isOpen"
})<AppBarProps>(({ theme, isOpen }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(isOpen && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}));

export const AppBar: React.FC<AppBarProps> = ({ isOpen, handleDrawerOpen }) => {
  const navigate = useNavigate();

  return (
    <StyledAppBar position={"fixed"} isOpen={isOpen}>
      <Toolbar>
        <IconButton
          color={"inherit"}
          aria-label={"open drawer"}
          onClick={() => handleDrawerOpen!()}
          edge={"start"}
          sx={{
            marginRight: 5,
            ...(isOpen && { display: "none" })
          }}
        >
          <MenuIcon />
        </IconButton>
        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} spacing={2} width={"100%"}>
          <div></div>
          <img
            onClick={() => navigate("/")}
            src={process.env.PUBLIC_URL + "/img/logos/ts_white.svg"}
            alt={"Logo"}
            height={"55px"}
            style={{ cursor: "pointer", padding: "5px 0px" }}
          />
        </Stack>
      </Toolbar>
    </StyledAppBar>
  );
};
