import React from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { DataGrid, gridClasses, GridColDef } from "@mui/x-data-grid";

import { TutoringBookRecord } from "../../../models/tutoringBookRecord";

type CourseBookRecordsTableProps = {
  entries: Array<TutoringBookRecord>;
  pageSize: number;
  toolbar?: React.ReactNode | React.FC;
};

export const TutoringBookRecordsTable: React.FC<CourseBookRecordsTableProps> = props => {
  const { t } = useTranslation();
  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 30 },
    {
      field: "teachingDay",
      headerName: t("COMMON.WORDS.TeachingDay") || "",
      width: 200,
      valueGetter: params => dayjs(params.row.teachingDay).format("DD.MM.YYYY, dddd"),
      sortable: false
    },
    {
      field: "teacher",
      headerName: t("COMMON.WORDS.Teacher") || "",
      width: 200,
      sortable: false,
      valueGetter: params => `${params.row.teacher.firstName} ${params.row.teacher.lastName}`
    },
    { field: "entry", headerName: t("COMMON.WORDS.Entry") || "", minWidth: 200, flex: 1, sortable: false }
  ];

  return (
    <DataGrid
      rows={props.entries}
      columns={columns}
      pageSizeOptions={[5, 10, 20]}
      checkboxSelection={false}
      autoHeight={true}
      components={{ Toolbar: props.toolbar as unknown as React.JSXElementConstructor<unknown> }}
      sx={{
        [`& .${gridClasses.cell}`]: {
          py: 1
        },
        border: "none"
      }}
      initialState={{ columns: { columnVisibilityModel: { id: false } } }}
      getRowHeight={() => "auto"}
    />
  );
};
