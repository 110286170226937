import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// MUI
import { Box, Grid, Tab, Tabs } from "@mui/material";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { ApiClassesService } from "../../services/apiClassesService";
import { Course } from "../../models/course";
import { TabPanel } from "../../components/tab";
import { LoadingScreen } from "../../components/screens/loadingScreen";
import { useSnackbar } from "../../provider/snackbar";
import { Heading } from "../../components/text/heading";
import { MainCard } from "../../components/cards";
import { ModuleTopicsTable } from "../../components/module/moduleTopicsTable";

import { CourseData } from "./components/tab-general/courseData";
import { ParticipantsList } from "./components/tab-participants/participantsList";
import { CourseBookDetail } from "./components/tab-coursebook/courseBookDetail";
import { CourseActions } from "./components/tab-general/courseActions";
import { CourseProgress } from "./components/tab-general/courseProgress";
import { CoursePerformanceReport } from "./components/tab-statistics/coursePerformanceReport";
import { CourseTimePlan } from "./components/tab-timeline/courseTimePlan";
import { CourseTeam } from "./components/tab-general/courseTeam";
import { CourseParticipantsPerformanceList } from "./components/tab-performance/courseParticipantsPerformanceList";
import { PresenceTrackingTable } from "../../components/presenceTracking/presenceTrackingTable";

export const CoursesDetail: React.FC = () => {
  const { courseId } = useParams<"courseId">();
  const [course, setCourse] = useState<Course>();
  const [tabPanel, setTabPanel] = useState(0);
  const navigate = useNavigate();
  const { toast } = useSnackbar();
  const { t } = useTranslation();

  /**
   * useEffect runs after the first render.
   *
   */
  useEffect(() => {
    if (location.href.includes("#allgemeines")) setTabPanel(0);
    if (location.href.includes("#kursmanager")) setTabPanel(1);
    if (location.href.includes("#statistiken")) setTabPanel(2);
    if (location.href.includes("#kursbuch")) setTabPanel(4);
    if (location.href.includes("#teilnehmende")) setTabPanel(5);
    if (location.href.includes("#leistungsbewertung")) setTabPanel(6);
    if (location.href.includes("#zeitplan")) setTabPanel(7);
    if (location.href.includes("#lehrplan")) setTabPanel(8);

    if (courseId) {
      ApiClassesService.fetchCourse(courseId).then(res => {
        if (res.status === 200) {
          setCourse(new Course(res.data));
        } else if (res.status === 403) {
          navigate("/not-allowed");
        } else {
          toast({ level: "error", message: t("COMMON.SNACKBAR.GENERIC_ERROR_MESSAGE") });
        }
      });
    }
  }, [courseId]);

  /**
   * Handles the onChange event on the tab to set the tab panel.
   *
   * @param event
   * @param newValue
   */
  function handleTabsOnChange(event: React.SyntheticEvent, newValue: number) {
    setTabPanel(newValue);
  }

  if (!course) {
    return <LoadingScreen />;
  }

  return (
    <AppLayout>
      <Heading text={`Kurs: ${course.title}`} />

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabPanel} onChange={handleTabsOnChange}>
            <Tab component="a" aria-current={"page"} label={"Allgemeines"} href={"#allgemeines"} />
            <Tab component="a" aria-current={"page"} label={"Kursmanager"} href={"#kursmanager"} />
            <Tab component="a" aria-current={"page"} label={"Statistiken"} href={"#statistiken"} />
            <Tab component="a" aria-current={"page"} label={"Kursbuch"} href={"#kursbuch"} />
            <Tab component="a" aria-current={"page"} label={"Nachhilfebuch"} disabled={true} />
            <Tab component="a" aria-current={"page"} label={"Teilnehmende"} href={"#teilnehmende"} />
            <Tab component="a" aria-current={"page"} label={"Leistungsbewertung"} href={"#leistungsbewertung"} />
            <Tab component="a" aria-current={"page"} label={"Zeitplan"} href={"#zeitplan"} />
            <Tab component="a" aria-current={"page"} label={"Lehrplan"} href={"#lehrplan"} />
          </Tabs>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <TabPanel value={tabPanel} index={0}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CourseProgress course={course} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CourseData course={course} setCourse={setCourse} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CourseActions course={course} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={6}>
              <CourseTeam course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={1}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PresenceTrackingTable course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={2}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CoursePerformanceReport course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={3}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CourseBookDetail course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={4}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CourseBookDetail course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={5}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={6} md={12} lg={12}>
              <ParticipantsList course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={6}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={6} md={12} lg={12}>
              <CourseParticipantsPerformanceList course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={7}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <CourseTimePlan course={course} />
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabPanel} index={8}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <MainCard title={"Lehrplan"}>
                <ModuleTopicsTable programId={course.program} />
              </MainCard>
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </AppLayout>
  );
};
