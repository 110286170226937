import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { CredentialResponse, GoogleLogin } from "@react-oauth/google";

// MUI
import { TextField } from "@mui/material";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

// TS-Hub
import { CenteredLayout } from "../../layouts/centeredLayout";
import { useAuth } from "../../provider/authentication";
import { useTheming } from "../../provider/theming";
import { useSnackbar } from "../../provider/snackbar";

type LoginData = {
  email: string;
  password: string;
};

export const Login: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { register, handleSubmit } = useForm<LoginData>();
  const { preferDarkMode } = useTheming();
  const { doLogin } = useAuth();
  const { toast } = useSnackbar();
  const imgPath = preferDarkMode
    ? process.env.PUBLIC_URL + "/img/logos/ts_white.svg"
    : process.env.PUBLIC_URL + "/img/logos/ts_black.svg";

  /**
   * Handler for onSubmit event.
   *
   * @param data
   */
  async function handleOnSubmit(data: LoginData) {
    setIsLoading(true);
    const loginSucceed = await doLogin(data.email, data.password);
    if (loginSucceed) {
      toast({ message: "Login erfolgreich!", level: "success" });
    } else {
      toast({ message: "E-Mail oder Passwort ist leider falsch!", level: "warning" });
      setIsLoading(false);
    }
  }

  // eslint-disable-next-line
  function onGoogleLoginSuccess(response: CredentialResponse) {
    alert(
      "Die Funktion ist noch nicht freigeschaltet. " +
        "Nur noch ein paar Tage, dann kannst du dich auch über dein Google Account anmelden. :)"
    );
  }

  return (
    <CenteredLayout>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Stack direction={"column"} spacing={2} width={"300px"}>
          <img src={imgPath} alt={"Logo"} style={{ marginBottom: "15px" }} />
          <TextField required variant={"outlined"} label={"E-Mail"} fullWidth={true} {...register("email")} />
          <TextField
            required
            variant={"outlined"}
            type={"password"}
            label={"Password"}
            fullWidth={true}
            {...register("password")}
          />
          <Stack direction={"row"} spacing={2}>
            <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
              Login
            </LoadingButton>
            <GoogleLogin type={"icon"} size={"large"} width={"100%"} onSuccess={onGoogleLoginSuccess} onError={() => {}} />
          </Stack>
        </Stack>
      </form>
      <br />
      <br />
    </CenteredLayout>
  );
};
