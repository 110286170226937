import React from "react";

// MUI
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

// TS-Hub
import { AnalyticCard } from "../../../components/cards";
import { Participant } from "../../../models/participant";

interface ParticipantStatisticsProps {
  participant: Participant;
}

export const ParticipantStatistics: React.FC<ParticipantStatisticsProps> = props => {
  const { participant } = props;

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Anwesenheit"}
            count={`${participant.statistics.presenceAvg.participant}%`}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Anwesenheit
                </Typography>{" "}
                im Durchschnitt im gesamten Zeitraum. Damit ist der Teilnehmende{" "}
                {participant.statistics.presenceAvg.participant > participant.statistics.presenceAvg.course ? (
                  <span style={{ color: "green" }}>über</span>
                ) : (
                  <span style={{ color: "red" }}>unter</span>
                )}{" "}
                dem Kurs-Durchschnitt von {participant.statistics.presenceAvg.course}%.
              </>
            }
            percentage={Math.abs(
              participant.statistics.presenceAvg.course - participant.statistics.presenceAvg.participant
            ).toFixed(2)}
            percentageDescription={"Im direkten Vergleich zum Kurzdurschnitt"}
            isLoss={participant.statistics.presenceAvg.participant < participant.statistics.presenceAvg.course}
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Abwesenheit"}
            count={`${participant.statistics.absenceAvg.participant}%`}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  unentschuldigte Abwesenheit
                </Typography>{" "}
                im Durchschnitt über den gesamten Zeitraum. Damit ist der Teilnehmende{" "}
                {participant.statistics.absenceAvg.participant < participant.statistics.absenceAvg.course ? (
                  <span style={{ color: "green" }}>über</span>
                ) : (
                  <span style={{ color: "red" }}>unter</span>
                )}{" "}
                dem Kurs-Durchschnitt von {participant.statistics.absenceAvg.course}%.
              </>
            }
            percentage={Math.abs(
              participant.statistics.absenceAvg.course - participant.statistics.absenceAvg.participant
            ).toFixed(2)}
            percentageDescription={"Im direkten Vergleich zum Kurzdurschnitt"}
            isLoss={participant.statistics.absenceAvg.participant > participant.statistics.absenceAvg.course}
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Hausaufgaben"}
            count={`${participant.statistics.homeworkAvg.participant}%`}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  erledigt Hausaufgaben
                </Typography>{" "}
                im Durchschnitt über den gesamten Zeitraum. Damit ist der Teilnehmende{" "}
                {participant.statistics.homeworkAvg.participant > participant.statistics.homeworkAvg.course ? (
                  <span style={{ color: "green" }}>über</span>
                ) : (
                  <span style={{ color: "red" }}>unter</span>
                )}{" "}
                dem Kurs-Durchschnitt von {participant.statistics.homeworkAvg.course}%.
              </>
            }
            percentage={Math.abs(
              participant.statistics.homeworkAvg.course - participant.statistics.homeworkAvg.participant
            ).toFixed(2)}
            percentageDescription={"Im direkten Vergleich zum Kurzdurschnitt"}
            isLoss={participant.statistics.homeworkAvg.participant < participant.statistics.homeworkAvg.course}
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Note"}
            count={participant.statistics.gradeAvg.participant}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Gesamtnote
                </Typography>{" "}
                im Durchschnitt über den gesamten Zeitraum. Damit ist der Teilnehmende{" "}
                {participant.statistics.gradeAvg.participant < participant.statistics.gradeAvg.course ? (
                  <span style={{ color: "green" }}>über</span>
                ) : (
                  <span style={{ color: "red" }}>unter</span>
                )}{" "}
                dem Kurs-Durchschnitt von {participant.statistics.gradeAvg.course}.
              </>
            }
            isLoss={participant.statistics.gradeAvg.participant < participant.statistics.gradeAvg.course}
            variant={"filled"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
