import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

// MUI
import { Box, Grid, Tab, Tabs } from "@mui/material";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { Participant } from "../../models/participant";
import { ApiParticipantService } from "../../services/apiParticipantService";
import { useSnackbar } from "../../provider/snackbar";
import { useNavigate, useParams } from "react-router-dom";
import { MainCard } from "../../components/cards";
import { TabPanel } from "../../components/tab";
import { ParticipantForm } from "./forms/participantForm";
import { PerformanceTrackingCard } from "./components/performanceTrackingCard";
import { ParticipantActionsCard } from "./components/participantActionsCard";
import { ParticipantStatistics } from "./components/participantStatistics";
import { PerformanceCommentsTimeline } from "./components/performanceCommentsTimeline";
import { ActivitiesTimeline } from "./components/activitiesTimeline";
import { FeatureNotPublished } from "../../components/notifier/featureNotPublished";
import { Heading } from "../../components/text/heading";
import { Course } from "../../models/course";
import { ApiClassesService } from "../../services/apiClassesService";
import { CourseCard } from "./components/courseCard";

export const ParticipantsDetail: React.FC = () => {
  const [participant, setParticipant] = useState<Participant>();
  const [course, setCourse] = useState<Course>();
  const [tabPanel, setTabPanel] = useState(0);
  const { participantId } = useParams<"participantId">();
  const { toast } = useSnackbar();
  const navigate = useNavigate();
  const { register, control } = useForm<Participant>({
    values: participant
  });

  useEffect(() => {
    ApiParticipantService.fetchParticipant(Number(participantId))
      .then(res => {
        if (res.status === 200) {
          const p = new Participant(res.data);
          setParticipant(p);

          ApiClassesService.fetchCourse(p.course).then(res => {
            if (res.status === 200) {
              setCourse(new Course(res.data));
            }
          });
        } else {
          handleUserNotFound();
        }
      })
      .catch(() => handleUserNotFound());
  }, []);

  /**
   * Handler for the case that a user cannot be found on the backend.
   *
   */
  function handleUserNotFound() {
    toast({ message: `Participant with ID ${participantId} with not found!`, level: "error" });
    navigate("/participants");
  }

  /**
   * Handles the onChange event on the tab to set the tab panel.
   *
   * @param event
   * @param newValue
   */
  function handleTabsOnChange(event: React.SyntheticEvent, newValue: number) {
    setTabPanel(newValue);
  }

  if (!participant) {
    return null;
  }

  return (
    <AppLayout>
      <Heading text={`Teilnehmender: ${participant.firstName} ${participant.lastName}`} />

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabPanel} onChange={handleTabsOnChange}>
            <Tab label="Allgemeines" />
            <Tab label="Performance" />
            <Tab label="Kommentare" />
            <Tab label="Aktivitäten" />
          </Tabs>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <TabPanel value={tabPanel} index={0}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <MainCard title={"Personal Information"}>
                <ParticipantForm control={control} register={register} participant={participant} />
              </MainCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <ParticipantActionsCard participant={participant} />
            </Grid>
            <Grid item xs={12} md={12}>
              {course && <CourseCard course={course} />}
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <TabPanel value={tabPanel} index={1}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <FeatureNotPublished />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ParticipantStatistics participant={participant} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PerformanceTrackingCard participant={participant} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <TabPanel value={tabPanel} index={2}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <PerformanceCommentsTimeline participant={participant} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <TabPanel value={tabPanel} index={3}>
          <Grid container rowSpacing={4.5} columnSpacing={2.75}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <ActivitiesTimeline participant={participant} />
            </Grid>
          </Grid>
        </TabPanel>
      </Grid>
    </AppLayout>
  );
};
