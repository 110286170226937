import React, { useEffect, useState } from "react";

// MUI
import { Grid, Typography } from "@mui/material";

import { AnalyticCard } from "../../components/cards";
import { ICoursesPerformanceReport } from "../../models/course";
import { ApiClassesService } from "../../services/apiClassesService";

export const CardsCoursesPerformanceReport: React.FC = () => {
  const [report, setReport] = useState<ICoursesPerformanceReport>();

  useEffect(() => {
    ApiClassesService.fetchCoursesPerformanceReport().then(res => setReport(res.data));
  }, []);

  if (!report) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"Dropouts"}
          count={report.dropouts}
          extra={
            <>
              Anzahl der Teilnehmenden, die{" "}
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                abgrebochen haben oder nicht angetreten sind.
              </Typography>
            </>
          }
          variant={"filled"}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <AnalyticCard
          title={"Dropouts"}
          count={`${report.dropoutRate}%`}
          extra={
            <>
              Anzahl der Teilnehmenden in Prozent, die{" "}
              <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                abgrebochen haben oder nicht angetreten sind.
              </Typography>
            </>
          }
          variant={"filled"}
        />
      </Grid>
    </React.Fragment>
  );
};
