import React from "react";
import Alert from "@mui/material/Alert";

interface TrackingAlertProps {
  result?: number | null;
}

export const TrackingAlert: React.FC<TrackingAlertProps> = props => {
  const { result } = props;

  if (!result) {
    return null;
  }

  if (result <= 1.49) {
    return (
      <Alert severity={"success"}>
        <strong>Track A</strong>
        <p>Sehr gute Mitarbeit. Freiwillige Teilnahme an Nachhilfeangebot.</p>
      </Alert>
    );
  }

  if (result > 1.49 && result <= 2.49) {
    return (
      <Alert severity={"success"}>
        <strong>Track B</strong>
        <p>Gute Mitarbeit. Freiwillige Teilnahme an Nachhilfeangebot.</p>
      </Alert>
    );
  }

  if (result > 2.49 && result <= 3.49) {
    return (
      <Alert severity={"warning"}>
        <strong>Track C</strong>
        <p>Befriedigende Mitarbeit. Teilnahme an Nachhilfeangebot empfohlen (1x pro Woche).</p>
      </Alert>
    );
  }

  if (result > 3.49 && result <= 4.49) {
    return (
      <Alert severity={"warning"}>
        <strong>Track D</strong>
        <p>Ausreichende Mitarbeit. Teilnahme an Nachhilfeangebot verpflichtend (1x pro Woche).</p>
      </Alert>
    );
  }

  if (result > 4.49) {
    return (
      <Alert severity={"error"}>
        <strong>Track E</strong>
        <p>
          Mangelhafte Mitarbeit. Teilnahme an Nachhilfeangebot verpflichtend (2x pro Woche in Nachhilfegruppe, 1x pro Woche
          Einzelnachhilfe).
        </p>
      </Alert>
    );
  }

  return null;
};
