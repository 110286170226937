import dayjs, { Dayjs } from "dayjs";

export interface IVacation {
  name: string;
  dateFrom: Dayjs;
  dateUntil: Dayjs;
}

export class Vacation implements IVacation {
  name: string;
  dateFrom: Dayjs;
  dateUntil: Dayjs;

  constructor(data: IVacation) {
    this.name = data.name;
    this.dateFrom = dayjs(data.dateFrom);
    this.dateUntil = dayjs(data.dateUntil);
  }

  public getDateRangeAsString() {
    return `${this.dateFrom.format("DD.MM.YYYY")} - ${this.dateUntil.format("DD.MM.YYYY")}`;
  }
}
