import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

// MUI
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import { Button } from "@mui/material";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { getInfinitePaginatedData } from "../../helper/http";
import { IParticipant, Participant } from "../../models/participant";
import { ApiParticipantService } from "../../services/apiParticipantService";
import { MainCard } from "../../components/cards";
import { Heading } from "../../components/text/heading";

export const ParticipantsList: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [participants, setParticipants] = useState<Array<Participant>>([]);
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    { field: "tsId", headerName: "TS ID", width: 120 },
    { field: "firstName", headerName: "Vorname", width: 200 },
    { field: "lastName", headerName: "Nachname", width: 200 },
    { field: "courseName", headerName: "Kurs", width: 100 },
    { field: "hubspotLeadStatus", headerName: "Status", width: 200 },
    {
      field: "city",
      headerName: "Stadt",
      width: 200,
      type: "string"
    },
    {
      field: "__",
      headerName: "",
      width: 300,
      sortable: false,
      disableColumnMenu: true,
      renderCell: params => {
        return (
          <div>
            <Button
              onClick={() => navigate(`/teilnehmer/${params.row.id}`)}
              variant={"contained"}
              style={{ marginRight: "5px" }}
            >
              Zu den Details
            </Button>
            <Button
              href={`https://app-eu1.hubspot.com/contacts/25040982/contact/${params.row.hubspotId}`}
              target={"_blank"}
              variant={"outlined"}
            >
              Zu HubSpot
            </Button>
          </div>
        );
      }
    }
  ];

  const columnGroups = [
    {
      groupId: "Intern",
      children: [{ field: "clientNumber" }, { field: "email" }]
    },
    {
      groupId: "Person",
      children: [{ field: "lastName" }, { field: "firstName" }, { field: "dateOfBirth" }]
    },
    {
      groupId: "Adresse",
      children: [{ field: "postalCode" }, { field: "city" }]
    },
    {
      groupId: "Kurs",
      children: [{ field: "courseName" }, { field: "hubspotDocumentNumber" }, { field: "hubspotLeadStatus" }]
    }
  ];

  useEffect(() => {
    getInfinitePaginatedData<IParticipant>(ApiParticipantService.fetchAllParticipants).then(res => {
      setParticipants(res.data.map(p => new Participant(p)));
      setIsLoading(false);
    });
  }, []);

  return (
    <AppLayout>
      <Heading text={<Trans i18nKey={"COMMON.WORDS.Participants"} />} />

      <MainCard sx={{ height: "auto" }}>
        <DataGrid
          rows={participants}
          columns={columns}
          pageSizeOptions={[5, 10, 20, 100]}
          initialState={{
            pagination: { paginationModel: { pageSize: 10 } }
          }}
          checkboxSelection={false}
          autoHeight={true}
          loading={isLoading}
          slots={{ toolbar: GridToolbar }}
          experimentalFeatures={{ columnGrouping: true }}
          columnGroupingModel={columnGroups}
          style={{ border: "none" }}
        />
      </MainCard>
    </AppLayout>
  );
};
