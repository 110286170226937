import { Module, IModule } from "./module";

export interface IProgram {
  id: number;

  name: string;

  officialNumber: string;

  modules: Array<Module>;
}

export class Program implements IProgram {
  id: number;

  name: string;

  officialNumber: string;

  modules: Array<Module> = [];

  constructor(data: IProgram) {
    this.id = Number(data.id);
    this.name = String(data.name);
    this.officialNumber = String(data.officialNumber);

    data.modules.map(d => this.modules.push(new Module(d as IModule)));
  }
}
