/**
 * Simple function to sum members of an array.
 *
 * @param list
 */
export function getSum(list: Array<number>): number {
  let sum = 0;
  list.forEach(count => (sum += count));
  return sum;
}
