import React from "react";
import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";

import { ProtectedRoute } from "./components/router";

import { CoursesDetail } from "./pages/courses-detail";
import { CoursesList } from "./pages/courses-list";
import { Dashboard } from "./pages/dashboard";
import { Login } from "./pages/login";
import { NotAllowed } from "./pages/403";
import { ParticipantsDetail } from "./pages/participants-detail";
import { ParticipantsList } from "./pages/participants-list";
import { Profile } from "./pages/profile";
import { ProgramDetail } from "./pages/programs-detail";
import { ProgramList } from "./pages/programs-list";
import { TutoringBookDetail } from "./pages/tutoring-books-detail";
import { TodosList } from "./pages/todos-list";

/**
 * Router for react-router-dom.
 *
 */
const r = createBrowserRouter(
  createRoutesFromElements(
    <Route path={"/"}>
      <Route
        index
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route path={"login"} element={<Login />} />

      <Route path={"kurse"}>
        <Route
          path={":courseId"}
          element={
            <ProtectedRoute>
              <CoursesDetail />
            </ProtectedRoute>
          }
        />
        <Route
          index
          element={
            <ProtectedRoute>
              <CoursesList />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path={"teilnehmer"}>
        <Route
          path={":participantId"}
          element={
            <ProtectedRoute>
              <ParticipantsDetail />
            </ProtectedRoute>
          }
        />
        <Route
          index
          element={
            <ProtectedRoute>
              <ParticipantsList />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path={"profile"}
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />

      <Route path={"maßnahmen"}>
        <Route
          path={":programId"}
          element={
            <ProtectedRoute>
              <ProgramDetail />
            </ProtectedRoute>
          }
        />
        <Route
          index
          element={
            <ProtectedRoute>
              <ProgramList />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path={"todos"}
        element={
          <ProtectedRoute>
            <TodosList />
          </ProtectedRoute>
        }
      />

      <Route path={"tutoring-books"}>
        <Route
          path={":tutoringBookId"}
          element={
            <ProtectedRoute>
              <TutoringBookDetail />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path={"not-allowed"} element={<NotAllowed />} />
    </Route>
  )
);

export default function App() {
  return <RouterProvider router={r} />;
}
