import * as React from "react";
import { useNavigate } from "react-router-dom";

// MUI
import { Badge, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer/Drawer";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import PeopleIcon from "@mui/icons-material/People";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PersonIcon from "@mui/icons-material/Person";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";

// TS-Hub
import { Routes } from "../../../constants";
import LogoutIcon from "@mui/icons-material/Logout";
import { useAuth } from "../../../provider/authentication";
import { useEffect, useState } from "react";
import { Todo } from "../../../models/todo";
import { getInfinitePaginatedData } from "../../../helper/http";
import { ApiTodoService } from "../../../services/apiTodoService";

interface DrawerProps extends MuiDrawerProps {
  isOpen?: boolean;
  handleDrawerClose: Function;
}

/**
 * Type that describes a single menu item on the drawer.
 *
 */
type MenuItem = {
  title: string;
  icon: JSX.Element;
  route: string;
};

/**
 * Drawer width.
 *
 */
const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  }
});

export const StyledDrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}));

const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: prop => prop !== "isOpen" })<DrawerProps>(({ theme, isOpen }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(isOpen && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme)
  }),
  ...(!isOpen && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme)
  })
}));

export const Drawer: React.FC<DrawerProps> = ({ isOpen, handleDrawerClose }) => {
  const { doLogout, user } = useAuth();
  const navigate = useNavigate();
  const [todos, setTodos] = useState<Array<Todo>>([]);

  useEffect(() => {
    getInfinitePaginatedData<Todo>(ApiTodoService.fetchAllTodos).then(res => {
      setTodos(res.data);
    });
  }, []);

  /**
   * This is the menu itself.
   *
   */
  const menu: Array<MenuItem> = [
    { title: "Dashboard", icon: <DashboardIcon />, route: "/" },
    { title: "Teilnehmende", icon: <PeopleIcon />, route: Routes.PARTICIPANTS_LIST },
    { title: "Kurse", icon: <CollectionsBookmarkIcon />, route: Routes.COURSES_LIST },
    { title: "Maßnahmen", icon: <LocalLibraryIcon />, route: Routes.PROGRAMS_LIST },
    {
      title: "Meine Aufgaben",
      icon: (
        <Badge badgeContent={todos.length} color={"primary"}>
          <PlaylistAddCheckIcon />
        </Badge>
      ),
      route: Routes.TODOS_LIST
    },
    { title: "Mein Profil", icon: <PersonIcon />, route: Routes.USERS_PROFILE }
  ];

  return (
    <StyledDrawer variant={"permanent"} isOpen={isOpen} handleDrawerClose={handleDrawerClose}>
      <StyledDrawerHeader>
        <IconButton onClick={() => handleDrawerClose()}>
          <ChevronLeftIcon />
        </IconButton>
      </StyledDrawerHeader>

      <Divider />

      <Stack direction={"column"}>
        <List>
          <ListItem>
            <ListItemIcon></ListItemIcon>
            <ListItemText>
              <strong>{user?.getFullName()}</strong>
            </ListItemText>
          </ListItem>
          <Divider />
          {menu.map(menuItem => (
            <ListItem key={menuItem.title} disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={() => navigate(menuItem.route)}
                sx={{
                  minHeight: 48,
                  justifyContent: isOpen ? "initial" : "center",
                  px: 2.5
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: isOpen ? 3 : "auto",
                    justifyContent: "center"
                  }}
                >
                  {menuItem.icon}
                </ListItemIcon>
                <ListItemText primary={menuItem.title} sx={{ opacity: isOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}

          <Divider />
        </List>

        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              onClick={() => doLogout()}
              sx={{
                minHeight: 48,
                justifyContent: isOpen ? "initial" : "center",
                px: 2.5
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isOpen ? 3 : "auto",
                  justifyContent: "center"
                }}
              >
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} sx={{ opacity: isOpen ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Stack>
    </StyledDrawer>
  );
};
