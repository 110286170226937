import React from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Grid, Typography } from "@mui/material";

// TS-Hub
import { AnalyticCard } from "../cards";
import { Participant } from "../../models/participant";
import Alert from "@mui/material/Alert";

interface PerformanceTrackingAnalyticsProps {
  participant: Participant;
}

export const PerformanceTrackingAnalytics: React.FC<PerformanceTrackingAnalyticsProps> = props => {
  const { participant } = props;
  const { t } = useTranslation();

  /**
   * Function to get the recommendation for the presence.
   *
   */
  function getExtrasForPresence(): React.ReactNode {
    return (
      <React.Fragment>
        Dieser Wert zeigt an, wie die Rate der <strong>Anwesenheit</strong> in Prozent ist. Er gilt für den zu bewertenden Monat.
      </React.Fragment>
    );
  }

  /**
   * Function to get the recommendation for the absences.
   *
   */
  function getExtrasForAbsence(): React.ReactNode {
    let recommendedGrade: React.ReactNode;
    let recommendedColor: string;

    if (participant.statistics.lastMonth.absence === 0) {
      recommendedGrade = 1;
      recommendedColor = "green";
    } else if (participant.statistics.lastMonth.absence > 0 && participant.statistics.lastMonth.absence < 5) {
      recommendedGrade = 2;
      recommendedColor = "green";
    } else if (participant.statistics.lastMonth.absence >= 5 && participant.statistics.lastMonth.absence < 10) {
      recommendedGrade = 3;
      recommendedColor = "yellow";
    } else if (participant.statistics.lastMonth.absence >= 10 && participant.statistics.lastMonth.absence < 15) {
      recommendedGrade = 4;
      recommendedColor = "orange";
    } else {
      recommendedGrade = 5;
      recommendedColor = "red";
    }

    return (
      <React.Fragment>
        Dieser Wert zeigt an, wie die Rate der <strong>unentschuldigten Abwesenheit</strong> in Prozent ist. Er gilt für den zu
        bewertenden Monat.
        <br />
        <br />
        <strong>Empfehlung:</strong> Bei einer unentschuldigten Abwesenheit von{" "}
        <Typography variant={"caption"} color={recommendedColor}>
          {participant.statistics.lastMonth.absence}%
        </Typography>{" "}
        empfehlen wir die Note{" "}
        <Typography variant={"caption"} color={recommendedColor}>
          {recommendedGrade}
        </Typography>
        .
      </React.Fragment>
    );
  }

  /**
   * Function to get the recommendation for the homework.
   *
   */
  function getExtrasForHomework(): React.ReactNode {
    let recommendedGrade: React.ReactNode;
    let recommendedColor: string;

    if (participant.statistics.lastMonth.homework <= 100 && participant.statistics.lastMonth.homework >= 90) {
      recommendedGrade = 1;
      recommendedColor = "green";
    } else if (participant.statistics.lastMonth.homework <= 90 && participant.statistics.lastMonth.homework >= 80) {
      recommendedGrade = 2;
      recommendedColor = "green";
    } else if (participant.statistics.lastMonth.homework <= 80 && participant.statistics.lastMonth.homework >= 70) {
      recommendedGrade = 3;
      recommendedColor = "yellow";
    } else if (participant.statistics.lastMonth.homework <= 70 && participant.statistics.lastMonth.homework >= 60) {
      recommendedGrade = 4;
      recommendedColor = "orange";
    } else {
      recommendedGrade = 5;
      recommendedColor = "red";
    }

    return (
      <React.Fragment>
        Dieser Wert zeigt an, wie hoch die Rate der <strong>erledigten Hausaufgaben</strong> in Prozent ist. Er gilt für den zu
        bewertenden Zeitraum.
        <br />
        <br />
        <strong>Empfehlung</strong>: Bei einer Rate von{" "}
        <Typography variant={"caption"} color={recommendedColor}>
          {participant.statistics.lastMonth.homework}%
        </Typography>{" "}
        empfehlen wir die Note{" "}
        <Typography variant={"caption"} color={recommendedColor}>
          {recommendedGrade}
        </Typography>
        .
      </React.Fragment>
    );
  }

  /**
   * Function to get the recommendation for the grade.
   *
   */
  function getExtrasForGrade(): React.ReactNode {
    return (
      <React.Fragment>
        Die <strong>Note des Vormonats</strong> dient zur Orientierung für die Benotung der sonstigen Leistungen. Hier siehst du,
        wie die/der Teilnehmer*in im Vormonat abgeschnitten hat.
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <h4>Leistungsdaten des zu bewertenden Monats: </h4>

      <Alert severity={"info"} style={{ marginBottom: 10 }}>
        Aktuell werden nur die Leistungsdaten des laufenden Vormonats gezogen, nicht die des eingegebenen Vormonats. Das Features
        wird bald implementiert.
      </Alert>

      <Grid container spacing={3}>
        <Grid item xs={3}>
          <AnalyticCard
            title={t("COMMON.WORDS.Presence")}
            count={`${participant.statistics.lastMonth.presence}%`}
            variant={"filled"}
            extra={getExtrasForPresence()}
          />
        </Grid>

        <Grid item xs={3}>
          <AnalyticCard
            title={t("COMMON.WORDS.Absence")}
            count={`${participant.statistics.lastMonth.absence}%`}
            variant={"filled"}
            extra={getExtrasForAbsence()}
          />
        </Grid>

        <Grid item xs={3}>
          <AnalyticCard
            title={t("COMMON.WORDS.Homework")}
            count={`${participant.statistics.lastMonth.homework}%`}
            variant={"filled"}
            extra={getExtrasForHomework()}
          />
        </Grid>

        <Grid item xs={3}>
          <AnalyticCard
            title={t("COMMON.WORDS.Grade") + " Vormonat"}
            count={participant.statistics.lastMonth.grade}
            variant={"filled"}
            extra={getExtrasForGrade()}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
