import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";

// MUI
import { Tab, Tabs } from "@mui/material";
import Box from "@mui/material/Box";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { getInfinitePaginatedData } from "../../helper/http";
import { ApiClassesService } from "../../services/apiClassesService";
import { Course, ICourse } from "../../models/course";
import { Heading } from "../../components/text/heading";
import { TabPanel } from "../../components/tab";
import { CoursesListPanel } from "./coursesListPanel";

export const CoursesList: React.FC = () => {
  const [tabPanel, setTabPanel] = React.useState(0);

  const [activeCourses, setActiveCourses] = useState<Array<Course>>([]);
  const [futureCourses, setFutureCourses] = useState<Array<Course>>([]);
  const [completedCourses, setCompletedCourses] = useState<Array<Course>>([]);

  /**
   * Use useInfiniteQuery to load all courses.
   *
   */
  useEffect(() => {
    getInfinitePaginatedData<ICourse>(ApiClassesService.fetchAllCourses).then(res => {
      const tmpActiveCourses: Array<Course> = [];
      const tmpFutureCourses: Array<Course> = [];
      const tmpCompletedCourses: Array<Course> = [];

      res.data.map(c => {
        const course = new Course(c);
        if (course.isActive()) {
          tmpActiveCourses.push(course);
        } else if (course.willStartLater()) {
          tmpFutureCourses.push(course);
        } else if (course.isCompleted()) {
          tmpCompletedCourses.push(course);
        }
      });

      setActiveCourses(tmpActiveCourses.sort((a, b) => (a.title > b.title ? 1 : -1)));
      setFutureCourses(tmpFutureCourses.sort((a, b) => (a.title > b.title ? 1 : -1)));
      setCompletedCourses(tmpCompletedCourses.sort((a, b) => (a.title > b.title ? 1 : -1)));
    });
  }, []);

  function handleTabOnChange(event: React.SyntheticEvent, newValue: number) {
    setTabPanel(newValue);
  }

  return (
    <AppLayout>
      <Heading text={<Trans i18nKey={"COMMON.WORDS.Courses"}>Courses</Trans>} />

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabPanel} onChange={handleTabOnChange} aria-label="basic tabs example">
          <Tab label="Aktive Kurse" />
          <Tab label="Abgeschlossene Kurse" />
          <Tab label="Zukünftige Kurse" />
        </Tabs>
      </Box>

      <TabPanel index={0} value={tabPanel}>
        <CoursesListPanel courses={activeCourses} />
      </TabPanel>
      <TabPanel index={1} value={tabPanel}>
        <CoursesListPanel courses={completedCourses} />
      </TabPanel>
      <TabPanel index={2} value={tabPanel}>
        <CoursesListPanel courses={futureCourses} />
      </TabPanel>
    </AppLayout>
  );
};
