import { CourseBookRecord, ICourseBookRecord } from "./courseBookRecord";
import { ApiClassesService } from "../services/apiClassesService";
import download from "downloadjs";
import { Dayjs } from "dayjs";

export interface ICourseBook {
  id: number;

  course: string;

  entries: Array<CourseBookRecord>;
}

export interface ICompletionCheck {
  complete: boolean;
  missingDays: Array<Dayjs>;
}

export class CourseBook implements ICourseBook {
  id: number;

  course: string;

  entries: Array<CourseBookRecord> = [];

  constructor(data: ICourseBook) {
    this.id = data.id;
    this.course = data.course || "";

    if (Array.isArray(data.entries)) {
      data.entries.map(e => this.entries.push(new CourseBookRecord(e as ICourseBookRecord)));
    }
  }

  /**
   * Calls the backend to download the course book of this course.
   *
   */
  public async downloadCourseBook(period: Date) {
    const res = await ApiClassesService.downloadCourseBook(
      this.id,
      (period.getMonth() + 1).toString(),
      period.getFullYear().toString()
    );
    if (res.status === 200) {
      const fileName = `${this.course} - ${period.getMonth() + 1}.${period.getFullYear()}.pdf`;
      download(res.data, fileName, "application/pdf");
    }
  }
}
