import React, { ReactNode } from "react";

// MUI
import { Typography } from "@mui/material";

type HeadingProps = {
  text: string | ReactNode;
};

export const Heading: React.FC<HeadingProps> = props => {
  const { text } = props;

  return (
    <>
      <Typography variant={"h3"} style={{ marginBottom: "20px", textAlign: "center" }}>
        {text}
      </Typography>
    </>
  );
};
