import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

// MUI
import { Box, IconButton } from "@mui/material";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import DoneIcon from "@mui/icons-material/Done";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { Todo, ITodo } from "../../models/todo";
import { getInfinitePaginatedData } from "../../helper/http";
import { ApiTodoService } from "../../services/apiTodoService";
import { MainCard } from "../../components/cards";
import { useSnackbar } from "../../provider/snackbar";
import { FeatureNotPublished } from "../../components/notifier/featureNotPublished";
import { Heading } from "../../components/text/heading";

export const TodosList: React.FC = () => {
  const [todos, setTodos] = useState<Array<Todo>>([]);
  const { toast } = useSnackbar();
  const { t } = useTranslation();

  useEffect(() => {
    fetchTodos();
  }, []);

  /**
   * Function to fetch the to dos from the backend.
   *
   */
  function fetchTodos() {
    const tmp: Array<Todo> = [];
    getInfinitePaginatedData<ITodo>(ApiTodoService.fetchAllTodos).then(res => {
      res.data.forEach((todo: ITodo) => tmp.push(new Todo(todo)));
      setTodos(tmp);
    });
  }

  /**
   * Marks the given to do model as done!
   *
   * @param todo
   */
  function markAsDone(todo: Todo) {
    ApiTodoService.markTodoAsDone(todo).then(() => {
      toast({ message: `"${todo.title} marked as done!"`, level: "success" });
      setTimeout(() => {
        fetchTodos();
      }, 1_000);
    });
  }

  return (
    <AppLayout>
      <Heading text={"Todos"} />

      <FeatureNotPublished />

      <MainCard sx={{ height: "auto" }}>
        <TableContainer component={Box}>
          <Table size={"small"} aria-label={"Todos table"} border={0}>
            <TableHead>
              <TableRow>
                <TableCell>{t("COMMON.WORDS.date")}</TableCell>
                <TableCell align={"left"}>Todo</TableCell>
                <TableCell align={"right"}>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {todos?.map((todo: Todo) => (
                <TableRow key={todo.title} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell component={"th"} scope={"row"}>
                    {todo.doingDate.format("dddd DD.MM.YYYY")}
                  </TableCell>
                  <TableCell align={"left"}>{t("TODOS.TITLES." + todo.title)}</TableCell>
                  <TableCell align={"right"}>
                    <IconButton aria-label={"done"} onClick={() => markAsDone(todo)}>
                      <DoneIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>
    </AppLayout>
  );
};
