import React, { useEffect, useState } from "react";

// MUI
import { Box } from "@mui/material";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

// TS-Hub
import { PerformanceTracking } from "../../../models/performanceTracking";
import { ApiParticipantService } from "../../../services/apiParticipantService";
import { Participant } from "../../../models/participant";
import { getInfinitePaginatedData } from "../../../helper/http";
import { Comment, IComment } from "../../../models/comment";

interface PerformanceCommentsTimelineProps {
  participant: Participant;
}
export const PerformanceCommentsTimeline: React.FC<PerformanceCommentsTimelineProps> = props => {
  const { participant } = props;
  const [comments, setComments] = useState<Array<Comment>>([]);
  const [data, setData] = useState<PerformanceTracking[]>([]);

  useEffect(() => {
    getInfinitePaginatedData<IComment>(
      ApiParticipantService.fetchAllCommentsForParticipant,
      [],
      new URLSearchParams({ participant: String(participant.id) })
    ).then(res => {
      setComments(res.data.map(d => new Comment(d)));
    });

    participant.getPerformanceTracking().then(data => setData(data.performanceTracking));
  }, [participant]);

  return (
    <Box>
      <Box display={"flex"}>
        <Box width={"50%"}>
          <h3>Kommentare zu Leistungserfassung</h3>
          <Timeline>
            {data.map(d => {
              return (
                <TimelineItem key={d.id}>
                  <TimelineOppositeContent color={"text.secondary"}>
                    {d.dateMonth}.{d.dateYear}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{d.comment}</TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Box>

        <Box width={"50%"}>
          <h3>Allgemeine Kommentare</h3>
          <Timeline>
            {comments.map(d => {
              return (
                <TimelineItem key={d.id}>
                  <TimelineOppositeContent color={"text.secondary"}>
                    <div>
                      {d.createdAt.format("DD.MM.YYYY, dddd")}
                      <div>User: {d.creator}</div>
                    </div>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{d.comment}</TimelineContent>
                </TimelineItem>
              );
            })}
          </Timeline>
        </Box>
      </Box>
    </Box>
  );
};
