import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";
import ReactDOM from "react-dom/client";

import dayjs from "dayjs";
import "dayjs/locale/de";

import * as Sentry from "@sentry/react";

import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import { LicenseInfo } from "@mui/x-data-grid-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";

import App from "./App";
import { GoogleClientId } from "./constants";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./provider/authentication";
import { ThemingProvider } from "./provider/theming";
import { SnackbarProvider } from "./provider/snackbar";
import { ErrorPage } from "./pages/error";

/**
 * Sentry
 *
 */
Sentry.init({
  dsn: "https://7636a9ebdb9e7966888fe747b25700f1@o4505709283704832.ingest.sentry.io/4505709289406464",
  integrations: [
    new Sentry.BrowserProfilingIntegration(),
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!

  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api\.hub\.apps\.techstarter\.de\/api/],

  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**
 * MUI License
 */
LicenseInfo.setLicenseKey(String(process.env.REACT_APP_MUI_LICENSE));

/**
 * React Query queryClient.
 *
 */
const queryClient = new QueryClient();

/**
 * Initialize dayjs.
 *
 */
dayjs.locale("de");

/**
 * Root element for React.
 *
 */
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<ErrorPage />} showDialog={true}>
      <GoogleOAuthProvider clientId={GoogleClientId}>
        <I18nextProvider i18n={i18n}>
          <ThemingProvider>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient}>
                <ReactQueryDevtools initialIsOpen={false} />
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                  <AuthProvider>
                    <App />
                  </AuthProvider>
                </LocalizationProvider>
              </QueryClientProvider>
            </SnackbarProvider>
          </ThemingProvider>
        </I18nextProvider>
      </GoogleOAuthProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
