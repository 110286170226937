import { AxiosResponse } from "axios";
import dayjs from "dayjs";

import { dispatch } from "./http";
import { Participant } from "../models/participant";
import { Todo } from "../models/todo";

export class ApiTodoService {
  public static fetchAllTodos(pageParam: URLSearchParams): Promise<AxiosResponse> {
    return dispatch({
      method: "GET",
      url: "/api/v1/todos/",
      params: pageParam
    });
  }

  public static markTodoAsDone(todo: Todo): Promise<AxiosResponse<Participant>> {
    return dispatch({
      method: "PATCH",
      url: `/api/v1/todos/${todo.id}/`,
      data: { doneAt: dayjs() }
    });
  }
}
