import React from "react";
import { useTranslation } from "react-i18next";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

import { PerformanceTracking } from "../../../models/performanceTracking";
import { MainCard } from "../../../components/cards";

interface PerformanceTrackingGradeChartProps {
  data: Array<PerformanceTracking>;
}

export const PerformanceTrackingGradeChart: React.FC<PerformanceTrackingGradeChartProps> = props => {
  const { data } = props;
  const { t } = useTranslation();

  /**
   * Creates label for the chart label.
   *
   * @param label
   * @param payload
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getLabel(label: string, payload: any) {
    let text = "";
    if (Array.isArray(payload)) {
      if (payload[0] !== undefined) {
        text = `Zeitraum: ${payload[0].payload.dateMonth}.${payload[0].payload.dateYear}`;
      }
    }
    return <p>{text}</p>;
  }

  return (
    <React.Fragment>
      <MainCard sx={{ height: "auto" }} title={"Gesamtnote"}>
        <ResponsiveContainer width={"100%"} height={300}>
          <LineChart data={data} syncId={"anyId"}>
            <CartesianGrid horizontal={true} vertical={false} strokeDasharray={"1 1"} />
            <XAxis dataKey={"dateMonth"} />
            <YAxis domain={[0, 6]} />
            <Tooltip
              contentStyle={{ backgroundColor: "#121212", color: "#fff", border: "none" }}
              cursor={false}
              labelFormatter={getLabel}
            />
            <Legend />
            <Line
              type={"monotone"}
              dataKey={"result"}
              name={`${t("COMMON.WORDS.Grade")}`}
              stroke={"#43755B"}
              strokeWidth={"3"}
              dot={{ strokeWidth: 2, r: 1 }}
              activeDot={{ strokeWidth: 2, r: 5 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </MainCard>
    </React.Fragment>
  );
};
