export enum Role {
  Teacher = 1,
  TeacherJunior = 2,
  TeachingAssistant = 6,
  ClassManager = 7,
  CareerCoach = 8
}

export interface IUser {
  id: number;

  email: string;

  firstName: string;

  lastName: string;

  password?: string;

  isSuperuser: boolean;

  role: Role;

  phoneNumber: string;

  signatureFile: string;
}

export class User {
  id: number;

  email: string;

  firstName: string;

  lastName: string;

  password?: string;

  isSuperuser: boolean;

  role: Role;

  phoneNumber: string;

  signatureFile: string;

  constructor(data: IUser) {
    this.id = data.id || 0;
    this.email = data.email;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.isSuperuser = data.isSuperuser;
    this.role = data.role;
    this.phoneNumber = data.phoneNumber;
    this.signatureFile = data.signatureFile;
  }

  public getFullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public isTrainer(): boolean {
    return this.role === Role.Teacher;
  }

  public isTrainerTeam(): boolean {
    return this.role === Role.Teacher || this.role === Role.TeacherJunior;
  }

  public isEduStaff(): boolean {
    return this.role === Role.Teacher || this.role === Role.TeacherJunior || this.role === Role.TeachingAssistant;
  }
}
