import React from "react";

// MUI
import { GridRenderEditCellParams, useGridApiContext } from "@mui/x-data-grid-pro";
import { unstable_useEnhancedEffect as useEnhancedEffect } from "@mui/utils";
import { MenuItem, Select } from "@mui/material";

// TS-Hub
import { useSnackbar } from "../../../provider/snackbar";
import { HomeworkTrackingIcon } from "./homeworkTrackingIcon";

type EditComponentProps = {
  params: GridRenderEditCellParams;
};

export const HomeworkTrackingCellEdit: React.FC<EditComponentProps> = props => {
  const { id, value, field, hasFocus } = props.params;
  const apiRef = useGridApiContext();
  const ref = React.useRef<HTMLElement>();
  const { toast } = useSnackbar();

  useEnhancedEffect(() => {
    if (hasFocus && ref.current && value.homeworkTrackingId) {
      switch (value.homeworkTrackingStatus) {
        case true:
          ref.current.querySelector<HTMLInputElement>(`input[value="true"]`);
          break;
        // input?.focus();
        case false:
          ref.current.querySelector<HTMLInputElement>(`input[value="false"]`);
          break;
        default:
          ref.current.querySelector<HTMLInputElement>(`input[value="to-delete"]`);
      }
    }
  }, [hasFocus, value]);

  function handleChange(newValue: number | string) {
    const val = newValue === "true" ? true : newValue === "false" ? false : null;

    if (value) {
      apiRef.current.setEditCellValue({
        id,
        field,
        value: { ...value, homeworkTrackingStatus: val },
        debounceMs: 200
      });
    } else {
      toast({ level: "warning", message: "Für diesen Zeitraum gibt es keinen Eintrag. Bitte erstelle zunächst einen." });
    }
  }

  return (
    <Select
      fullWidth={true}
      ref={ref}
      value={value.isHomeworkTracking ? value.homeworkTrackingStatus : false}
      onChange={event => handleChange(event.target.value)}
      size={"small"}
    >
      <MenuItem value={"true"}>
        <HomeworkTrackingIcon status={true} comment={""} />
      </MenuItem>
      <MenuItem value={"false"}>
        <HomeworkTrackingIcon status={false} comment={""} />
      </MenuItem>
      {/*<MenuItem value={"to-delete"}>*/}
      {/*  <HomeworkTrackingIcon status={null} comment={""} />*/}
      {/*</MenuItem>*/}
    </Select>
  );
};
