import dayjs, { Dayjs } from "dayjs";

import { Participant } from "./participant";

/**
 * Return the right presence period based on the time of the user.
 *
 */
export function getDefaultPresencePeriod() {
  if (new Date().getHours() <= 12) {
    return PresencePeriod.MORNING;
  } else {
    return PresencePeriod.AFTERNOON;
  }
}

export enum PresenceStatus {
  PRESENT = "x",
  DELAYED = "v",
  ABSENT = "a",
  EXCUSED = "e",
  VACATION = "u",
  HOLIDAY = "f"
}

export enum PresencePeriod {
  MORNING = "m",
  AFTERNOON = "a"
}

export interface IPresenceTracking {
  id?: number;
  participant: Participant;
  date: Dayjs;
  status: PresenceStatus;
  period: PresencePeriod;
  comment: string;
}

export interface IPresenceTrackingCreate {
  participant: number;
  date: Dayjs | string;
  status: PresenceStatus;
  period: PresencePeriod;
  comment: string;
}

export interface IPresenceTrackingUpdate {
  status: PresenceStatus;
  period?: PresencePeriod;
  comment: string;
}

export interface IPresenceTrackingSummary {
  today: {
    present: number;
    absent: number;
  };

  yesterday: {
    present: number;
    absent: number;
  };
}

export interface IPresenceTrackingMonthReport {
  date: Dayjs;
  name: string;
  countPresentMo: number;
  countPresentAn: number;
  countAbsentMo: number;
  countAbsentAn: number;
  countExcusedMo: number;
  countExcusedAn: number;
  countVacationMo: number;
  countVacationAn: number;
}

export class PresenceTracking implements IPresenceTracking {
  id?: number;
  participant: Participant;
  date: Dayjs;
  status: PresenceStatus;
  period: PresencePeriod;
  comment: string;

  constructor(data: IPresenceTracking) {
    this.id = data.id;
    this.participant = new Participant(data.participant);
    this.date = dayjs(data.date);
    this.status = data.status;
    this.period = data.period;
    this.comment = data.comment;
  }
}
