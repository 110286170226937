import React from "react";

// MUI
import { Tooltip } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";

type IconProps = {
  status: boolean | null;
  comment?: string;
};

export const HomeworkTrackingIcon: React.FC<IconProps> = props => {
  const { status, comment } = props;

  switch (status) {
    case true:
      return (
        <Tooltip title={comment}>
          <CheckIcon color={"success"} />
        </Tooltip>
      );
    case false:
      return (
        <Tooltip title={comment}>
          <CloseIcon color={"error"} />
        </Tooltip>
      );
    default:
      return (
        <Tooltip title={"Keine Hausaufgaben an diesem Tag"}>
          <RemoveIcon color={"warning"} />
        </Tooltip>
      );
  }
};
