import { styled } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import { tableCellClasses } from "@mui/material";
import TableRow from "@mui/material/TableRow";

export const SimpleTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    height: "70px",
    border: "1px #1e1e1e solid"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    border: "1px #1e1e1e solid"
  }
}));

export const SimpleTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0
  }
}));
