import { IPerformanceTrackingParameter } from "./performanceTrackingParameter";

export interface IPerformanceTrackingTemplate {
  id?: number;
  name: string;
  performanceTrackingParameters: Array<IPerformanceTrackingParameter>;
}

export class PerformanceTrackingTemplate implements IPerformanceTrackingTemplate {
  id?: number;
  name: string;
  performanceTrackingParameters: Array<IPerformanceTrackingParameter>;

  constructor(data: IPerformanceTrackingTemplate) {
    this.id = data.id;
    this.name = data.name;
    this.performanceTrackingParameters = data.performanceTrackingParameters;
  }
}
