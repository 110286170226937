import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

// MUI
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// TS-Hub
import { User } from "../../../models/user";
import { ApiUserService } from "../../../services/apiUserService";
import { useSnackbar } from "../../../provider/snackbar";
import { useAuth } from "../../../provider/authentication";

type UserFormProps = {
  isLoading: boolean;
  setIsLoading: Function;
  user: User;
};

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});

export const UserForm: React.FC<UserFormProps> = props => {
  const { isLoading, setIsLoading, user } = props;
  const [password2, setPassword2] = useState<String>();
  const [passwordValid, setPasswordValid] = useState(false);
  const { t } = useTranslation();
  const { register, handleSubmit, watch } = useForm<User>({ values: user! });
  const { toast } = useSnackbar();
  const { cacheUser } = useAuth();
  const password = watch("password");

  /**
   * Validate the password and return the state.
   *
   */
  useEffect(() => {
    if (password) {
      if (password.length > 8 && password === password2) {
        setPasswordValid(true);
      } else {
        setPasswordValid(false);
      }
    } else {
      setPasswordValid(true);
    }
  }, [password, password2]);

  async function onSubmit(data: User) {
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { signatureFile, ...rest } = data;
    const res = await ApiUserService.updateMe(rest as User);
    res.status === 200 && toast({ message: "Profil aktualisiert!", level: "success" });
    res.status !== 200 && toast({ message: "Aktualisierung nicht erfolgreich!", level: "warning" });
    cacheUser();
    setIsLoading(false);
  }

  /**
   * Handles the signature change and uploads the file to the cloud.
   *
   * @param event
   */
  function handleSignatureOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event.target.files![0];
    const formData = new FormData();
    formData.append("signatureFile", file);
    ApiUserService.updateMeSignature(user, formData).then(res => {
      res.status === 200 && toast({ message: "Unterschrift aktualisiert!", level: "success" });
      res.status !== 200 && toast({ message: "Aktualisierung nicht erfolgreich!", level: "warning" });
      cacheUser();
    });
  }

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography variant={"h5"}>Persönliche Daten</Typography>

        <Divider style={{ margin: "10px 0" }} />

        <Grid container spacing={3} sx={{ paddingTop: "10px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("COMMON.WORDS.First Name")}
              fullWidth={true}
              variant={"outlined"}
              required={true}
              defaultValue={" "}
              {...register("firstName")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label={t("COMMON.WORDS.Last Name")}
              fullWidth={true}
              variant={"outlined"}
              required={true}
              defaultValue={" "}
              {...register("lastName")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label={"E-Mail"}
              fullWidth={true}
              variant={"outlined"}
              required={true}
              defaultValue={" "}
              {...register("email")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label={"Phone"}
              fullWidth={true}
              variant={"outlined"}
              required={false}
              defaultValue={" "}
              {...register("phoneNumber")}
            />
          </Grid>
        </Grid>

        <Typography variant={"h5"} mt={"50px"}>
          Signatur
        </Typography>

        <Grid container spacing={3} sx={{ padding: "10px 0px" }}>
          <Grid item xs={12} sm={6}>
            <Button
              component="label"
              role={undefined}
              variant="contained"
              fullWidth={true}
              tabIndex={-1}
              startIcon={<CloudUploadIcon />}
            >
              Upload Unterschrift (.png) (596px X 270px)
              <VisuallyHiddenInput type={"file"} accept={".png"} onChange={handleSignatureOnChange} />
            </Button>
          </Grid>

          <Grid item xs={12} sm={6}>
            <img src={user.signatureFile} style={{ width: "100%", height: "auto" }} />
          </Grid>
        </Grid>

        <Typography variant={"h5"} mt={"50px"}>
          Password
        </Typography>

        <Divider style={{ margin: "10px 0" }} />

        <Grid container spacing={3} sx={{ padding: "10px 0px" }}>
          <Grid item xs={12} sm={6}>
            <TextField
              label={t("COMMON.WORDS.Password (new)")}
              fullWidth={true}
              variant={"outlined"}
              type={"password"}
              placeholder={"Mindestens 8 Zeichen"}
              {...register("password")}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              label={t("COMMON.WORDS.Password (new repeat)")}
              fullWidth={true}
              variant={"outlined"}
              type={"password"}
              value={password2}
              error={!passwordValid}
              onChange={evt => setPassword2(evt.target.value)}
            />
          </Grid>
        </Grid>

        <Divider style={{ margin: "50px 0 20px 0" }} />
        <Grid container spacing={3}>
          <Grid item width={"100%"}>
            <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
              <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading} disabled={!passwordValid}>
                Speichern
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </React.Fragment>
  );
};
