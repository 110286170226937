import React, { useEffect, useState } from "react";

// MUI
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";

// TS-Hub
import { AnalyticCard } from "../../../../components/cards";
import { Course, ICoursePerformanceReport } from "../../../../models/course";
import { ApiClassesService } from "../../../../services/apiClassesService";
import { Gender } from "../../../../models/participant";

interface CoursePerformanceReportProps {
  course: Course;
}

export const CoursePerformanceReport: React.FC<CoursePerformanceReportProps> = props => {
  const [report, setReport] = useState<ICoursePerformanceReport>();
  const { course } = props;

  useEffect(() => {
    ApiClassesService.fetchCoursePerformanceReport(course.title).then(res => setReport(res.data));
  }, []);

  if (!report) {
    return null;
  }

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Anwesenheit"}
            count={`${report.presenceAvg}%`}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Anwesenheit
                </Typography>{" "}
                im Durchschnitt über den gesamten Kurs und den gesamten Zeitraum.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Abwesenheit"}
            count={`${report.absenceAvg}%`}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  unentschuldigte Abwesenheit
                </Typography>{" "}
                im Durchschnitt über den gesamten Kurs und den gesamten Zeitraum.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Hausaufgaben"}
            count={`${report.homeworkAvg}%`}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  erledigt Hausaufgaben
                </Typography>{" "}
                im Durchschnitt über den gesamten Kurs und den gesamten Zeitraum.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Ø Note"}
            count={report.gradeAvg}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Gesamtnote
                </Typography>{" "}
                im Durchschnitt über den gesamten Kurs und den gesamten Zeitraum.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Teilnehmende Gesamt"}
            count={course.participants.length}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Anzahl
                </Typography>{" "}
                an Teilnehmern in diesem Kurs.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"davon Frauen"}
            count={course.participants.filter(p => p.gender === Gender.WOMAN).length}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Anzahl
                </Typography>{" "}
                an Teilnehmern in diesem Kurs, die sich als{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Frau
                </Typography>{" "}
                identifizieren.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"davon Männer"}
            count={course.participants.filter(p => p.gender === Gender.MAN).length}
            extra={
              <>
                Die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Anzahl
                </Typography>{" "}
                an Teilnehmern in diesem Kurs, die sich als{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Mann
                </Typography>{" "}
                identifizieren.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Frauenquote"}
            count={`${(
              ((course.participants.length - course.participants.filter(p => p.gender === Gender.MAN).length) /
                course.participants.length) *
              100
            ).toFixed(2)}%`}
            extra={
              <>
                Die Menge in{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Prozent
                </Typography>{" "}
                an Teilnehmern in diesem Kurs, die sich als{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  Frau
                </Typography>{" "}
                identifizieren.
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Dropouts"}
            count={report.dropouts}
            extra={
              <>
                Die Anzahl an Teilnehmern, die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  abgebrochen haben oder nicht angetreten sind.
                </Typography>
              </>
            }
            variant={"filled"}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Dropout Rate"}
            count={`${report.dropoutRate}%`}
            extra={
              <>
                Die prozentuale Anzahl an Teilnehmern, die{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  abgebrochen haben oder nicht angetreten sind.
                </Typography>
              </>
            }
            variant={"filled"}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
