import React, { useEffect, useState } from "react";
import _ from "lodash";

// MUI

// TS-Hub
import { CourseBook } from "../../../../models/courseBook";
import { CourseBookRecord } from "../../../../models/courseBookRecord";
import { MainCard } from "../../../../components/cards";
import { CourseBookAccordion } from "./courseBookAccordion";

type SortedEntries = {
  [key: number]: {
    [key: number]: {
      monthName: string;
      records: Array<CourseBookRecord>;
    };
  };
};

type CourseBookAccordionListProps = {
  courseBook: CourseBook;
  refreshCourseBook: Function;
};

export const CourseBookAccordionList: React.FC<CourseBookAccordionListProps> = props => {
  const { courseBook, refreshCourseBook } = props;
  const [entries, setEntries] = useState<SortedEntries>({});

  /**
   * useEffect to fetch the data from the backend.
   *
   */
  useEffect(() => {
    if (courseBook) {
      sortEntries(courseBook);
    }
  }, [courseBook]);

  /**
   * Create a sorted object of all entries by year and month.
   *
   * This functions creates an object like this:
   * const o = { 2022: { "December": [{}, {}, {}]}}
   *
   * @param courseBook
   */
  function sortEntries(courseBook: CourseBook) {
    const tmp: SortedEntries = {};
    courseBook.entries.map(entry => {
      const year = entry.teachingDay.year();
      const month = entry.teachingDay.month();
      const monthName = entry.teachingDay.format("MMMM");

      if (_.isEmpty(tmp[year])) {
        tmp[year] = {};
      }

      if (!tmp[year][month]) {
        tmp[year][month] = {
          monthName: monthName,
          records: []
        };
      }

      tmp[year][month].records.push(entry);
    });
    setEntries(tmp);
  }

  /**
   * Renders and returns the whole accordion element.
   *
   */
  function renderAccordions(): React.ReactNode {
    const accordions: Array<React.ReactNode> = [];

    Object.keys(entries).forEach(value => {
      const year = Number(value);
      const entriesOfYear = entries[year];
      Object.keys(entriesOfYear).forEach((value, index) => {
        const month = Number(value);
        accordions.push(
          <CourseBookAccordion
            key={index}
            courseBook={courseBook}
            year={year}
            month={month}
            monthName={entries[year][month].monthName}
            records={entries[year][month].records}
            refreshCourseBook={refreshCourseBook}
          />
        );
      });
    });

    return accordions;
  }

  return (
    <MainCard title={"Kursbücher"} sx={{ height: "auto" }}>
      {renderAccordions()}
    </MainCard>
  );
};
