import * as React from "react";

// MUI
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";

// TS-Hub
import { AppBar } from "./components/appBar";
import { Drawer, StyledDrawerHeader } from "./components/drawer";
import { useEffect } from "react";
import Alert from "@mui/material/Alert";

type AppLayoutProps = {
  children?: React.ReactNode;
};
export const AppLayout: React.FC<AppLayoutProps> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(window.innerWidth > 1280);

  useEffect(() => {
    addEventListener("resize", onWindowResize);
    return () => removeEventListener("resize", onWindowResize);
  });

  /**
   * Handler that checks the window size and automatically opens or closes the drawer.
   *
   */
  function onWindowResize() {
    setIsOpen(window.innerWidth > 1280);
  }

  /**
   * Handler for setting the drawer to open.
   *
   */
  function handleDrawerOpen(): void {
    setIsOpen(true);
  }

  /**
   * Handler for setting the drawer to closed.
   *
   */
  function handleDrawerClose(): void {
    setIsOpen(false);
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar isOpen={isOpen} handleDrawerOpen={handleDrawerOpen} />
      <Drawer isOpen={isOpen} handleDrawerClose={handleDrawerClose} />
      <Box component={"main"} sx={{ flexGrow: 1, p: 3 }}>
        <StyledDrawerHeader />
        {process.env.REACT_APP_APP_ENVIRONMENT === "staging" && (
          <Alert severity={"warning"} sx={{ margin: "5px 0 30px 0" }}>
            APP ENVIRONMENT: STAGING! <br />
            DATA ENVIRONMENT: PRODUCTION!
          </Alert>
        )}
        {children}
      </Box>
    </Box>
  );
};
