import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// MUI
import TableContainer from "@mui/material/TableContainer";
import { Button, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { Heading } from "../../components/text/heading";
import { IProgram, Program } from "../../models/program";
import { getInfinitePaginatedData } from "../../helper/http";
import { ApiProgramsService } from "../../services/apiProgramsService";
import { MainCard } from "../../components/cards";

export const ProgramList: React.FC = () => {
  const [programs, setPrograms] = useState<Array<Program>>();
  const navigate = useNavigate();

  useEffect(() => {
    getInfinitePaginatedData<IProgram>(ApiProgramsService.fetchPrograms).then(res =>
      setPrograms(res.data.map(p => new Program(p)))
    );
  }, []);

  return (
    <AppLayout>
      <Heading text={"Maßnahmen"} />

      <MainCard>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Maßnahmennummer</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {programs?.map((program, index) => (
                <TableRow key={index} hover={true}>
                  <TableCell>{program.name}</TableCell>
                  <TableCell>{program.officialNumber}</TableCell>
                  <TableCell>
                    <Button onClick={() => navigate(`/programs/${program.id}`)} variant={"contained"}>
                      Details
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </MainCard>
    </AppLayout>
  );
};
