import { type AxiosRequestHeaders, AxiosResponse } from "axios";

import { dispatch } from "./http";
import { IUser } from "../models/user";

export class ApiUserService {
  public static fetchMe(): Promise<AxiosResponse<IUser>> {
    return dispatch({
      method: "GET",
      url: "/api/v1/users/me/"
    });
  }

  public static updateMe(user: IUser): Promise<AxiosResponse<IUser>> {
    return dispatch({
      method: "PATCH",
      url: "/api/v1/users/me/",
      data: user
    });
  }

  public static updateMeSignature(user: IUser, formData: FormData): Promise<AxiosResponse<IUser>> {
    return dispatch({
      method: "PATCH",
      url: "/api/v1/users/me/",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data"
      } as AxiosRequestHeaders
    });
  }
}
