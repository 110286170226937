import React from "react";

// MUI
import { Avatar, Grid, List, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";

// TS-Hub
import { MainCard } from "../../../../components/cards";
import { Course } from "../../../../models/course";

type CourseTeamProps = {
  course: Course;
};

export const CourseTeam: React.FC<CourseTeamProps> = props => {
  const { course } = props;

  return (
    <MainCard title={"Kurs-Team"}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <List sx={{ width: "100%" }}>
                {course.teacher.map(t => (
                  <ListItem key={t.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <SchoolIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t.getFullName()} secondary={"Dozent"} />
                  </ListItem>
                ))}

                {course.teachingAssistants.map(t => (
                  <ListItem key={t.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <CastForEducationIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t.getFullName()} secondary={"Teaching Assistance"} />
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={6}>
              <List sx={{ width: "100%" }}>
                {course.classManager.map(t => (
                  <ListItem key={t.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <SupportAgentIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t.getFullName()} secondary={"Class Management"} />
                  </ListItem>
                ))}
                {course.careerCoaches.map(t => (
                  <ListItem key={t.id}>
                    <ListItemAvatar>
                      <Avatar>
                        <FitnessCenterIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={t.getFullName()} secondary={"Career Coach"} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </MainCard>
  );
};
