import { AxiosResponse } from "axios";

import { dispatch } from "./http";

export type Session = {
  access: string;
  refresh: string;
};

export class ApiSessionService {
  public static createSession(username: string, password: string): Promise<AxiosResponse<Session>> {
    return dispatch({
      method: "POST",
      url: "/api/v1/sessions/",
      data: { username, password }
    });
  }
}
