import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";

// MUI
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Autocomplete from "@mui/material/Autocomplete";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

// TS-Hub
import { CourseBook } from "../../../models/courseBook";
import { Module } from "../../../models/module";
import { ICourseBookRecordCreate } from "../../../models/courseBookRecord";
import { getInfinitePaginatedData } from "../../../helper/http";
import { ApiClassesService } from "../../../services/apiClassesService";
import { ApiProgramsService } from "../../../services/apiProgramsService";
import { useSnackbar } from "../../../provider/snackbar";
import { Course } from "../../../models/course";
import { Topic } from "../../../models/topic";

type CourseBookRecordCreateModalProps = {
  isOpen: boolean;
  onClose: Function;
  course: Course;
  courseBook: CourseBook;
  refreshCourseBook: Function;
};

export const CourseBookRecordCreateModal: React.FC<CourseBookRecordCreateModalProps> = props => {
  const [modules, setModules] = useState<Array<Module>>([]);
  const [topics, setTopics] = useState<Array<Topic>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { isOpen, onClose, course, courseBook, refreshCourseBook } = props;
  const { toast } = useSnackbar();
  const { register, handleSubmit, control, reset, setValue, watch } = useForm<ICourseBookRecordCreate>({
    values: {
      courseBook: courseBook.id,
      module: 0,
      entry: "",
      topic: "",
      teachingDay: null,
      teachingUnits: 0
    }
  });
  const watchedModule = watch("module");

  /**
   * useEffect that fetches all Modules.
   *
   */
  useEffect(() => {
    if (isOpen) {
      getInfinitePaginatedData<Module>(
        ApiProgramsService.fetchAllModules,
        [],
        new URLSearchParams({ program: String(course.program) })
      ).then(res => setModules(res.data));
    }
  }, [isOpen]);

  /**
   * Fetch topics.
   *
   */
  function fetchTopics(moduleId: number) {
    getInfinitePaginatedData<Topic>(ApiProgramsService.fetchTopics, [], new URLSearchParams({ module: String(moduleId) })).then(
      res => {
        setTopics(res.data);
      }
    );
  }

  /**
   * Handles the module changes event.
   *
   */
  async function moduleOnChange(onChange: Function, moduleId: number) {
    const chosenModule = modules.find(m => m.id === moduleId);
    if (chosenModule) setValue("teachingUnits", chosenModule?.teachingUnitsPerDay);
    onChange(moduleId);
    fetchTopics(moduleId);
  }

  /**
   * Handles the topic changes event.
   *
   */
  function topicOnChange(onChange: Function, topicTitle: string) {
    onChange(topicTitle);
  }

  /**
   * Handler for the onSubmit event.
   *
   * @param data
   */
  async function handleOnSubmit(data: ICourseBookRecordCreate) {
    setIsLoading(true);
    const res = await ApiClassesService.createCourseBookRecord(data);
    if (res.status === 201) {
      toast({ message: t("COURSE_BOOK_RECORDS.TOASTS.CREATED_SUCCESSFULLY"), level: "success" });
      refreshCourseBook();
      setTimeout(() => handleOnClose(), 2_000);
    } else if (res.status === 403) {
      toast({ message: t("COURSE_BOOK_RECORDS.TOASTS.CREATION_NOT_ALLOWED"), level: "error" });
      setIsLoading(false);
      onClose();
    } else {
      toast({ message: t("COURSE_BOOK_RECORDS.TOASTS.CREATION_FAILED"), level: "error" });
      setIsLoading(false);
    }
  }

  /**
   * Handles the onClose event of the modal.
   *
   */
  function handleOnClose() {
    setIsLoading(false);
    reset();
    onClose();
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <DialogTitle>Kursbucheintrag hinzufügen</DialogTitle>
        <DialogContent>
          <Grid container spacing={3} sx={{ marginTop: "0" }}>
            <Grid item xs={12} sm={9}>
              <Controller
                control={control}
                name={"teachingDay"}
                render={({ field: { ref, ...field } }) => (
                  <DesktopDatePicker {...field} inputRef={ref} label={t("COMMON.WORDS.TeachingDay")} sx={{ width: "100%" }} />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <TextField
                id={"teachingUnits"}
                label={t("COMMON.WORDS.TU")}
                title={t("COMMON.WORDS.Teaching units")}
                variant={"outlined"}
                type={"number"}
                required={true}
                {...register("teachingUnits")}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                name={"module"}
                render={({ field: { onBlur, name, onChange }, fieldState }) => (
                  <Autocomplete
                    disablePortal
                    id={name}
                    disableClearable={true}
                    options={modules}
                    getOptionLabel={(option: Module) => option.title}
                    isOptionEqualToValue={(option: Module, value) => option.id === value.id}
                    onChange={(event, value) => moduleOnChange(onChange, value!.id)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onBlur={onBlur}
                        id={name}
                        fullWidth={true}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required={true}
                        label={"Module"}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                name={"topic"}
                render={({ field: { onBlur, name, onChange }, fieldState }) => (
                  <Autocomplete
                    disablePortal
                    id={name}
                    disableClearable={true}
                    options={topics}
                    getOptionLabel={(option: Topic) => option.title}
                    isOptionEqualToValue={(option: Topic, value) => option.title === value.title}
                    onChange={(event, value) => topicOnChange(onChange, value!.title)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        onBlur={onBlur}
                        id={name}
                        fullWidth={true}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        required={true}
                        label={"Thema"}
                        disabled={watchedModule < 0}
                      />
                    )}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                id={"entry"}
                label={t("COMMON.WORDS.Comment")}
                fullWidth={true}
                variant={"outlined"}
                multiline={true}
                minRows={4}
                autoFocus={true}
                required={true}
                {...register("entry")}
              />
            </Grid>
            <Grid item width={"100%"}>
              <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}></Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
            <Trans i18nKey={"COMMON.WORDS.Save"} />
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
