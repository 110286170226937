import dayjs, { Dayjs } from "dayjs";

export interface IComment {
  id: number;
  comment: string;
  participant: number;
  creator: string;
  createdAt: string | Dayjs;
}

export interface ICommentCreate {
  participant: number;
  comment: string;
}

export class Comment implements IComment {
  id: number;
  comment: string;
  participant: number;
  creator: string;
  createdAt: Dayjs;

  constructor(data: IComment) {
    this.id = data.id;
    this.comment = data.comment;
    this.participant = data.participant;
    this.creator = data.creator;
    this.createdAt = dayjs(new Date(data.createdAt as string));
  }
}
