import React, { useEffect, useState } from "react";
import dayjs from "dayjs";

// MUI
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// TS-Hub
import { MainCard } from "../../../../components/cards";
import { Course } from "../../../../models/course";
import { useTranslation } from "react-i18next";

type CourseProgressProps = {
  course: Course;
};

export const CourseProgress: React.FC<CourseProgressProps> = props => {
  const [progress, setProgress] = useState(10);
  const [daysDone, setDaysDone] = useState(0);
  const [courseDuration, setCourseDuration] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    calculateProgress();
  }, []);

  /**
   * Calculates the progress of the course and tells us, how many days we have left in percent.
   *
   */
  function calculateProgress() {
    const today = dayjs();
    const courseBeg = props.course.startsAt;
    const courseEnd = props.course.endsAt;
    const perDay = 1_000 * 3_600 * 24;
    const daysDone = Math.round((today.toDate().getTime() - courseBeg.toDate().getTime()) / perDay);
    const courseDurationInDays = (courseEnd.toDate().getTime() - courseBeg.toDate().getTime()) / perDay;
    setDaysDone(daysDone);
    setCourseDuration(courseDurationInDays);
    setProgress((daysDone / courseDurationInDays) * 100);
  }

  function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ minWidth: 120 }}>
          <Typography variant={"body2"} color={"text.secondary"}>
            {daysDone} / {courseDuration} Tage
          </Typography>
        </Box>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant={"determinate"} {...props} style={{ height: "8px" }} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant={"body2"} color={"text.secondary"}>{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box>
    );
  }

  return (
    <MainCard title={t("COURSES.TITLES.Course progress")}>
      <Box sx={{ width: "100%", marginTop: "8px" }}>
        <LinearProgressWithLabel value={progress} />
      </Box>
    </MainCard>
  );
};
