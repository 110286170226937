import React from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";

// MUI
import { Box, Button, ButtonGroup } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";

// TS-Hub
import { Course } from "../../../../models/course";
import { MainCard } from "../../../../components/cards";
import { Participant } from "../../../../models/participant";

type ParticipantsListProps = {
  course?: Course;
};

export const ParticipantsList: React.FC<ParticipantsListProps> = props => {
  const { course } = props;
  const navigate = useNavigate();

  /**
   * Renders the table row for every participant.
   *
   * @param participant
   */
  function renderTableRow(participant: Participant): React.ReactNode {
    return (
      <TableRow key={participant.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell align={"left"}>{participant.tsId}</TableCell>
        <TableCell component={"th"} scope={"row"}>
          {participant.firstName}
        </TableCell>
        <TableCell align={"left"}>{participant.lastName}</TableCell>
        <TableCell align={"left"}>{participant.clientNumber}</TableCell>
        <TableCell align={"right"}>
          <ButtonGroup>
            <Button variant={"contained"} onClick={() => navigate(`/teilnehmer/${participant.id}`)}>
              <Trans i18nKey={"COURSES.BUTTONS.Details"}>Details</Trans>
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    );
  }

  if (!course) {
    return null;
  }

  return (
    <React.Fragment>
      <MainCard title={"Teilnehmende"}>
        <TableContainer component={Box}>
          <Table size={"small"} aria-label={"CourseBook table"} border={0}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Trans i18nKey={"ID"}>TS-ID</Trans>
                </TableCell>
                <TableCell>
                  <Trans i18nKey={"COMMON.WORDS.First Name"}>First Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans i18nKey={"COMMON.WORDS.Last Name"}>Last Name</Trans>
                </TableCell>
                <TableCell>
                  <Trans i18nKey={"COMMON.WORDS.Client Number"}>Client number</Trans>
                </TableCell>
                <TableCell align={"right"}></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{course.participants.map((participant: Participant) => renderTableRow(participant))}</TableBody>
          </Table>
        </TableContainer>
      </MainCard>
    </React.Fragment>
  );
};
