import React from "react";
import { useNavigate } from "react-router-dom";
import { Trans } from "react-i18next";

// MUI
import { Button, CardContent, Chip, Grid, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

// TS-Hub
import { Course } from "../../models/course";
import { MainCard } from "../../components/cards";

type CourseListPanelProps = {
  courses: Array<Course>;
};
export const CoursesListPanel: React.FC<CourseListPanelProps> = props => {
  const { courses } = props;
  const navigate = useNavigate();

  /**
   * This function renders the card actions for the MainCard.
   *
   * @param course
   */
  function renderCardActions(course: Course): React.ReactNode {
    return (
      <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
        <Button
          size={"small"}
          variant={"outlined"}
          onClick={() => navigate(`/kurse/${course.title}`)}
          disabled={!course.hasAccess}
        >
          <Trans i18nKey={"COMMON.WORDS.More"} />
        </Button>
      </Stack>
    );
  }

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {courses.map(course => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={course.id}>
          <MainCard
            title={
              <Stack direction={"row"} justifyContent={"space-between"}>
                {course.title}
                {course.isActive() ? (
                  <Chip label={"Aktiv"} color={"success"} variant={"outlined"} />
                ) : course.isCompleted() ? (
                  <Chip label={"Beendet"} variant={"outlined"} color={"error"} />
                ) : (
                  <Chip label={"Inaktiv"} variant={"outlined"} color={"error"} />
                )}
              </Stack>
            }
            cardActions={renderCardActions(course)}
          >
            <CardContent>
              <Stack style={{ textAlign: "center" }}>
                <Typography variant={"body2"} color={"text.secondary"}>
                  <strong>Trainer</strong>
                </Typography>
                <Typography variant={"body2"} color={"text.secondary"}>
                  {course.teacher.map(user => `${user.getFullName()}`).join(", ")}
                </Typography>
                <Divider style={{ margin: "5px 0" }} />
                <Typography variant={"body2"} color={"text.secondary"}>
                  <strong>Teaching Assistants</strong>
                </Typography>
                <Typography variant={"body2"} color={"text.secondary"}>
                  {course.teachingAssistants.map(user => `${user.getFullName()}`).join(", ")}
                </Typography>
                <Divider style={{ margin: "5px 0" }} />
                <Typography variant={"body2"} color={"text.secondary"}>
                  <strong>Class Management</strong>
                </Typography>
                <Typography variant={"body2"} color={"text.secondary"}>
                  {course.classManager.map(user => `${user.getFullName()}`).join(", ")}
                </Typography>
                <Divider style={{ margin: "5px 0" }} />
                <Typography variant={"body2"} color={"text.secondary"}>
                  <strong>Career Coaches</strong>
                </Typography>
                <Typography variant={"body2"} color={"text.secondary"}>
                  {course.careerCoaches.map(user => `${user.getFullName()}`).join(", ")}
                </Typography>
              </Stack>
            </CardContent>
          </MainCard>
        </Grid>
      ))}
    </Grid>
  );
};
