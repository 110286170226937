import React from "react";

// MUI
import { Grid } from "@mui/material";

// TS-Hub
import { AnalyticCard, MainCard } from "../../../components/cards";
import { Course } from "../../../models/course";
import Typography from "@mui/material/Typography";

type CourseCardProps = {
  course: Course;
};

export const CourseCard: React.FC<CourseCardProps> = props => {
  const { course } = props;

  return (
    <MainCard title={"Kurs"}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <AnalyticCard
            title={"Freie Urlaubstage"}
            count={course.vacationDaysForPersonalUsage}
            extra={
              <>
                Anzahl an Urlaubstagen, dir zur{" "}
                <Typography component={"span"} variant={"caption"} sx={{ color: "primary.main" }}>
                  freien Verwendung
                </Typography>{" "}
                stehen.
              </>
            }
            variant={"filled"}
          />
        </Grid>
      </Grid>
    </MainCard>
  );
};
