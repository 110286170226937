import { Participant } from "./participant";

export interface ICourseGroup {
  id: number;
  name: string;
  participants: Array<Participant>;
}

export class CourseGroup implements ICourseGroup {
  id: number;
  name: string;
  participants: Array<Participant> = [];

  constructor(data: ICourseGroup) {
    this.id = data.id;
    this.name = data.name;

    if (Array.isArray(data.participants)) {
      data.participants.map(e => this.participants.push(new Participant(e as Participant)));
    }
  }
}
