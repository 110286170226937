import { Dayjs } from "dayjs";

/**
 * Returns a Date object of the previous month.
 *
 */
export function getPreviousMonth(): Date {
  const d = new Date();
  d.setDate(1);
  d.setMonth(d.getMonth() - 1);
  return d;
}

/**
 * Returns an array of dayjs objects between two dates (including those dates as well).
 *
 * @param from
 * @param until
 */
export function getDaysBetween(from: Dayjs, until: Dayjs): Array<Dayjs> {
  const diffInDays = until.diff(from, "days");
  const days: Array<Dayjs> = [];

  for (let i = 0; i <= diffInDays; i++) {
    days.push(from.add(i, "day"));
  }

  return days;
}

/**
 * Returns true whether the given date is a weekday or not.
 *
 * @param date
 */
export function isWeekday(date: Date): boolean {
  return !(date.getDay() === 6 || date.getDay() === 0);
}
