import React from "react";

// MUI
import { Tooltip } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import HouseboatIcon from "@mui/icons-material/Houseboat";
import CelebrationIcon from "@mui/icons-material/Celebration";

// TS-Hub
import { PresenceStatus } from "../../../models/presenceTracking";

type IconProps = {
  status: PresenceStatus | null;
  comment: string | null;
};

export const PresenceTrackingIcon: React.FC<IconProps> = props => {
  const { status, comment } = props;

  if (status) {
    switch (status) {
      case PresenceStatus.PRESENT:
        return (
          <Tooltip title={comment}>
            <CheckIcon color={"success"} />
          </Tooltip>
        );
      case PresenceStatus.DELAYED:
        return (
          <Tooltip title={comment}>
            <CheckIcon color={"warning"} />
          </Tooltip>
        );
      case PresenceStatus.ABSENT:
        return (
          <Tooltip title={comment}>
            <CloseIcon color={"error"} />
          </Tooltip>
        );
      case PresenceStatus.EXCUSED:
        return (
          <Tooltip title={comment || "Entschuldigt"}>
            <VaccinesIcon color={"info"} />
          </Tooltip>
        );
      case PresenceStatus.VACATION:
        return (
          <Tooltip title={"Urlaub"}>
            <HouseboatIcon color={"info"} />
          </Tooltip>
        );
      case PresenceStatus.HOLIDAY:
        return (
          <Tooltip title={comment || "Feiertag"}>
            <CelebrationIcon color={"info"} sx={{ marginTop: "auto", marginBottom: "auto" }} />
          </Tooltip>
        );
      default:
        return "";
    }
  } else {
    return "";
  }
};
