import React, { createContext, useState } from "react";

import { User } from "../models/user";
import { browserStorage, deleteBrowserData } from "../storages/localStorage";
import { ApiSessionService, Session } from "../services/apiSessionService";
import { ApiUserService } from "../services/apiUserService";

interface IAuthContextStates {
  user: null | User;
  isAuthenticated: boolean;
  doLogin: (email: string, password: string) => Promise<boolean>;
  doLogout: Function;
  cacheUser: Function;
}

/**
 * Create the context.
 *
 */
const AuthContext = createContext<IAuthContextStates>({
  user: null,
  isAuthenticated: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  doLogin: (email: string, password: string) => new Promise(() => false),
  doLogout: () => {},
  cacheUser: () => {}
});

export const AuthProvider: React.FC<React.PropsWithChildren> = props => {
  const [user, setUser] = useState<User | null>(browserStorage.getUser());
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(Boolean(browserStorage.getAccessToken()));

  /**
   * Function that handles the actual login process.
   *
   * @param email
   * @param password
   */
  async function doLogin(email: string, password: string): Promise<boolean> {
    const res = await ApiSessionService.createSession(email, password);
    if (res.status === 201) {
      handleOnLoginSuccess(res.data);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function that handles the actual logout process.
   *
   */
  function doLogout() {
    deleteBrowserData();
    setTimeout(() => (window.location.href = "/"));
  }

  /**
   * Handles the success login process.
   * Save the access and refresh token.
   * Create a user model.
   *
   * @param session
   */
  async function handleOnLoginSuccess(session: Session) {
    browserStorage.setAccessToken(session.access);
    browserStorage.setRefreshToken(session.refresh);

    await cacheUser();
    setIsAuthenticated(true);
    setTimeout(() => (window.location.href = "/"), 2_000);
  }

  /**
   * Caches the user in the component and also in the browserStorage.
   *
   */
  async function cacheUser(): Promise<void> {
    ApiUserService.fetchMe().then(res => {
      const user = new User(res.data);
      setUser(user);
      browserStorage.setUser(user);
    });
  }

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, doLogin, doLogout, cacheUser }}>{props.children}</AuthContext.Provider>
  );
};

export function useAuth(): IAuthContextStates {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthContext.");
  }

  return context;
}
