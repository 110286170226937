import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Trans, useTranslation } from "react-i18next";
import _ from "lodash";

// MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  List,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import Divider from "@mui/material/Divider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

// TS-Hub
import { Course } from "../../models/course";
import { ApiParticipantService } from "../../services/apiParticipantService";
import { useSnackbar } from "../../provider/snackbar";
import { getInfinitePaginatedData } from "../../helper/http";
import { HomeworkTracking, IHomeworkTrackingCreate, IHomeworkTrackingUpdate } from "../../models/homeworkTracking";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

type HomeworkTrackingModalProps = {
  isOpen: boolean;
  onClose: Function;
  course: Course;
};

export const HomeworkTrackingModal: React.FC<HomeworkTrackingModalProps> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [trackingDate, setTrackingDate] = useState<Dayjs>(dayjs());
  const [homeworkTracks, setHomeworkTracks] = useState<Array<HomeworkTracking>>([]);
  const [originHomeworkTracks, setOriginHomeworkTracks] = useState<Array<HomeworkTracking>>([]);
  const { t } = useTranslation();
  const { toast } = useSnackbar();
  const { isOpen, onClose, course } = props;

  /**
   * useEffect
   *
   */
  useEffect(() => {
    if (isOpen) {
      fetchOrCreateHomeworkTracking();
    }
  }, [trackingDate, isOpen]);

  /**
   * Fetch the homework tracking from the backend or create new ones to work with them.
   *
   */
  function fetchOrCreateHomeworkTracking() {
    getInfinitePaginatedData<HomeworkTracking>(
      ApiParticipantService.fetchHomeworkTracking,
      [],
      new URLSearchParams({
        date_range_after: trackingDate.format("YYYY-MM-DD"),
        date_range_before: trackingDate.format("YYYY-MM-DD"),
        course: String(course.id)
      })
    ).then(res => {
      if (_.isEmpty(res.data)) {
        setHomeworkTracks(
          course.participants.map(
            participant => new HomeworkTracking({ participant: participant, status: false, date: trackingDate, comment: "" })
          )
        );
      } else {
        setHomeworkTracks(res.data.map(r => new HomeworkTracking(r)));
        setOriginHomeworkTracks(res.data.map(r => new HomeworkTracking(r)));
      }
    });
  }

  /**
   * Create a new homework tracking.
   *
   * @param track
   */
  async function createHomeworkTracking(track: HomeworkTracking): Promise<boolean> {
    const homeworkTrackingToCreate: IHomeworkTrackingCreate = {
      participant: track.participant.id,
      date: track.date,
      status: track.status,
      comment: track.comment
    };
    const res = await ApiParticipantService.createHomeworkTracking(homeworkTrackingToCreate);
    return res.status === 201;
  }

  /**
   * Update a homework tracking.
   *
   * @param track
   */
  async function updateHomeworkTracking(track: HomeworkTracking): Promise<boolean> {
    const homeworkTrackingToCreate: IHomeworkTrackingUpdate = { status: track.status, comment: track.comment };
    const res = await ApiParticipantService.updateHomeworkTracking(track.id!, homeworkTrackingToCreate);
    return res.status === 200;
  }

  /**
   * Handler for the onSubmit event.
   *
   */
  async function handleOnSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setIsLoading(true);
    let success = null;

    for (let i = 0; i < homeworkTracks.length; i++) {
      const track = homeworkTracks[i];

      if (track.id) {
        // Homework tracking was already created - update it only then, if the status changed
        if (track.status !== originHomeworkTracks[i].status || track.comment !== originHomeworkTracks[i].comment) {
          success = await updateHomeworkTracking(track);
        }
      } else {
        // Create new homework tracking
        success = await createHomeworkTracking(track);
      }

      if (success === false) {
        toast({
          message: `${t("HOMEWORK_TRACKING.TOASTS.CREATED_UNSUCCESSFULLY")}: ${track.participant.firstName} ${
            track.participant.lastName
          }`,
          level: "error"
        });
      }
    }

    if (success) {
      toast({ message: t("HOMEWORK_TRACKING.TOASTS.CREATED_SUCCESSFULLY"), level: "success" });
      fetchOrCreateHomeworkTracking();
    }

    setIsLoading(false);
  }

  /**
   * Handles the onChange event of the select form.
   *
   * This is the only way how we can update an object in an array made by useState.
   *
   * I followed these docs: https://beta.reactjs.org/learn/updating-arrays-in-state#updating-objects-inside-arrays
   *
   * @param event
   * @param index
   */
  function handleStatusOnChange(event: SelectChangeEvent, index: number) {
    const t = [...homeworkTracks];
    const t1 = t[index];
    t1.status = !t1.status;
    setHomeworkTracks(t);
  }

  /**
   * Handles the onChange event of the comment form.
   *
   * This is the only way how we can update an object in an array made by useState.
   *
   * I followed these docs: https://beta.reactjs.org/learn/updating-arrays-in-state#updating-objects-inside-arrays
   *
   * @param event
   * @param index
   */
  function handleCommentOnChange(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, index: number) {
    const t = [...homeworkTracks];
    const t1 = t[index];
    t1.comment = event.target.value || "";
    setHomeworkTracks(t);
  }

  /**
   * Handles the onClose event of the modal.
   *
   */
  function handleOnClose() {
    setIsLoading(false);
    onClose();
    setHomeworkTracks([]);
  }

  return (
    <Dialog open={isOpen} onClose={handleOnClose} fullWidth={true}>
      <form onSubmit={handleOnSubmit}>
        <DialogTitle textAlign={"center"}>{t("COMMON.WORDS.Homework Tracking")}</DialogTitle>

        <Divider />

        <DialogContent>
          <Box>
            <Grid container sx={{ marginBottom: "50px" }}>
              <Grid item xs={6} sx={{ margin: "auto 0px" }}>
                <strong>Unterrichtstag</strong>
              </Grid>
              <Grid item xs={6}>
                <DesktopDatePicker
                  format={"DD.MM.YYYY"}
                  value={trackingDate}
                  onChange={value => setTrackingDate(value as Dayjs)}
                />
              </Grid>
            </Grid>
            <List>
              <Divider />
              {homeworkTracks.map((tracking, index) => {
                return (
                  <Box key={tracking.participant.id}>
                    <Grid container sx={{ margin: "10px 0px" }}>
                      <Grid item xs={6} sm={6} sx={{ margin: "auto 0px" }}>
                        {tracking.participant.firstName} {tracking.participant.lastName}
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <FormControl fullWidth>
                          <FormControlLabel
                            control={
                              <Switch
                                id={"presence-status-select"}
                                checked={tracking.status}
                                onChange={event => handleStatusOnChange(event, index)}
                              />
                            }
                            label={<Trans i18nKey={"COMMON.WORDS.Homework done"}></Trans>}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} sx={{ margin: "5px 0px" }}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Kommentar</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <TextField
                              id={"comment"}
                              label={"Kommentar"}
                              fullWidth={true}
                              variant={"outlined"}
                              multiline={true}
                              minRows={4}
                              required={false}
                              value={tracking.comment}
                              onChange={event => handleCommentOnChange(event, index)}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                    <Divider />
                  </Box>
                );
              })}
            </List>
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};
