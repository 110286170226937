import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// MUI
import { Box, Grid, Tab, Tabs } from "@mui/material";

// TS-Hub
import { AppLayout } from "../../layouts/appLayout";
import { Heading } from "../../components/text/heading";
import { TabPanel } from "../../components/tab";
import { MainCard } from "../../components/cards";
import { ModuleTopicsTable } from "../../components/module/moduleTopicsTable";
import { Program } from "../../models/program";
import { ApiProgramsService } from "../../services/apiProgramsService";
import { useSnackbar } from "../../provider/snackbar";
import { GeneralTable } from "./components/generalTable";

export const ProgramDetail: React.FC = () => {
  const [program, setProgram] = useState<Program>();
  const [tabPanel, setTabPanel] = useState(0);
  const { programId } = useParams<"programId">();
  const { toast } = useSnackbar();
  const navigate = useNavigate();

  useEffect(() => {
    ApiProgramsService.fetchProgram(Number(programId))
      .then(res => {
        if (res.status === 200) {
          setProgram(new Program(res.data));
        } else {
          handleProgramNotFound();
        }
      })
      .catch(() => handleProgramNotFound());
  }, [programId]);

  /**
   * Handler for the case that a user cannot be found on the backend.
   *
   */
  function handleProgramNotFound() {
    toast({ message: `Maßnahme mit der ID ${programId} nicht gefunden!`, level: "error" });
    navigate("/programs");
  }

  /**
   * Handles the onChange event on the tab to set the tab panel.
   *
   * @param event
   * @param newValue
   */
  function handleTabsOnChange(event: React.SyntheticEvent, newValue: number) {
    setTabPanel(newValue);
  }

  return (
    <AppLayout>
      <Heading text={program?.name} />

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={tabPanel} onChange={handleTabsOnChange}>
            <Tab label="Allgemeines" />
            <Tab label="Lehrplan" />
          </Tabs>
        </Box>
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={12}>
        <TabPanel value={tabPanel} index={0}>
          <MainCard title={"Module"}>
            <GeneralTable program={program} />
          </MainCard>
        </TabPanel>

        <TabPanel value={tabPanel} index={1}>
          <MainCard title={"Lehrplan"}>
            <ModuleTopicsTable programId={program?.id} />
          </MainCard>
        </TabPanel>
      </Grid>
    </AppLayout>
  );
};
