import React from "react";
import { Control, Controller, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";

// MUI
import { Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Stack from "@mui/material/Stack";
import LoadingButton from "@mui/lab/LoadingButton";

// TS-Hub
import { Gender, Participant } from "../../../models/participant";

type ParticipantFormProps = {
  isLoading?: boolean;
  register: UseFormRegister<Participant>;
  handleOnSubmit?: Function;
  control: Control<Participant>;
  participant: Participant;
};
export const ParticipantForm: React.FC<ParticipantFormProps> = props => {
  const { control, register, handleOnSubmit, isLoading, participant } = props;
  const { t } = useTranslation();
  const handleOnSubmitFunc = !handleOnSubmit ? () => {} : handleOnSubmit();

  return (
    <form onSubmit={handleOnSubmitFunc()}>
      <Grid container spacing={3} sx={{ paddingTop: "10px" }}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("COMMON.WORDS.First Name")}
            fullWidth={true}
            variant={"outlined"}
            required={true}
            disabled={true}
            {...register("firstName")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("COMMON.WORDS.Last Name")}
            fullWidth={true}
            variant={"outlined"}
            required={true}
            disabled={true}
            {...register("lastName")}
          />
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            label={"E-Mail"}
            fullWidth={true}
            variant={"outlined"}
            required={true}
            disabled={true}
            {...register("email")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <Controller
            control={control}
            name={"dateOfBirth"}
            render={({ field: { ref, ...field } }) => (
              <DesktopDatePicker
                {...field}
                inputRef={ref}
                label={t("COMMON.WORDS.Date of birth")}
                disabled={true}
                sx={{ width: "100%" }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <InputLabel>{t("COMMON.WORDS.Gender")}</InputLabel>
            <Select
              label={t("COMMON.WORDS.Gender")}
              fullWidth={true}
              variant={"outlined"}
              required={true}
              disabled={true}
              defaultValue={participant.gender}
              {...register("gender")}
            >
              <MenuItem value={Gender.MAN}>Mann</MenuItem>
              <MenuItem value={Gender.WOMAN}>Frau</MenuItem>
              <MenuItem value={Gender.DIVERSE}>Divers</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Divider style={{ margin: "20px 0" }} />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t("COMMON.WORDS.Postal code")}
            fullWidth={true}
            variant={"outlined"}
            required={true}
            disabled={true}
            {...register("postalCode")}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            label={t("COMMON.WORDS.City")}
            fullWidth={true}
            variant={"outlined"}
            required={true}
            disabled={true}
            {...register("city")}
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: "20px 0" }} />

      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            label={t("COMMON.WORDS.Client Number")}
            fullWidth={true}
            variant={"outlined"}
            required={true}
            disabled={true}
            {...register("clientNumber")}
          />
        </Grid>
      </Grid>

      <Divider style={{ margin: "20px 0" }} />

      {handleOnSubmit && (
        <Grid container spacing={3}>
          <Grid item width={"100%"}>
            <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"}>
              <LoadingButton type={"submit"} variant={"outlined"} fullWidth={true} loading={isLoading}>
                {t("COMMON.WORDS.Save")}
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      )}
    </form>
  );
};
